import React, { useEffect, useState } from "react";
import { FaAngleLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { deadlineCalculator } from "../../utils/dateSubtract";
function AddProject() {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [branches, setBranches] = useState([]);
  const [branch,setBranch] = useState("")
  const [name, setName] = useState("");
  const [numberOfHouses, setNumberOfHouses] = useState(0);
  const [numberOfBlocks, setNumberOfBlocks] = useState(0);
  const [progressPercentage, setProgressPercentage] = useState(0);
  // const [status, setStatus] = useState("Not Started");
  const [budget, setBudget] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('')
  const [dateError,setDateError] = useState(false)







  useEffect(() => {
     
      axios
        .get("https://pms.dalyobt.com/api/view_branch/")
        .then((response) => {
       
        
         
          setBranches(response.data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }, []);
  const handleSend = async () => {
    // const startResult = deadlineCalculator(startDate)
    const endResult = deadlineCalculator(endDate)
    if(endResult < 0){
      setDateError(true)
    }
    else{
      const headers = {
        Authorization: token,
        "Content-Type": "application/json",
      };
      setLoading(true);
      await axios
        .post(
          "https://pms.dalyobt.com/api/view_project/",
          {
            no_houses: parseInt(numberOfHouses),
            no_blocks: parseInt(numberOfBlocks),
            name: name,
            start_date: startDate + " 00:00:00",
            end_date: endDate + " 00:00:00",
            // progress_percentage: progressPercentage,
            status: "New",
            budget: parseInt(budget),
            branch_id:parseInt(branch)
          },
          { headers }
        )
        .then((response) => {
          // console.log(response.data)
          setLoading(false);
          setDateError(false)
          navigate(`/branch/projects/${branch}`);
        })
        .catch((error) => {
          setError(error.message)
          console.log("error: ",error.message)
          // console.error("Error fetching data:", error);
        });
      
    }
  
  };


  return (
    <div className="w-[100%] min-h-[91.5vh] flex flex-col  py-4  bg-white">
      <div className="w-[90%] mx-auto my-[30px] flex justify-between items-center">
        <div className=" flex items-center gap-[30px]">
          <FaAngleLeft
            className="text-[30px] cursor-pointer text-[#0B72B5]"
            onClick={() => navigate("/projects")}
          />
          <span className="text-[20px] text-[#0B72B5] font-bold">
            Create new Project
          </span>
        </div>
        {/* <div>{loading && <p>Loading...</p>}</div> */}
      </div>
      {error && <div className="w-[80%] mx-auto my-2 bg-red-500 text-center p-2 rounded-md">
        <p className="text-white font-medium">Please Check the fields again there might be a missing field.</p>
      </div>}
      <div className="w-[80%] mt-[50px] mx-auto flex flex-col gap-[20px]">
        <div className="flex items-center justify-between gap-[10px]">
          <div className="w-[50%]  flex flex-col  items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              Project Name <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="text"
                placeholder="Project-1"
                onChange={(e) => setName(e.target.value)}
                className="w-[100%] border border-[#ced4da] py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>
          <div className="w-[50%]  flex flex-col  items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
             Branch<span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <select
                type="text"
                placeholder="Project-1"
                onChange={(e) => setBranch(e.target.value)}
                className="w-[100%] border border-[#ced4da] py-3 px-4 rounded-[5px] text-[14px] outline-none"
              >
                <option value={""}>Select branch</option>
                {branches?.map((br,index)=>{
                  return <option key={index} value={br?.id}>{br?.name}</option>
                })}
              </select>
            </div>
          </div>
          <div className="w-[50%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              Budget <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="number"
                placeholder="5,000,0000"
                onChange={(e) => setBudget(e.target.value)}
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>
        </div>

        <div className="flex justify-between gap-[10px] my-[20px]">
          
            <div className="w-[90%] flex flex-col items-start gap-[20px]">
              <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
                Number of Blocks
              </span>
              <div className="w-[100%] rounded-[5px]">
                <input
                  type="number"
                  placeholder="0"
                  onChange={(e) => setNumberOfBlocks(e.target.value)}
                  className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
                />
              </div>
            </div>
          
          <div className="w-[90%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              Number of Houses
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="number"
                placeholder="0"
                onChange={(e) => setNumberOfHouses(e.target.value)}
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>

          {/* <div className="w-[40%]  flex flex-col items-start gap-[20px]">
            {/* <span className='text-[14px] font-bold w-[80%] text-[#0B72B5]'>Status</span>
            <div className='w-[100%] rounded-[5px]'>
                <select onChange={(e)=>setStatus(e.target.value)} className='w-[100%] border border-[#ced4da]  p-4 rounded-[5px] text-[14px] outline-none'>

                    <option value={"ongoing"}>ongoing</option>
                    <option value={"Not started"}>Not Started</option>
                    <option value={"Completed"}>Completed</option>
                </select>
            </div> */}
          {/* </div> */} 
        </div>
        <div className="flex justify-between my-[20px] gap-[10px]">
          <div className="w-[50%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              Start Date <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="date"
                onChange={(e) => setStartDate(e.target.value)}
                placeholder="Ex. 2023-07-08"
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
            {/* {dateError && <span className="text-[14px] text-red-500">Invalid Date</span>} */}
          </div>
          <div className="w-[50%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              End Date <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="date"
                onChange={(e) => setEndDate(e.target.value)}
                placeholder="Ex. 2023-07-08"
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
            {dateError && <span className="text-[14px] text-red-500">Invalid Date</span>}
          </div>
        </div>
        {/* <div className='w-[100%]  flex flex-col items-start gap-[20px]'>
    <span className='text-[14px] font-bold w-[30%] text-[#0B72B5]'>Description</span>
    <div className='w-[100%] rounded-[5px]'>
    
    <textarea rows={10} className='w-[100%] border border-[#ced4da]  resize-none py-3 px-4 rounded-[5px] text-[14px] outline-none'>

    </textarea>
    </div>
 
</div> */}
      </div>

      <div className="w-[90%] mt-[30px] flex justify-end items-center gap-[20px]">
        <button onClick={() => navigate(-1)}>Cancel</button>
        <button
          className="py-2 px-4 bg-[#0B72B5] text-white rounded-[10px]"
          onClick={handleSend}
        >
          Create Project
        </button>
      </div>
    </div>
  );
}

export default AddProject;
