import React, { useEffect, useState } from "react";
import SiteTable from "./SiteTable";
import { FaAngleLeft } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
// import { projects } from '../../utils/data'
import AddBuilding from "./AddBuilding";
import axios from "axios";
// import { Box } from "@mui/material";

function Sites() {
  const navigate = useNavigate();
  const { id } = useParams();
  // const siteID = localStorage.getItem("siteID");
  const [siteData, setsiteData] = useState([]);
  const [buildingSearch, setBuildingSearch] = useState("");
  // const [siteName,setSiteName] = useState('')
  const [addBuilding, setAddbuilding] = useState(false);
  const userGroup = localStorage.getItem("userGroup");
  const projectID = localStorage.getItem("projectID");
  // const [calField, setCalField] = useState([]);

  useEffect(() => {
    axios
      .get(`https://pms.dalyobt.com/api/view_block/${id}`)
      .then((response) => {
        localStorage.setItem("siteID", id);
        // console.log(response.data)
        setsiteData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [id]);

  // useEffect(() => {
  //   axios
  //     .get(`https://pms.dalyobt.com/api/calculate_total_site_progress/${id}/`)
  //     .then((response) => {
  //       setCalField(response.data);
  //     })
  //     .catch((error) => {
  //       console.error(error?.response?.data?.error);
  //     });
  // }, [id]);

  return (
    <div className="mt-[50px]">
      <div className="w-[90%] mt-6 mx-auto flex justify-between items-center gap-[20px]">
        <div className="flex justify-start items-center gap-[20px]">
          <FaAngleLeft
            className="text-[30px] cursor-pointer text-[#0B72B5]"
            onClick={() => navigate("/projects/" + projectID)}
          />
          <span className="text-[20px] font-bold text-[#0B72B5]">
            Sites / {id}
          </span>
        </div>
{/* {calField?.average_progress  &&<Box
          sx={{
            background: `radial-gradient(white 55%, transparent 56%),
                            conic-gradient(transparent 0deg ${
                              (calField?.average_progress / 100) * 360
                            }deg,white ${
              (calField?.average_progress / 100) * 360
            }deg 360deg),
                            #0B72B5`,
            borderRadius: "50%",
            width: `70px`,
            height: `70px`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {calField?.average_progress ? calField?.average_progress :0}%
        </Box>} */}
        

        {userGroup?.toString() === "1" && (
          <button
            className="bg-[#0B72B5] py-2 px-4 text-white text-[14px] rounded-[10px]"
            onClick={() => setAddbuilding(true)}
          >
            Add Block
          </button>
        )}
      </div>
      <div className="w-[100%] mt-[50px] flex justify-center">
        <input
          type="text"
          placeholder="Search by block name"
          onChange={(e) => setBuildingSearch(e.target.value)}
          className="bg-white border border-gray-400 p-3 w-[40%] rounded-[5px] text-[14px] outline-none text-gray-500 max-[1000px]:w-[80%]"
        />
      </div>
      <div className="w-[90%] mx-auto my-[20px] bg-white  p-4 rounded-[10px] mt-[50px] border border-gray-300 max-[1000px]:w-[95%]">
        <div className="w-[100%]  mt-[50px] ">
          <SiteTable buildingSearch={buildingSearch} projects={siteData} />
        </div>
      </div>
      {addBuilding && (
        <AddBuilding
          addBuilding={addBuilding}
          setAddbuilding={setAddbuilding}
        />
      )}
    </div>
  );
}

export default Sites;
