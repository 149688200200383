import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function ContractorStatus({ contractors, error }) {
  const navigate = useNavigate();
  const [searchContractor,setSearchContractor] = useState("")

  // console.log(contractors)

  const handlePrint = ()=>{
    window.print();
  }

  const filteredContractor = contractors?.filter((contractor)=>{
    return contractor.name.includes(searchContractor) || contractor.name.includes(searchContractor.toLowerCase() )||  contractor.name.includes(searchContractor.toUpperCase() )
  })


  return (
    <div className="w-[100%] mx-auto relative overflow-auto  sm:rounded-l">
      <div className="w-[100%] flex justify-end items-center">
        <input type="text" onChange={(e)=>setSearchContractor(e.target.value)} className="py-2 px-6 bg-gray-200 rounded text-[14px] outline-none" placeholder="search by name"  />
      <button
        className="bg-[#0B72B5] m-[20px] py-2 px-4 text-white text-[12px] rounded-[5px] mb-4"
        onClick={handlePrint}
      >
        Print 
      </button>
      </div>
      
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-center text-gray-700 uppercase border-b border-gray-300">
          <tr>
            <th scope="col" className="px-6 py-3">
              ID
            </th>

            <th scope="col" className="px-6 py-3">
              Name
            </th>
            <th scope="col" className="px-6 py-3">
              Phone
            </th>
            <th scope="col" className="px-6 py-3">
              Email
            </th>
            <th scope="col" className="px-6 py-3">
              Contact Person
            </th>
            <th scope="col" className="px-6 py-3">
              Address
            </th>
            <th scope="col" className="px-6 py-3">
              Tin Number
            </th>
            <th scope="col" className="px-6 py-3">
              Vat Registration No.
            </th>

            <th scope="col" className="px-6 py-3">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredContractor?.map((contractor) => {
            return (
              <tr
                key={contractor?.id}
                className="text-center text-gray-600 border-b border-gray-300"
              >
                <td>{contractor?.id}</td>
                <td className="px-6 py-4">{contractor?.name}</td>
                <td className="px-6 py-4">{contractor?.phone}</td>
                <td className="px-6 py-4">{contractor?.email}</td>
                <td className="px-6 py-4">{contractor?.contact_person}</td>
                <td className="px-6 py-4">{contractor?.address}</td>
                <td className="px-6 py-4">{contractor?.tin_no}</td>
                <td className="px-6 py-4">{contractor?.Vat_Reg_no}</td>

                <td>
                  <button
                    className="bg-[#0B72B5] py-2 px-4 text-white text-[12px] rounded-[5px]"
                    onClick={() =>
                      navigate(`/updatecontractorstatus/${contractor?.id}`)
                    }
                  >
                    Update
                  </button>
                </td>

                {/* {userGroup.toString()==="1" && <td className="flex justify-center  items-center px-6 py-4 space-x-3"> */}
                {/* <button className='bg-[#0B72B5] text-white rounded-[5px] p-2'>Edit</button> */}
                {/* <button className='bg-rose-500 text-white rounded-[5px] p-2' onClick={()=>handleDelete(project.id)}>Remove</button> */}

                {/* </td>} */}
              </tr>
            );
          })}
        </tbody>
      </table>
      {contractors.length === 0 && (
        <div className="w-[100%] my-[20px] flex justify-center items-center">
          {" "}
          <span className="text-gray-600 font-bold ">No contractor available</span>
        </div>
      )}
          {filteredContractor.length === 0 && (
        <div className="w-[100%] my-[20px] flex justify-center items-center">
          {" "}
          <span className="text-gray-600 font-bold ">No contractor available</span>
        </div>
      )}
    </div>
  );
}

export default ContractorStatus;
