import React from 'react'
import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

function BlockBar({block}) {
    const width = window.innerWidth;

  
 
  return (
    <ResponsiveContainer
      width={width < 1000 ? "100%" : "90%" || width}
      height="80%"
    >
      <BarChart
        width={500}
        height={300}
        data={block}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <XAxis dataKey="name" />
        <YAxis type="number" domain={[0, 100]} />
        <Tooltip />
        <Legend />
        {/* {financial} */}
        <Bar dataKey="progress_percentage" fill="#ffe79e" />
      </BarChart>
    </ResponsiveContainer>
  )
}

export default BlockBar