import React, { useEffect, useState } from "react";
// import { checkListContent } from "../../../utils/data";
import axios from "axios";
import { useParams } from "react-router-dom";

function CheckListGet() {
  const { id } = useParams();
  const [input, setInput] = useState("MOBILIZATION");
  const [dateInput, setDateInput] = useState("");
  const [dataList, setDataList] = useState([]);
  const [chklItem, setchklItem] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [checkListItems, setCheckListItems] = useState([]);
  const [activity, setActivity] = useState([]);

  useEffect(() => {
    axios
      .get(`https://pms.dalyobt.com/api/check_list/${id}/`)
      .then((response) => {
        setDataList(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [id]);

  useEffect(() => {
    axios
      .get(`https://pms.dalyobt.com/api/chklitem/`)
      .then((response) => {
        setchklItem(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    axios
      .get("https://pms.dalyobt.com/api/activities/")
      .then((response) => {
        setActivity(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

    // console.log(input)

  const filteredList = dataList.filter((item) => item?.activity === input);
  const filteredListDate = filteredList.filter(
    (items) => items?.prepared_date === dateInput
  );

  const handleClick = (items) => {
    setShowTable(!showTable);
    setCheckListItems(items);
  };

  return (
    <>
      <div className="my-[50px] w-[90%] mx-auto bg-white p-4 rounded shadow-md">
      <div className="w-[100%] mt-[20px] flex justify-center items-center">
            <span className="text-[#0B72B5] font-bold">Checklist Progress</span>
          </div>
        <div className="w-[80%] mt-[30px] mx-auto flex justify-between items-center">
          <div className="w-[45%]">
            <select
              onChange={(e) => setInput(e.target.value)}
              id="data"
              className="bg-white w-[100%] p-4 text-[14px] text-gray-600 outline-none border border-gray-400 rounded-md"
            >
              {activity?.map((cl, index) => (
                <option className="p-2 border border-gray-400" key={index}>
                  {cl?.name}
                </option>
              ))}
            </select>
          </div>
          <div className="w-[45%]">
            <input
              type="date"
              className="w-full bg-white p-4 text-[14px] text-gray-600 outline-none border border-gray-400 rounded-md cursor-pointer"
              onChange={(e) => setDateInput(e.target.value)}
            />
          </div>
        </div>
        <div className="w-[100%] my-[30px] mx-auto max-h-[400px] overflow-auto">
          <table className="min-w-full divide-y mt-[20px]  divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  CHKL_NUM
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Block
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Floor
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Fullfilled
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Allowed
                </th>

                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Remark
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Items
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Prepared By
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Approved By
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Prepared Date
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Approved Date
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Attachement
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {dateInput === "" ? filteredList?.map((boq, index) => {
                    return (
                      <tr
                        key={index}
                        className="text-[15px] font-normal text-gray-600"
                      >
                        <td className="px-6 py-4 whitespace-nowrap">
                          {index + 1}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {boq ? boq?.block : "-"}
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap">
                          {boq ? boq?.floor : "-"}
                        </td>
                        {/* <td className="px-6 py-4 whitespace-nowrap">Electricity</td> */}
                        <td className="px-6 py-4 whitespace-nowrap">
                          {boq?.fulfilled?.toString() === "true"
                            ? "Approved"
                            : "Rejected"}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {boq?.allowed?.toString() === "true"
                            ? "Approved"
                            : "Rejected"}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {boq ? boq?.remark : "-"}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {boq ? (
                            <button
                              onClick={() => handleClick(boq?.items)}
                              className="text-[#0B72B5] underline"
                            >
                              CheckItems
                            </button>
                          ) : (
                            ""
                          )}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {boq ? boq?.prepared_by : "-"}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {/* {boq?.attachement_url && (
                          <a
                            href={`https://pms.dalyobt.com${boq?.attachement_url}`}
                            download
                          >
                            {boq ? boq?.attachement : "-"}
                          </a>
                        )} */}
                          {boq ? boq?.approved_by : "-"}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {boq ? boq?.prepared_date : "-"}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {boq ? boq?.approved_date : "-"}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {/* {boq?.attachement_url && (
                          <a
                            href={`https://pms.dalyobt.com${boq?.attachement_url}`}
                            download
                          >
                            {boq ? boq?.attachement : "-"}
                          </a>
                        )} */}
                          {boq ? boq?.attachement : "-"}
                        </td>
                      </tr>
                    );
                  })
                : filteredListDate?.map((item, index) => {
                    return (
                      <tr
                        key={index}
                        className="text-[15px] font-normal text-gray-600"
                      >
                        <td className="px-6 py-4 whitespace-nowrap">
                          {index + 1}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {item ? item?.block : "-"}
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap">
                          {item ? item?.floor : "-"}
                        </td>
                        {/* <td className="px-6 py-4 whitespace-nowrap">Electricity</td> */}
                        <td className="px-6 py-4 whitespace-nowrap">
                          {item?.fulfilled.toString() === "true"
                            ? "Approved"
                            : "Rejected"}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {item?.allowed.toString() === "true"
                            ? "Approved"
                            : "Rejected"}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {item ? item?.remark : "-"}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {item ? (
                            <button
                              onClick={() => handleClick(item?.items)}
                              className="text-[#0B72B5] underline"
                            >
                              CheckItems
                            </button>
                          ) : (
                            ""
                          )}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {item ? item?.prepared_by : "-"}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {/* {item?.attachement_url && (
                          <a
                            href={`https://pms.dalyobt.com${item?.attachement_url}`}
                            download
                          >
                            {item ? item?.attachement : "-"}
                          </a>
                        )} */}
                          {item ? item?.approved_by : "-"}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {item ? item?.prepared_date : "-"}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {item ? item?.approved_date : "-"}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {/* {item?.attachement_url && (
                          <a
                            href={`https://pms.dalyobt.com${item?.attachement_url}`}
                            download
                          >
                            {item ? item?.attachement : "-"}
                          </a>
                        )} */}
                          {item ? item?.attachement : "-"}
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </table>
          {(filteredList?.length === 0 && filteredListDate?.length === 0) &&
            input !== "MOBILIZATION" && (
              <div className="w-[100%] my-[20px] flex justify-center items-center">
                {" "}
                <span className="text-gray-600 font-bold ">
                  No CheckList available
                </span>
              </div>
            )}
        </div>
      </div>
      {showTable && (
        <div className="w-[100%] absolute z-50 bg-white top-10 p-4 left-0 my-[30px] mx-auto max-h-[500px]">
          <div className="flex flex-col">
            <div className="w-full z-10 my-4 flex justify-end">
              <button
                onClick={() => setShowTable(!showTable)}
                className=" bg-red-700 p-3 rounded-md text-[18px] text-white"
              >
                x
              </button>
            </div>
            <div className="overflow-scroll max-h-[300px]">
              <table className="min-w-full divide-y mt-[20px]  divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      ID
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      CHKL_NUM
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Description
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Value
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {checkListItems?.map((itemCheck) => {
                    return (
                      <tr className="text-[15px] font-normal text-gray-600">
                        <td className="px-6 py-4 whitespace-nowrap">
                          {itemCheck?.chkl_item}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {
                            chklItem?.find(
                              (items) => itemCheck?.chkl_item === items?.id
                            )?.chkl_num
                          }
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {
                            chklItem?.find(
                              (items) => itemCheck?.chkl_item === items?.id
                            )?.description
                          }
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {itemCheck?.value}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CheckListGet;
