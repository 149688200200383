import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FaAngleLeft } from "react-icons/fa";
import CheckList from "./BOQ/CheckList";
import FinancialReport from "./BOQ/FinancialReport";
import BOQAmendment from "./BOQ/BOQAmendment";
import axios from "axios";
import ProgressReport from "./ProgressReport";
// import { Box } from "@mui/material";
import TemporaryHandover from "./BOQ/TemporaryHandover";
import TimeExpansion from "./TimeExpansion";

function Building() {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;
  const siteID = localStorage.getItem("siteID");

  const [review, setReview] = useState(true);
  const [checkList, setCheckList] = useState(false);
  const [report, setReport] = useState(false);
  const [boqamend, setBOQAmend] = useState(false);
  const [freport, setFReport] = useState(false);
  const [tempHandover, setTempHandover] = useState(false);
  const [timeExpansion, setTimeExpansion] = useState(false);
  const [fullContraName, setFullContraName] = useState([]);
  const [contractorList, setContractorList] = useState([]);
  const [subContractorList, setSubContractorList] = useState([]);
  const [blockPrice, setBlockPrice] = useState([]);
  // const [calField, setCalField] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    axios
      .get("https://pms.dalyobt.com/api/view_block/")
      .then((response) => {
        setBlockPrice(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    axios
      .get("https://pms.dalyobt.com/api/contractor_view/")
      .then((response) => {
        // console.log(response.data)
        setFullContraName(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    axios
      .get("https://pms.dalyobt.com/api/block_contractor/")
      .then((response) => {
        // console.log(response.data)
        setContractorList(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    axios
      .get("https://pms.dalyobt.com/api/block_sub_contractor/")
      .then((response) => {
        // console.log(response.data)
        setSubContractorList(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  // useEffect(() => {
  //   axios
  //     .get(`https://pms.dalyobt.com/api/calculate_total_block_progress/${id}/`)
  //     .then((response) => {
  //       setCalField(response.data);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // }, [id]);

  // console.log(subContractorList, "SubContractorList")

  // console.log(contractorList, "Contractor-List")

  // console.log(typeof(calField?.total_work_done_percentage), "----------------------CalField-----------------------------------")

  const singleListOfContractor = contractorList.find(
    (item) => item?.block?.toString() === id?.toString()
  );

  // console.log(singleListOfContractor)

  // const singleContractor = fullContraName.find((item)=>singleListOfContractor.find((items)=>items.contractor===item.id))

  const singleContractor = fullContraName.find(
    (item) => item?.id === singleListOfContractor?.contractor
  );

  // console.log(singleContractor?.name, "Single Contractor")

  const filteredSubContractorList = subContractorList.filter(
    (item) => item?.block?.toString() === id?.toString()
  );

  // console.log(filteredSubContractorList, "Assigned-SubContractor")

  const handleReview = () => {
    setReview(true);
    setCheckList(false);
    setReport(false);
    setBOQAmend(false);
    setFReport(false);
    setTempHandover(false);
    setTimeExpansion(false);
  };

  const handleCheckList = () => {
    setReview(false);
    setCheckList(true);
    setReport(false);
    setBOQAmend(false);
    setFReport(false);
    setTempHandover(false);
    setTimeExpansion(false);
  };

  const handleReport = () => {
    setReview(false);
    setCheckList(false);
    setReport(true);
    setBOQAmend(false);
    setFReport(false);
    setTempHandover(false);
    setTimeExpansion(false);
  };

  const handleFReport = () => {
    setReview(false);
    setCheckList(false);
    setReport(false);
    setBOQAmend(false);
    setFReport(true);
    setTempHandover(false);
    setTimeExpansion(false);
  };

  const handleBoq = () => {
    setReview(false);
    setCheckList(false);
    setReport(false);
    setBOQAmend(true);
    setFReport(false);
    setTempHandover(false);
    setTimeExpansion(false);
  };
  const handleTempHandover = () => {
    setReview(false);
    setCheckList(false);
    setReport(false);
    setBOQAmend(false);
    setFReport(false);
    setTempHandover(true);
    setTimeExpansion(false);
  };
  const handleTimeExpansion = () => {
    setReview(false);
    setCheckList(false);
    setReport(false);
    setBOQAmend(false);
    setFReport(false);
    setTempHandover(false);
    setTimeExpansion(true);
  };

  // console.log(singleContractor)

  return (
    <div className=" w-[100%]  h-[91vh]  p-2">
      <div className="w-[90%] mt-[50px] mx-auto flex  justify-between items-center">
        <div className="flex justify-start items-center gap-[20px]">
          <FaAngleLeft
            className="text-[30px] cursor-pointer text-[#0B72B5]"
            onClick={() => navigate("/projects/sites/" + siteID)}
          />
          <span className="text-[#0B72B5] text-[24px] font-bold capitalize">
            {data?.buildingName}
          </span>
        </div>
        {/* <div className="flex items-center gap-[20px]">
          <button
            className="bg-[#0B72B5] text-white p-2 rounded-[5px]"
            onClick={() =>
              navigate("/projcts/sites/buildings/boqamendment", {
                state: {
                  buildingID: data.buildingID,
                },
              })
            }
          >
            BOQ Amendment
          </button>
          <button
            className="bg-[#0B72B5] text-white p-2 rounded-[5px]"
            onClick={() => navigate("/progressreport")}
          >
            Progress Report
          </button>
        </div> */}

        
        {/* <Box
          sx={{
            background: `radial-gradient(white 55%, transparent 56%),
                            conic-gradient(transparent 0deg ${
                              (calField?.total_work_done_percentage / 100) * 360
                            }deg,white ${
              (calField?.total_work_done_percentage / 100) * 360
            }deg 360deg),
                            #0B72B5`,
            borderRadius: "50%",
            width: `70px`,
            height: `70px`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {calField?.total_work_done_percentage ? calField?.total_work_done_percentage:0}%
        </Box> */}
      </div>

      <div className="w-full my-6">
        <div className="w-[90%] mx-auto gap-[20px] p-4 flex justify-between flex-wrap items-center max-[1100px]:justify-start">
          <button
            onClick={handleReview}
            className="bg-[#ffffff] hover:bg-[#0B72B5] focus:bg-[#0B72B5] transition duration-500 hover:text-white focus:text-white shadow-2xl p-2 text-[#0B72B5] rounded-md font-medium outline-none"
            autoFocus
          >
            Review
          </button>

          <button
            onClick={handleCheckList}
            className="bg-[#ffffff] hover:bg-[#0B72B5] focus:bg-[#0B72B5] transition duration-500 hover:text-white focus:text-white shadow-2xl p-2 text-[#0B72B5] rounded-md font-medium outline-none"
          >
            Checklist
          </button>
          <button
            onClick={handleReport}
            className="bg-[#ffffff] hover:bg-[#0B72B5] focus:bg-[#0B72B5] transition duration-500 hover:text-white focus:text-white shadow-2xl p-2 text-[#0B72B5] rounded-md font-medium outline-none"
          >
            Construction Progress Report
          </button>
          <button
            onClick={handleBoq}
            className="bg-[#ffffff] hover:bg-[#0B72B5] focus:bg-[#0B72B5] transition duration-500 hover:text-white focus:text-white shadow-2xl p-2 text-[#0B72B5] rounded-md font-medium outline-none"
          >
            BOQ Amendment
          </button>
          <button
            onClick={handleFReport}
            className="bg-[#ffffff] hover:bg-[#0B72B5] focus:bg-[#0B72B5] transition duration-500 hover:text-white focus:text-white shadow-2xl p-2 text-[#0B72B5] rounded-md font-medium outline-none"
          >
            Financial Report
          </button>
          <button
            onClick={handleTimeExpansion}
            className="bg-[#ffffff] hover:bg-[#0B72B5] focus:bg-[#0B72B5] transition duration-500 hover:text-white focus:text-white shadow-2xl p-2 text-[#0B72B5] rounded-md font-medium outline-none"
          >
            Time Extension
          </button>
          <button
            onClick={handleTempHandover}
            className="bg-[#ffffff] hover:bg-[#0B72B5] focus:bg-[#0B72B5] transition duration-500 hover:text-white focus:text-white shadow-2xl p-2 text-[#0B72B5] rounded-md font-medium outline-none"
          >
            Provisional Acceptance
          </button>
        </div>
      </div>

      {review && (
        <div className="w-full my-6">
          <div className="w-[90%] mx-auto bg-white shadow-md text-gray-700 p-4 rounded-xl flex flex-col gap-[20px]">
            <div className="w-[100%] mt-[20px] flex justify-center items-center">
              <span className="text-[#0B72B5] font-bold">Review</span>
            </div>
            <div className="flex justify-between border border-y-gray-200 border-x-0 p-2 items-center">
              <p className="text-[#0B72B5] font-medium">Price</p>
              <p>
                {
                  blockPrice?.find(
                    (item) => item?.id?.toString() === id?.toString()
                  )?.price
                }
              </p>
            </div>
            <div className="flex justify-between border border-y-gray-200 border-x-0 p-2 items-center">
              <p className="text-[#0B72B5] font-medium">Contractor</p>
              <p>{singleContractor ? singleContractor?.name : "-"}</p>
            </div>
            <div className="flex justify-between border border-y-gray-200 border-x-0 p-2 items-center">
              <p className="text-[#0B72B5] font-medium">Sub-Contractor</p>
              <table className="w-[70%] table-auto">
                <thead>
                  <tr>
                    <th className="px-4 text-start font-medium py-2">
                      Sub-Contractor Name
                    </th>
                    <th className="px-4 text-start font-medium py-2">
                      Work Type
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredSubContractorList.map((item) => {
                    const subcontractorName = fullContraName.find(
                      (items) => items.id === item.contractor
                    )?.name;

                    return (
                      <tr key={item.id} className="bg-white">
                        <td className="border px-4 py-2">
                          {subcontractorName ? subcontractorName : "-"}
                        </td>
                        <td className="border px-4 py-2">
                          {item.type ? item.type : "-"}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {/* <p className="text-[#0B72B5] font-medium">Sub-Contractor</p>
                {filteredSubContractorList.map((item)=>{
                  return <div>
                    <p>{fullContraName.find((items) => items.id === item.contractor) ? fullContraName.find((items) => items.id === item.contractor).name : ''}</p>

                    <p>{item? item?.type : '-'}</p>
                </div>
                })} */}
            </div>
          </div>
        </div>
      )}

      {checkList && <CheckList />}
      {report && <ProgressReport />}
      {boqamend && <BOQAmendment />}
      {freport && <FinancialReport />}
      {tempHandover && <TemporaryHandover />}
      {timeExpansion && <TimeExpansion />}
      {/* <div className="w-[100%] h-[85%] overflow-y-scroll bg-white my-[20px] py-2  mx-auto p-2 mt-[50px] rounded-[5px]">
        <div className="w-[90%] mx-auto mt-[20px]  flex items-center gap-[20px]">
          <div className="w-[25%]">
            <input
              list="data"
              className="w-[100%] p-4 text-[14px] text-gray-600 outline-none border border-gray-400 rounded-[5px]"
              onChange={(e) => setInput(e.target.value)}
              placeholder="Search"
            />
            <datalist color="white" id="data" className="bg-white">
              {checkListContent.map((cl, index) => (
                <option key={index}>{cl.Activities}</option>
              ))}
            </datalist>
          </div>

          <div className="w-[25%]">
            <input
              type="date"
              className="w-[100%] p-4 text-[14px] text-gray-600 outline-none border border-gray-400 rounded-[5px]"
              onChange={(e) => setInput(e.target.value)}
              placeholder="Search"
            />
          </div>
          <div className="w-[25%]">
            <input
              type="text"
              className="w-[100%] p-4 text-[14px] text-gray-600 outline-none border border-gray-400 rounded-[5px]"
              onChange={(e) => setInput(e.target.value)}
              placeholder="Consultant"
            />
          </div>
        </div>
        <CheckListTable />
      </div> */}

      {/* <div className="w-[90%] mx-auto mt-[40px] flex flex-col gap-[10px]">
        <span className="text-[#0B72B5] font-bold">Remark</span>
        <textarea
          rows={8}
          placeholder="Additional Remarks"
          className="w-[40%] p-2 bg-white outline-none border border-gray-400 resize-none rounded-[5px]"
        ></textarea>
      </div>

      <div className="w-[95%] my-[30px] p-4 flex justify-end items-center gap-[20px]">
        <button className="py-3 px-4 bg-[#0B72B5] text-white rounded-[10px]">
          Save
        </button>
      </div> */}
    </div>
  );
}

export default Building;
