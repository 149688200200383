import axios from "axios";
import React, { useState } from "react";
import { FaAngleLeft } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";

function AddNewBOQ() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [errFill, setErrFill] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);
  const [itemNo, setItemNo] = useState(null);
  const [categoryFill, setCategoryFill] = useState("");
  const [unitPrice, setUnitPrice] = useState("");
  const [quan, setQuan] = useState("");
  const [quanUnit, setQuanUnit] = useState("");
  const [workType, setWorkType] = useState("");
  const [dateFill, setDateFill] = useState("");
  const [remarkFill, setRemarkFill] = useState("");
  const [descriptionFill, setDescriptionFill] = useState("");

  const handleSend = async () => {
    if (
      uploadFile &&
      itemNo &&
      categoryFill &&
      unitPrice &&
      quan &&
      quanUnit &&
      workType &&
      dateFill &&
      descriptionFill
    ) {
      const formData = new FormData();
      formData.append("category", categoryFill);
      formData.append("work_type", workType);
      formData.append("Item_no", itemNo);
      formData.append("revision", 0);
      formData.append("attachement", uploadFile);
      formData.append("changeDate", dateFill);
      formData.append("description", descriptionFill);
      formData.append("quantity_unit", quanUnit);
      formData.append("quantity", quan);
      formData.append("unit_price", unitPrice);
      formData.append("remark", remarkFill);
      formData.append("block", id);

      await axios
        .post("https://pms.dalyobt.com/api/boq_item_post/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          navigate(-1)
        })
        .catch((error) => {
          console.error(error);
          setError(true);
          setErrFill(true);
        });
    }
    else{
      setError(true);
      setErrFill(false);
    }
  };

  return (
    <div className="w-[100%] min-h-[91.5vh] flex flex-col  py-4  bg-white">
      <div className="w-[90%] mx-auto my-[30px] flex justify-between items-center">
        <div className=" flex items-center gap-[30px]">
          <FaAngleLeft
            className="text-[30px] cursor-pointer text-[#0B72B5]"
            onClick={() => navigate(-1)}
          />
          <span className="text-[20px] text-[#0B72B5] font-bold">
            Add New BOQ
          </span>
        </div>
      </div>
      {/*		PB END DATE	AG START DATE	AG END DATE	REMARK */}
      {error && (
        <div className="w-[80%] mx-auto my-2 bg-red-500 text-center p-2 rounded-md">
          <p className="text-white font-medium">
            {errFill ? <span>Please check the item number or the category.</span> : <span>Please Check the fields again there might be a missing field.</span>}
          </p>
        </div>
      )}
      <div className="w-[80%] mt-[50px] max-h-[500px] p-2 overflow-y-scroll mx-auto flex flex-col gap-[20px]">
        <div className="flex items-center justify-between gap-[10px]">
          <div className="w-[50%]  flex flex-col  items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              Item Number <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="text"
                placeholder="1.01"
                onChange={(e) => setItemNo(e.target.value)}
                className="w-[100%] border border-[#ced4da] py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>
          <div className="w-[50%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              Category <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="text"
                placeholder="SUB-STRUCTURE"
                onChange={(e) => setCategoryFill(e.target.value)}
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>
        </div>

        <div className="flex justify-between gap-[10px] my-[20px]">
          <div className="w-[100%] flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              Unit Price <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="number"
                placeholder="20.35"
                min={0}
                onChange={(e) => setUnitPrice(e.target.value)}
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>

          <div className="w-[100%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              Quantity <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="number"
                placeholder="250.25"
                min={0}
                onChange={(e) => setQuan(e.target.value)}
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>

          {/* <div className="w-[40%]  flex flex-col items-start gap-[20px]">
            {/* <span className='text-[14px] font-bold w-[80%] text-[#0B72B5]'>Status</span>
            <div className='w-[100%] rounded-[5px]'>
                <select onChange={(e)=>setStatus(e.target.value)} className='w-[100%] border border-[#ced4da]  p-4 rounded-[5px] text-[14px] outline-none'>

                    <option value={"ongoing"}>ongoing</option>
                    <option value={"Not started"}>Not Started</option>
                    <option value={"Completed"}>Completed</option>
                </select>
            </div> */}
          {/* </div>  */}
        </div>
        <div className="flex justify-between my-[20px] gap-[10px]">
          <div className="w-[50%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              Quantity Unit <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="text"
                onChange={(e) => setQuanUnit(e.target.value)}
                placeholder="m2, m3"
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>

          <div className="w-[50%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              Work Type <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="text"
                placeholder="1 Excavation and Earth Works"
                onChange={(e) => setWorkType(e.target.value)}
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>
        </div>

        <div className="flex justify-between my-[20px] gap-[10px]">
          <div className="w-[50%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              Date <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="date"
                onChange={(e) => setDateFill(e.target.value)}
                // placeholder="m2, m3"
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>
        </div>

        <div className="flex justify-between my-[20px] gap-[10px]">
          <div className="w-full flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              Remark
            </span>
            <div className="w-[100%] rounded-[5px]">
              <textarea
                rows={8}
                placeholder="Additional Remarks"
                onChange={(e) => setRemarkFill(e.target.value)}
                className="w-[100%] p-2 bg-white outline-none border border-gray-400 resize-none rounded-[5px]"
              />
            </div>
          </div>
        </div>

        <div className="flex justify-between my-[20px] gap-[10px]">
          <div className="w-full flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              Description <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <textarea
                rows={5}
                placeholder="Descriptions"
                onChange={(e) => setDescriptionFill(e.target.value)}
                className="w-[100%] p-2 bg-white outline-none border border-gray-400 resize-none rounded-[5px]"
              />
            </div>
          </div>
        </div>

        <div className="w-full">
          <div className=" mx-auto flex justify-start p-2 items-center gap-[10px]">
            <div className="flex flex-col items-start gap-[20px]">
              <button>
                <label>
                  <input
                    type="file"
                    onChange={(e) => setUploadFile(e.target.files[0])}
                    name="myfile"
                    accept=".pdf"
                    hidden
                  />
                  <span className="bg-[#0B72B5] text-white p-4 cursor-pointer text-[12px] rounded-[5px]">
                    Upload Attachement
                  </span>
                </label>
              </button>
            </div>
            <span className="text-red-700">*</span>
          </div>
        </div>

        {uploadFile && (
          <div className="my-4 border border-gray-400 rounded-md w-[90%] p-2 mx-auto">
            <embed
              src={URL.createObjectURL(uploadFile)}
              type="application/pdf"
              width="100%"
              height="600px"
            />
          </div>
        )}
      </div>

      <div className="w-[80%] mt-[30px] flex justify-end items-center gap-[20px]">
        <button onClick={() => navigate(-1)}>Cancel</button>
        <button
          className="py-3 px-4 bg-[#0B72B5] text-white rounded-[10px]"
          onClick={handleSend}
        >
          Add BOQ
        </button>
      </div>
    </div>
  );
}

export default AddNewBOQ;
