import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { FaAngleLeft } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import SiteOverviewTable from './SiteOverviewTable';
import BlockBar from '../BlockBar';

function SiteOverview() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [sites, setSites] = useState([]);
    const [block, setBlock] = useState([]);
    const selectState = useSelector((state) => state.sideBar.sidebarClosed);
    const [selectedBlock, setSelectedBlock] = useState(block && block[0]?.id);
 
  
    useEffect(() => {
      // Fetch data from Django API
      axios
        .get("https://pms.dalyobt.com/api/view_site/")
        .then((response) => {
          
          // Set the data received from the API to the state
          setSites(response.data);
          // console.log(response.data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }, []);
  
    useEffect(() => {
      axios
        .get(`https://pms.dalyobt.com/api/view_block/${id}/`)
        .then((response) => {
          setBlock(response.data);
        
        })
        .catch((error) => {
          console.error("Error fetching data: ", error);
        });
    }, [id]);
  
    const site = sites?.find((pt) => {
      return pt.id.toString() === id.toString();
    });
  
  

    
  
  
 
  return (
    <div className="w-[90%] mx-auto mt-[50px] flex flex-col">
      <div className="w-[100%] mt-6 mx-auto flex items-center gap-[10px]">
        <FaAngleLeft
          className="text-[30px] cursor-pointer text-[#FDC00D] "
          onClick={() => navigate(-1)}
        />
        <span className="text-[20px] text-[#FDC00D] font-bold">
          {site?.name}
        </span>
      </div>
      <div className="w-[100%] flex gap-[20px] max-[1100px]:flex-col">
        <div
          className={
            selectState
              ? " w-[50%] h-[350px] overflow-x-auto mt-[50px] bg-white p-4 rounded-[10px] shadow-lg flex flex-col items-center  justify-center max-[1100px]:w-[90%] max-[1100px]:mx-auto"
              : "w-[50%] h-[400px] mt-[50px] bg-white p-4 rounded-[10px] flex  flex-col items-center justify-center max-[1100px]:w-[90%] max-[1100px]:mx-auto"
          }
        >
          <div className="my-4 mx-auto flex justify-center font-bold">
            <span className="text-[#FDC00D]">{site?.name} Progress</span>
          </div>

          <BlockBar block={block} />
        </div>
        <div
          className={
            selectState
              ? " w-[50%] h-[350px] overflow-x-auto mt-[50px] bg-white p-4 rounded-[10px] shadow-lg flex flex-col items-center max-[1100px]:w-[90%] max-[1100px]:mx-auto"
              : "w-[50%] h-[400px] mt-[50px] bg-white p-4 rounded-[10px] flex  flex-col items-center justify-center max-[1100px]:w-[90%] max-[1100px]:mx-auto"
          }
        >
          <div className="my-4 mx-auto flex justify-center font-bold">
            <span className="text-[#FDC00D]">{site?.name} Review</span>
          </div>
          <div className="w-[80%] mt-[30px]  mx-auto flex  items-center justify-around  overflow-x-auto  sm:rounded-l">
            <div className="flex flex-col justify-center gap-[25px] font-bold uppercase text-[14px] text-gray-700">
              <span>Site Name</span>
              <span>Progress</span>
              <span>Start Date</span>
              <span>End Date</span>
            </div>
            <div className="flex flex-col gap-[25px] justify-center text-gray-500 text-[14px]">
              <span>{site?.name}</span>
              <span>{site?.progress_percentage ? site?.progress_percentage:0}%</span>
              <span>{site?.start_date?.split("T")[0]}</span>
              <span>{site?.end_date.split("T")[0]}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="w-[100%] mt-[50px] rounded-[10px] shadow-lg bg-white py-4 flex flex-col justify-center items-center">
        <div className="w-[1000px] flex justify-center items-center gap-[20px] overflow-x-auto">
            <select
            onChange={(e)=>setSelectedBlock(e.target.value)}
             className="block w-full p-4 rounded-md border-0  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FBB042] outline-none sm:text-sm sm:leading-6 max-lg2:p-2 max-lg2:text-[12px]"
            >
                <option value={""}>Select block</option>
            {block?.map((bloc) => {
            return (
              <option
              value={bloc?.id}
                key={bloc?.id}
             
                
              >
                {bloc?.name}
              </option>
            );
          })}

            </select>
        
        </div>
        <div className="w-[100%] mt-[50px]">
          {site && selectedBlock &&  (
            <SiteOverviewTable sites={block} selectedBlock={selectedBlock} />
          )}
        </div>
      </div>
    </div>
  )
}

export default SiteOverview