import axios from "axios";
import React from "react";

function ViewBOQ({ data, setViewBOQ, building, setFile }) {
  const siteID = localStorage.getItem("siteID");
  const handleSave = async () => {
    try {
      const token = localStorage.getItem("token");
        // console.log('Data: ' , data)
      //   console.log(token)
      const headers = {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      };

      await axios
        .post("https://pms.dalyobt.com/api/save-data/", data, { headers })
        .then((response) => {
          console.log(response.status);
          window.location.href = `/projects/sites/${siteID}`;
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error); // Handle the error
      // Perform additional actions on error
    }
  };

  const handleCancel = () => {
    setViewBOQ(false);
    setFile(null);
    window.location.href = `/projects/sites/${siteID}`;
  };

  return (
    <div className=" w-[100%] min-h-[100vh] my-[20px] flex flex-col justify-between py-4 top-[9%] bg-white shadow-xl absolute left-[0] z-[100] rounded-[10px]">
      <div>
        <div className="w-[90%] mx-auto flex justify-between items-center">
          <div className="w-[90%] mx-auto mt-[50px] flex justify-start items-center gap-[10px]">
            <span className=" text-[#0B72B5] text-[20px] font-bold py-2 px-4 rounded-[5px]">
              Uploaded File for {building?.type} building
            </span>
          </div>
          <div className="w-[90%] my-[50px] flex justify-end items-center gap-[20px]">
            <button
              onClick={handleCancel}
              className="py-2 px-4 bg-[#0B72B5] text-white rounded-[10px]"
            >
              Cancel
            </button>
            <button
              onClick={handleSave}
              className="py-2 px-4 bg-[#0B72B5] text-white rounded-[10px]"
            >
              Save
            </button>
          </div>
        </div>

        <table className="w-[90%] mx-auto mt-[30px] text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-center text-gray-700 uppercase border-b border-gray-300">
            <tr>
              <th scope="col" className="px-6 py-3">
                Item_no
              </th>
              <th scope="col" className="px-6 py-3">
                Category
              </th>
              <th scope="col" className="px-6 py-3">
              Work Type
              </th>
              <th scope="col" className="px-6 py-3">
            Description
              </th>

              <th scope="col" className="px-6 py-3">
                Quantity Unit
              </th>
              <th scope="col" className="px-6 py-3">
                Quantity
              </th>
              <th scope="col" className="px-6 py-3">
                Unit Price
              </th>
              <th scope="col" className="px-6 py-3">
                Remark
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.map((d, index) => {
              return (
                <tr
                  key={index}
                  className="text-center text-gray-600 border-b border-gray-300 "
                >
                  <td className="px-6 py-4">{d.item_no ? d.item_no : "-"}</td>
                  <td className="px-6 py-4">{d.category ? d.category : "-"}</td>
                  <td className="px-6 py-4">
                    {d.work_type ? d.work_type : "-"}
                  </td>
                  <td className="px-6 py-4">
                    {d.description ? d.description : "-"}
                  </td>
                  <td className="px-6 py-4">
                    {d.quantity_unit ? d.quantity_unit : "-"}
                  </td>
                  <td className="px-6 py-4">{d.quantity ? d.quantity : "-"}</td>

                  <td className="px-6 py-4">
                    {d.unit_price ? d.unit_price : "-"}
                  </td>
                  <td className="px-6 py-4">{d.remark ? d.remark : "-"}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ViewBOQ;
