
// import LoginContainer from '../components/LoginContainer'
// import CreateAccount from '../components/CreateAccount'
// import React, { useState } from 'react';
// import axios from 'axios';
// import api from './api';

// function Login() {
//   const [toggleSignIn, setToggleSignIn] = useState(true);
//   const [username, setUsername] = useState('');
//   const [password, setPassword] = useState('');
//   const [error, setError] = useState('');

//   const handleLogin = async () => {
//     try {
//       const response = await axios.post('https://pms.dalyobt.com/api/login/', {
//         username ,
//         password,
//       });

//       if (response.status === 200) {
//         // Authentication successful
//         const token = response.data.token;
//         console.log(token);

//         localStorage.setItem('token', token);
//         // Redirect or perform any necessary actions for successful authentication
//       } else {
//         // Authentication failed
//         console.log('Authentication failed');
//       }
//     } catch (error) {
//       console.log('Error occurred:', error);
//     }
//   };

//   return (
//     <div className='min-h-[100vh] flex items-center'>
//       {toggleSignIn ? (
//         <LoginContainer
//           toogleSignIn={toggleSignIn}
//           settoggleSignIn={setToggleSignIn}
//         />
//       ) : (
//         <CreateAccount
//           toogleSignIn={toggleSignIn}
//           settoggleSignIn={setToggleSignIn}
//         />
//       )}

//       <button onClick={handleLogin}>Login</button>
//     </div>
//   );
// }

// export default Login;

import LoginContainer from '../components/LoginContainer'
import CreateAccount from '../components/CreateAccount'
import React, { useState } from 'react';
import axios from 'axios';
// import api from './api';

function Login() {
  const [toggleSignIn, setToggleSignIn] = useState(true);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = async () => {
    try {
      const response = await axios.post('https://pms.dalyobt.com/api/login/', {
        username,
        password,
      });

      if (response.status === 200) {
        // Authentication successful
        const token = response.data.token;
    

        localStorage.setItem('token', token);
        // Redirect or perform any necessary actions for successful authentication
      } else {
        // Authentication failed
        console.log('Authentication failed');
      }
    } catch (error) {
      console.log('Error occurred:', error);
      setError('Failed to login. Please check your credentials.');
    }
  };

  return (
    <div className='min-h-[100vh] flex items-center'>
      {toggleSignIn ? (
        <LoginContainer
          toogleSignIn={toggleSignIn}
          settoggleSignIn={setToggleSignIn}
          username={username}
          password={password}
          setUsername={setUsername}
          setPassword={setPassword}
          handleLogin={handleLogin}
          error={error}
        />
      ) : (
        <CreateAccount
          toogleSignIn={toggleSignIn}
          settoggleSignIn={setToggleSignIn}
        />
      )}
    </div>
  );
}


export default Login;

