import { Box } from '@mui/material';
import React from 'react'
import { Link } from 'react-router-dom';

function DashboardStatistics({projects,sites}) {
  return (
    <div className="w-[100%] mt-[50px] max-[1120px]:w-[100%] max-[1120px]:flex-nowrap">
    {/* <span className="text-[12px] text-gray-500">STATISTICS</span> */}
    <div className="flex flex-wrap justify-start gap-[20px] max-[1300px]:flex-col max-[1120px]:flex-row">
      {projects?.map((st) => {
        return (
          <Link
            to={`/projectoverview/${st.id}`}
            key={st.id}
            className="w-[30%] mt-[10px] flex flex-col gap-[30px] cursor-pointer  p-4 border   bg-white shadow-xl rounded-[10px] max-[1300px]:w-[100%] max-[1120px]:w-[45%] "
          >
            <div className="w-[100%] mx-auto flex justify-between items-center">
              <span>{st?.name}</span>
              {/* <BsThreeDotsVertical className='cursor-pointer'/> */}
            </div>
            <div className="flex justify-between items-center">
              <span className="font-bold text-[16px]">
                {
                  sites?.filter(
                    (item) =>
                      item?.project_id?.toString() === st?.id?.toString()
                  ).length
                }{" "}
                Sites
              </span>

              <div style={{ textAlign: "center" }}>
                {/* {console.log(parseInt(st.progress) / 100 * 360)} */}

                <Box
                  sx={{
                    background: `radial-gradient(white 55%, transparent 56%),
                      conic-gradient(transparent 0deg ${
                        (parseInt(st?.progress_percentage) / 100) * 360
                      }deg,white ${
                      (parseInt(st?.progress_percentage) / 100) * 360
                    }deg 360deg),
                      #FDC00D`,
                    borderRadius: "50%",
                    width: `70px`,
                    height: `70px`,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {st?.progress_percentage}%
                </Box>
              </div>
            </div>
          </Link>
    );
      })}
    </div>
  </div>
  )
}

export default DashboardStatistics