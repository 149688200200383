import React, { useEffect, useState } from "react";
import { FaAngleLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ContractorAssignTable from "./ContractorAssignTable";

function PreConstruction() {
  const navigate = useNavigate();
  const [project, setProject] = useState("");
  const [site, setSite] = useState("");
  const [block, setblock] = useState("");
  const [projectList, setProjectList] = useState([]);
  const [projID, setProjID] = useState('');
  const [currentSiteID, setCurrentSiteID] = useState('');
  const [siteList, setSiteList] = useState([]);
  const [blockList, setBlockList] = useState([])
  const [currentBlockID, setCurrentBlockID] = useState('')
  const [currentBlockInfo, setCurrentBlockInfo] = useState(null) 
  const [contractorChoice, setContractorChoice] = useState('')
  const [contractorList, setContractorList] = useState([])
  const [contraList, setContraList] = useState([])
  const [listOfContractor, setListOfContractor] = useState([])
  const [error, setError] = useState(false);
  // const [currentProjectID, setCurrentProjectID] = useState(null);

  useEffect(() => {
    if (project !== null || project !== "") {
      axios
        .get(`https://pms.dalyobt.com/api/view_project`)
        .then((response) => {
          setProjectList(response.data);
        })
        .catch((error) => {
          setError(true);
          console.error(error)
        });
    }
  }, [project]);
  useEffect(() => {
    //site !== null || site !== "" || 
    if (projID!=='') {
      axios
        .get(`https://pms.dalyobt.com/api/view_site/${projID}`)
        .then((siteresponse) => {
          setSiteList(siteresponse.data);
        })
        .catch((error) => {
          setError(true);
          // console.error(error)
        });
    }
  }, [project, site, projID]);

  useEffect(()=>{
    // block !== null || block !== "" || projID !== '' || 
    if(currentSiteID !== ''){
      axios.get(`https://pms.dalyobt.com/api/view_block/${currentSiteID}`).then((blockresponse)=>{
        setBlockList(blockresponse.data)
      }).catch((error) => {
        setError(true); 
        console.error(error)
      });
    }
  },[project, block, projID, currentSiteID])

  useEffect(()=>{
    if(currentBlockID!==''){
      axios.get(`https://pms.dalyobt.com/api/block_contractor/${currentBlockID}`).then((contractorresponse)=>{
        setContractorList(contractorresponse.data)
      }).catch((error) => {
        setError(true); 
        // console.error(error)
      });
    }
  },[project, block, projID, currentSiteID, currentBlockID])

  useEffect(()=>{
    axios.get('https://pms.dalyobt.com/api/block_contractor/').then((contraresponse)=>{
      setContraList(contraresponse.data)
    }).catch((error)=>{
      setError(true)
      // console.error(error)
    })
  },[])

  useEffect(()=>{
    axios.get('https://pms.dalyobt.com/api/contractor_view/').then((listContractor)=>{
      setListOfContractor(listContractor.data)
    }).catch((error)=>{
      setError(true)
      // console.error(error)
    })
  },[])

  const filteredGroup = blockList?.filter((item) => !contraList?.some((contraItem) => contraItem?.block === item?.id));

  // console.log(filteredGroup)

  const handleProjectChange = (e) => {
    setProject(e.target.value);
    // You can find the currentProjectID from projectList based on the selected project name
    const selectedProject = projectList.find((item) => item.name === e.target.value);
    if (selectedProject) {
      setProjID(selectedProject.id);
    }
  };

  const handleSiteChange = (e)=>{
    setSite(e.target.value)
    const selectedSite = siteList.find((item) => item.name === e.target.value);
    if (selectedSite) {
      setCurrentSiteID(selectedSite.id);
    }
  }

  const handleBlockChange = (e)=>{
    setblock(e.target.value)
    const selectedBlock = blockList?.find((item) => item?.name === e.target.value);
    if (selectedBlock) {
      setCurrentBlockID(selectedBlock?.id);
      setCurrentBlockInfo(selectedBlock)
    }
  }

  // console.log(selectedBlock)

  const findContractorID = listOfContractor?.find((item)=>{
    return item.name===contractorChoice
  })

  // console.log(findContractorID?.id)


  // console.log("projectList: ", projectList);
  // console.log("siteList: ", siteList);

  return (
    <div className="mt-[50px]">
      <div className="w-[90%] mt-6 mx-auto flex justify-between">
        <div className="flex justify-start items-center gap-[20px]">
          <FaAngleLeft
            className="text-[30px] cursor-pointer text-[#0B72B5]"
            onClick={() => navigate("/")}
          />
          <span className="text-[20px] font-bold text-[#0B72B5]">
            Preconstruction
          </span>
        </div>
        <div>
          <button
            onClick={() => navigate("/preconstruction/addcontractor")}
            className="bg-[#0B72B5] py-2 px-4 text-white text-[14px] rounded-[10px]"
          >
            Add Contractor
          </button>
        </div>
      </div>
      <div className="bg-white shadow-xl w-[80%] mx-auto mt-[50px]  flex justify-center p-2 items-center gap-[20px] max-[1000px]:w-[90%]">
        <div className="w-[20%]">
         
           <input
            list="projectList"
            required
            className="w-[100%] p-4 text-[14px] text-gray-600 outline-none border border-gray-400 rounded-[5px]"
            onChange={handleProjectChange}
            placeholder="Choose Project"
          />
          <datalist color="white" id="projectList" className="bg-white">
            {projectList?.map((cl, index) => (
              <option key={index}>{cl?.name}</option>
            ))}
          </datalist> 
        </div>

        <div className="w-[20%]">
          
          <input
            list="siteList"
            className="w-[100%] p-4 text-[14px] text-gray-600 outline-none border border-gray-400 rounded-[5px]"
            required
            onChange={handleSiteChange}
            placeholder="Choose Site"
          />
          <datalist color="white" id="siteList" className="bg-white">
            {siteList?.map((cl, index) => (
              <option key={index}>{cl.name}</option>
            ))}
            {/* `      <option>Site-1</option>` */}
          </datalist>
        </div>
        <div className="w-[20%]">
          <input
            list="blockList"
            required
            className="w-[100%] p-4 text-[14px] text-gray-600 outline-none border border-gray-400 rounded-[5px]"
            onChange={handleBlockChange}
            placeholder="Choose Block"
          />
          <datalist color="white" id="blockList" className="bg-white">
            {/* {checkListContent.map((cl,index)=><option key={index}>{cl.Activities}</option>)} */}
            {filteredGroup?.map((cl, index) => (
              <option key={index}>{cl.name}</option>
            ))}
          </datalist>
        </div>

        {currentBlockID && <div className="w-[25%] flex gap-2 items-center">
          <div>
            <select  onChange={(e) => setContractorChoice(e.target.value)} className="w-[100%] p-4 text-[14px] text-gray-600 outline-none border border-gray-400 rounded-[5px]">
            <option>select contractor</option>
            {listOfContractor?.map((cl, index) => (
           
                <option key={index}>{cl.name}</option>
              ))}
            </select>
            {/* <input
              list="listOfContractor"
              required
              className="w-[100%] p-4 text-[14px] text-gray-600 outline-none border border-gray-400 rounded-[5px]"
              onChange={(e) => setContractorChoice(e.target.value)}
              placeholder="Choose Contractor"
            />
            <datalist color="white" id="listOfContractor" className="bg-white">
              {/* {checkListContent.map((cl,index)=><option key={index}>{cl.Activities}</option>)} */}
              {/* {listOfContractor?.map((cl, index) => (
                <option key={index}>{cl.name}</option>
              ))} */}
            {/* </datalist> */} 
          </div>
          {/* <div>
            <button className="outline-none border-none bg-[#0B72B5] text-white shadow-md p-2 text-[14px] rounded-md">
                Assign Contractor
            </button>
          </div> */}
        </div>}

      </div>


      {contractorChoice && <div>
        <ContractorAssignTable currentBlockInfo={currentBlockInfo} currentBlockID={currentBlockID} currentContractorID={findContractorID?.id} />
      </div>}


    </div>
  );
}

export default PreConstruction;
