import React, { useEffect, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
// import { RxEnvelopeClosed } from "react-icons/rx";
import { BiBell } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { sideBarAction } from "../REDUX/sidebarSlice";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
function Navbar() {
  const navigate = useNavigate();
  const selectState = useSelector((state) => state.sideBar.sidebarClosed);
  const dispatch = useDispatch();
  const [profile, setProfile] = useState([]);
  const userID = localStorage.getItem("userID");

  useEffect(() => {
    axios
      .get("https://pms.dalyobt.com/api/user_profile_pic/")
      .then((response) => {
        setProfile(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const filteredProfile = profile.find(
    (item) => item?.user?.toString() === userID?.toString()
  );

  const handleLogout = () => {
    localStorage.clear();

    window.location.href = "/login";
  };

  const handleMenu = () => {
    dispatch(sideBarAction.toggleSidebar(!selectState));
  };

  return (
    <div className="w-[100%] h-[80px] flex justify-between items-center border-b border-gray-300 px-4 bg-white sticky top-0 z-[1000]">
      <div>
        <GiHamburgerMenu
          className="text-[25px] text-[#353535] cursor-pointer"
          onClick={handleMenu}
        />
      </div>
        <div className="flex flex-col justify-center items-center max-[900px]:hidden">
          <span className="text-[14px] text-[#0B72B5] font-bold">ADDIS ABABA HOUSING DEVELOPMENT CORPORATION</span>
          <span className="text-[12px] text-[#FDC00D] font-bold">PROJECT MONITORING</span>
        </div>
      <div className="p-2 flex gap-[20px] items-center text-[30px] text-[#808080]  max-[1120px]:justify-end">
        {/* <div className="relative">
          <RxEnvelopeClosed />
          <div className="w-[15px] h-[15px] absolute top-0 right-0 bg-[#FDC00D] rounded-full flex justify-center items-center text-[10px] text-white">
            3
          </div>
        </div> */}

        <div
          className="relative cursor-pointer"
          onClick={() => navigate("/notification")}
        >
          <BiBell className="text-[#0B72B5]" />
          {/* <div className="w-[15px] h-[15px] absolute top-0 right-0 bg-[#1B3E74] rounded-full flex justify-center items-center text-[10px] text-white">
            3
          </div> */}
        </div>
        <div className="relative">
          <div className="w-[40px] h-[40px] rounded-full">
            <Link to='/myprofile'>
            {filteredProfile?.profile_picture ? (
              <img
                src={`https://pms.dalyobt.com${filteredProfile?.profile_picture}`}
                className="w-[100%] h-[100%] object-cover rounded-full"
                alt=""
              />
            ) : (
              <img
                // src={require("../Images/person.jpg")}
                src={require("../Images/userLogo.jpg")}
                className="w-[100%] h-[100%] object-cover rounded-full"
                alt=""
              />
            )}
            </Link>
            {/* <div className="w-[10px] h-[10px] absolute top-0 right-[-5px] bg-[#2CBC29] rounded-full flex justify-center items-center text-[10px] text-white"></div> */}
          </div>
        </div>
        <div>
          <button
            onClick={handleLogout}
            className="text-[14px] bg-[#0B72B5] p-3 text-white rounded-[5px]"
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
