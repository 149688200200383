import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import SubContractorAssignTable from "./SubContractorAssignTable";
import { FaAngleLeft } from "react-icons/fa";

function AddSubContractor() {
  const { block_id } = useParams();
  const navigate = useNavigate();
  const [subContractorList, setSubContractorList] = useState([]);
  const [blockList, setBlockList] = useState([]);
  const [fullContraName, setFullContraName] = useState([]);
  const siteID = localStorage.getItem("siteID");

  const [types, setTypes] = useState("");
  const [contractorChoice, setContractorChoice] = useState("");
  const [remark, setRemark] = useState('')

  useEffect(() => {
    axios
      .get("https://pms.dalyobt.com/api/block_contractor/")
      .then((response) => {
        // console.log(response.data)
        setSubContractorList(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    axios
      .get("https://pms.dalyobt.com/api/view_block/")
      .then((response) => {
        // console.log(response.data)
        setBlockList(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    axios
      .get("https://pms.dalyobt.com/api/contractor_view/")
      .then((response) => {
        // console.log(response.data)
        setFullContraName(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const filteredDataOfBlock = subContractorList.find(
    (item) => item.block.toString() === block_id.toString()
  );

  const filteredBlockName = blockList.find(
    (item) => item.id.toString() === block_id.toString()
  );
  // console.log(filteredDataOfBlock.contractor, "Filtered")

  //   const listOfContractorsAll = subContractorList.filter(
  //     (item) => item?.contractor !== filteredDataOfBlock?.contractor
  //   );

  // console.log(listOfContractor, "List-Of-Contractor")

  const singleListOfContractor = subContractorList.find(
    (item) => item?.contractor === filteredDataOfBlock?.contractor
  );

  //   console.log(singleListOfContractor.contractor.toString(), "Single-Contractor")

  const singleContractorname = fullContraName.find(
    (items) =>
      items.id.toString() === singleListOfContractor?.contractor.toString()
  );

  //   console.log(singleContractorname?.name, "Final Contractor Name")

  const listOfContractor = fullContraName.filter(
    (items) => items.id.toString() !== singleContractorname?.id.toString()
  );

  const choosedContractor = fullContraName.find((item)=>item.name===contractorChoice)

//   console.log(choosedContractor.id)

  // console.log(listOfContractor, "Available Contractors")

  const handleTypeChange = (e) => {
    setTypes(e.target.value);
  };

  return (
    <div
      className={
        filteredDataOfBlock
          ? "w-[90%] mx-auto my-5 flex justify-center items-center"
          : " h-[700px] w-[90%] mx-auto my-5 flex justify-center items-center"
      }
    >
      {filteredDataOfBlock ? (
        <div className="w-full my-5">
          <div className="w-[80%] mt-6 gap-5 mx-auto flex justify-between">
            <div className="w-[100%] flex justify-start items-center gap-[20px]">
              <FaAngleLeft
                className="text-[30px] cursor-pointer text-[#0B72B5]"
                onClick={() => navigate(`/projects/sites/${siteID}`)}
              />
              <span className="text-[20px] capitalize font-bold text-[#0B72B5]">
                {filteredBlockName?.name} - Contractor Name :{" "}
                {singleContractorname?.name} / Add SubContractor
              </span>
            </div>
            {/* <div>
              <button
                onClick={() => navigate("/preconstruction/addcontractor")}
                className="bg-[#0B72B5] p-4 text-white text-[14px] rounded-[10px]"
              >
                Add Contractor
              </button>
            </div> */}
          </div>
          <div className="bg-white shadow-xl w-[80%] mx-auto mt-[50px]  flex justify-center p-2 items-center gap-[20px] max-[1000px]:w-[100%]">
            <div className="w-[30%]">
              <input
                required
                className="w-[100%] p-4 text-[14px] text-gray-600 outline-none border border-gray-400 rounded-[5px]"
                onChange={handleTypeChange}
                placeholder="Type Of Works"
              />
            </div>

            {types && (
              <div className="w-[25%] flex gap-2 items-center">
                <div>
                  <input
                    list="listOfContractor"
                    required
                    className="w-[100%] p-4 text-[14px] text-gray-600 outline-none border border-gray-400 rounded-[5px]"
                    onChange={(e) => setContractorChoice(e.target.value)}
                    placeholder="Choose Sub-Contractor"
                  />
                  <datalist
                    color="white"
                    id="listOfContractor"
                    className="bg-white"
                  >
                    {/* {checkListContent.map((cl,index)=><option key={index}>{cl.Activities}</option>)} */}
                    {listOfContractor?.map((cl, index) => (
                      <option key={index}>{cl.name}</option>
                    ))}
                  </datalist>
                </div>
              </div>
            )}

            {(types && contractorChoice) && <div className="w-[35%] flex gap-2 items-center">
                <input
                    className="w-[100%] p-4 text-[14px] text-gray-600 outline-none border border-gray-400 rounded-[5px]"
                    onChange={(e) => setRemark(e.target.value)}
                    placeholder="Remark"
                />
            </div>}

          </div>

          {(types && contractorChoice) && (
            <div>
              <SubContractorAssignTable
                type={types}
                remark={remark}
                currentBlockID={block_id}
                currentContractorID={choosedContractor?.id}
              />
            </div>
          )}
        </div>
      ) : (
        <div className="w-[60%] bg-white p-8 rounded-md shadow-xl flex flex-col gap-8 justify-center items-center">
          <p className="font-bold text-red-800 text-center capitalize">
            Cannot Assign subcontractor to this block since it is not assigned
            with main contractor.
          </p>
          <button
            onClick={() => navigate(`/projects/sites/${siteID}`)}
            className="bg-[#0B72B5] rounded-md p-2 outline-none border-none text-white font-medium"
          >
            Return to Sites
          </button>
        </div>
      )}
    </div>
  );
}

export default AddSubContractor;
