import React, { useEffect, useState } from 'react'
import BranchLineBar from '../BranchLineBar';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import axios from 'axios';

function Branch() {
    const username = localStorage.getItem('username');
    const [branches, setBranches] = useState([]);
    const [projects,setProjects] = useState([])


    useEffect(() => {
       
        axios
          .get("https://pms.dalyobt.com/api/view_branch/")
          .then((response) => {
         
 
           
            setBranches(response.data);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      }, []);

      useEffect(() => {
        // Fetch data from Django API
        axios
          .get("https://pms.dalyobt.com/api/view_project/")
          .then((response) => {
            // Set the data received from the API to the state
            setProjects(response.data, "Projects");
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      }, []);

  return (
    <div className="w-[90%] mt-[30px] mx-auto  p-4 rounded-[10px] max-[1100px]:w-[100%]">
    <div className="flex flex-col">
      <span className="text-[30px] text-[#0B72B5] font-semibold">Branch Analytics</span>
      <span className="text-[14px]   text-gray-600">
        Welcome back, {username}
      </span>
    </div>
    <div className="w-[100%] mx-auto  mt-[30px] flex justify-between gap-[10px] ">
      <div
        className={
       
        "w-[80%] h-[400px] overflow-x-auto bg-white p-4 rounded-[10px] flex  flex-col items-center justify-center max-[1300px]:w-[90%]"
        }
      >
        <div className="my-4 mx-auto flex justify-center font-bold">
          <span className="text-[#FDC00D]">Branch Progress</span>
        </div>

        <BranchLineBar />
      </div>
   

    </div>

    <div className="w-[100%] mt-[50px] max-[1120px]:w-[100%] max-[1120px]:flex-nowrap">
    <span className="text-[12px] text-gray-500">BRANCH STATISTICS</span>
    <div className="flex flex-wrap justify-start gap-[20px] max-[1300px]:flex-col max-[1120px]:flex-row">
      {branches?.map((st) => {
        const projectList =  projects?.filter((pro)=>pro?.branch_id === st?.id);
        return (
          <Link
            to={`/branch/project/analytics/${st?.id}`}
            key={st.id}
            className="w-[30%] mt-[10px] flex flex-col gap-[30px] cursor-pointer  p-4 border   bg-white shadow-xl rounded-[10px] max-[1300px]:w-[100%] max-[1120px]:w-[45%] "
          >
            <div className="w-[100%] mx-auto flex justify-between items-center">
              <span>{st?.name}</span>
              {/* <BsThreeDotsVertical className='cursor-pointer'/> */}
            </div>
            <div className="flex justify-between items-center">
              <span className="font-bold text-[16px]">
                {projectList?.length}{" "}
                projects
              </span>

              <div style={{ textAlign: "center" }}>
                {/* {console.log(parseInt(st.progress) / 100 * 360)} */}

                <Box
                  sx={{
                    background: `radial-gradient(white 55%, transparent 56%),
                      conic-gradient(transparent 0deg ${
                        (parseInt(st?.physical) / 100) * 360
                      }deg,white ${
                      (parseInt(st?.physical) / 100) * 360
                    }deg 360deg),
                      #FDC00D`,
                    borderRadius: "50%",
                    width: `70px`,
                    height: `70px`,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {st?.progress_percentage ? st?.progress_percentage:0}%
                </Box>
              </div>
            </div>
          </Link>
    );
      })}
    </div>
  </div>
   
   
  </div>
  )
}

export default Branch