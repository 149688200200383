import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import DashboardStatistics from '../DashboardStatistics';
import DashboardBondSummary from '../DashboardBondSummary';
import LineBar from '../LineBar';
import { FaAngleLeft } from 'react-icons/fa';

function ProjectAnalytics() {
    const {id} = useParams()
    const navigate = useNavigate();
    const selectState = useSelector((state) => state.sideBar.sidebarClosed);
    const [projects, setProjects] = useState([]);
    const [sites, setSites] = useState([]);
    const [contractor, setContractor] = useState([]);
    const [contractorName, setContractorName] = useState([]);
    const [currentTableState, setCurrentTableState] = useState("STATISTICS");


  useEffect(() => {
    // Fetch data from Django API
    axios
      .get(`https://pms.dalyobt.com/api/view_project/${id}`)
      .then((response) => {
        // Set the data received from the API to the state
        
        setProjects(response.data, "Projects");
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [id]);

  
  
  useEffect(() => {
    axios
      .get("https://pms.dalyobt.com/api/view_site/")
      .then((response) => {
        setSites(response.data, "Sites");
        // console.log(response.data)
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, []);

  useEffect(() => {
    axios
      .get("https://pms.dalyobt.com/api/block_contractor/")
      .then((response) => {
        setContractor(response.data);
        // console.log(response.data, "Contractors")
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, []);

  useEffect(() => {
    axios
      .get("https://pms.dalyobt.com/api/contractor_view/")
      .then((response) => {
        setContractorName(response.data);
        // console.log(response.data, "Contractors")
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, []);

  return (
    <div className="w-[90%] mt-[30px] mx-auto  p-4 rounded-[10px] max-[1100px]:w-[100%]">
    <div className="flex flex-col">
        <div className='flex items-center gap-[10px]'>
        <FaAngleLeft
            className="text-[40px] cursor-pointer text-[#0B72B5]"
            onClick={() => navigate("/")}
          />
                <span className="text-[30px] text-[#0B72B5] font-semibold">Project Analytics</span>
        </div>

      
    </div>
    <div className="w-[100%] mx-auto  mt-[30px] flex justify-between gap-[10px] ">
      <div
        className={
          selectState
            ? " w-[100%] h-[350px] overflow-x-auto bg-white p-4 rounded-[10px] shadow-md flex flex-col items-center  justify-center"
            : "w-[70%] h-[400px] overflow-x-auto bg-white p-4 rounded-[10px] flex  flex-col items-center justify-center max-[1300px]:w-[90%]"
        }
      >
        <div className="my-4 mx-auto flex justify-center font-bold">
          <span className="text-[#FDC00D]">Project Progress</span>
        </div>

        <LineBar projects={projects}/>
      </div>
   

    </div>
    <div className="w-[100%] mt-[50px]  flex justify-center items-center gap-[10px] mx-auto">
      <button
        onClick={() => setCurrentTableState("STATISTICS")}
        className={
          currentTableState === "STATISTICS"
            ? "bg-[#0B72B5] text-white  py-2 px-4  text-[14px] rounded-[5px]"
            : " py-2 px-4 text-black text-[14px] rounded-[5px]"
        }
      >
       STATISTICS
      </button>
      <button
        onClick={() => setCurrentTableState("BONDS_SUMMARY")}
        className={
          currentTableState === "BONDS_SUMMARY"
            ? "bg-[#0B72B5] text-white  py-2 px-4  text-[14px] rounded-[5px]"
            : " py-2 px-4 text-black text-[14px] rounded-[5px]"
        }
      >
        BONDS SUMMARY
      </button>
    </div>
    {/* Stat Earnning, General */}
    <div className="flex gap-[20px] items-start max-[1120px]:flex-col">
    <div className="w-full">
      {currentTableState ==="STATISTICS" && <DashboardStatistics projects={projects} sites={sites} />}
      {currentTableState === "BONDS_SUMMARY" &&  <DashboardBondSummary contractor={contractor} contractorName={contractorName} />}
    </div>

     
  
  
      {/* Stat */}
   
      {/* Earnning */}

    

      {/* <div className='w-[50%] mt-[50px]'>
            <span className='text-[12px] text-gray-500 uppercase'>Bonds Summary</span>
          <div className='mt-[10px] p-4 bg-white rounded-[10px] shadow-xl'>
            <div className='w-[90%] mx-auto  flex justify-between items-center'>
              <span>Performance percentage</span>
              <BsThreeDotsVertical className='cursor-pointer'/>
            </div>

            <div className='w-[90%] mx-auto text-center  mt-[10px] flex justify-between text-[12px] text-gray-500'>
                <span>contractor 1</span>
                <span>contractor 2</span>
                <span>contractor 3</span>
                <span>contractor 4</span>
            </div>
            <div className='w-[90%] mx-auto mt-[10px] flex justify-between'>
              <div className='flex flex-col items-center'>
                <div className='relative w-[40px] h-[200px] flex justify-center items-end   bg-[#ffe79e] rounded-[5px]'>
                <div className='w-[100%] h-[100px] bg-[#FDC00D] rounded-[10px]'></div>
                </div>
                <span className='my-[10px] text-gray-500'>50%</span>
               
              </div>
              <div className='flex flex-col items-center'>
                <div className='relative w-[40px] h-[200px] flex justify-center items-end   bg-[#ffe79e] rounded-[5px]'>
                <div className='w-[100%] h-[150px] bg-[#FDC00D] rounded-[10px]'></div>
                </div>
                <span className='my-[10px] text-gray-500'>85%</span>
              </div>
              <div className='flex flex-col items-center'>
                <div className='relative w-[40px] h-[200px] flex justify-center items-end   bg-[#ffe79e] rounded-[5px]'>
                <div className='w-[100%] h-[70px] bg-[#FDC00D] rounded-[10px]'></div>
                </div>
                <span className='my-[10px] text-gray-500'>25%</span>
               
              </div>
              <div className='flex flex-col items-center'>
                <div className='relative w-[40px] h-[200px] flex justify-center items-end   bg-[#ffe79e] rounded-[5px]'>
                <div className='w-[100%] h-[40px] bg-[#FDC00D] rounded-[10px]'></div>
                </div>
                <span className='my-[10px] text-gray-500'>15%</span>
               
              </div>
            </div>
            </div>
          </div> */}
    </div>
  </div>
  )
}

export default ProjectAnalytics