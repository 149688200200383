import axios from "axios";
import React, { useState } from "react";
import { BsFillCloudUploadFill } from "react-icons/bs";

function ContractorAssignTable({
  currentBlockID,
  currentContractorID,
  currentBlockInfo,
}) {
  const [pbfile, setPBFile] = useState(null);
  const [agfile, setAGFile] = useState(null);
  const [tradeLicense, setTradeLicense] = useState(null);
  const [tradeRegistration, setTradeRegistration] = useState(null);
  const [tinRegistration, setTinRegistration] = useState(null);
  const [vatRegistration, setVatRegistration] = useState(null);
  const [competencyRegistration, setCompetencyRegistration] = useState(null);
  const [pbStartDate, setPBStartDate] = useState(null);
  const [pbEndDate, setPBEndDate] = useState(null);
  const [agStartDate, setAGStartDate] = useState(null);
  const [agEndDate, setAGEndDate] = useState(null);
  const [error, setError] = useState(false);
  // const [currentBlockInfo, setCurrentBlockInfo] = useState([])

  const formData = new FormData();

  formData.append("performance_bond", pbfile);
  formData.append("advance_guarantee", agfile);
  formData.append("trade_license", tradeLicense);
  formData.append("trade_registration", tradeRegistration);
  formData.append("tin_registration", tinRegistration);
  formData.append("vat", vatRegistration);
  formData.append("competency", competencyRegistration);
  formData.append("pb_start_date", pbStartDate);
  formData.append("pb_end_date", pbEndDate);
  formData.append("ag_start_date", agStartDate);
  formData.append("ag_end_date", agEndDate);
  formData.append("remark", null);
  formData.append("block", currentBlockID);
  formData.append("contractor", currentContractorID);

  const handleAssign = async () => {
    await axios
      .post("https://pms.dalyobt.com/api/block_contractor/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        // console.log(response.data);
        window.location.href = "/preconstruction";
      })
      .catch((error) => {
        setError(true);
        console.error(error);
      });
  };

  // console.log(currentBlockInfo?.design_documents, "-----------------------------------------------")

  return (
    <div className="w-[90%] mx-auto mt-[30px] py-2">
      {error && (
        <div className="w-[100%]  text-center p-2 font-bold">
          <span className=" text-[14px] text-center text-red-700">
            Please Check the fields again there might be a missing field.
          </span>
        </div>
      )}
      <div className="w-[100%] mx-auto bg-white p-4  rounded shadow-md">
        <div className="w-[100%] mt-[20px] flex justify-center items-center">
          <span className="text-[#0B72B5] font-bold">Assign Contractor</span>
        </div>

        {currentBlockInfo?.design_documents && <div className="w-[100%] my-4 overflow-y-scroll">
          {typeof currentBlockInfo?.design_documents === "string" && (
            <embed
              src={currentBlockInfo?.design_documents}
              type="application/pdf"
              width="100%"
              height="600px"
            />
          )}
        </div>}

        <div className="flex justify-between my-[20px] gap-[10px]">
          <div className="w-[50%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
            Block Start Date <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="date"
                onChange={(e) => setPBStartDate(e.target.value)}
                placeholder="Ex. 2023-07-08"
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
            {/* {dateError && <span className="text-[14px] text-red-500">Invalid Date</span>} */}
          </div>
          <div className="w-[50%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              block End Date <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="date"
                onChange={(e) => setPBEndDate(e.target.value)}
                placeholder="Ex. 2023-07-08"
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
            {/* {dateError && <span className="text-[14px] text-red-500">Invalid Date</span>} */}
          </div>
        </div>

        <div className="flex justify-between my-[20px] gap-[10px]">
          <div className="w-[50%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              PB Start Date <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="date"
                onChange={(e) => setPBStartDate(e.target.value)}
                placeholder="Ex. 2023-07-08"
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
            {/* {dateError && <span className="text-[14px] text-red-500">Invalid Date</span>} */}
          </div>
          <div className="w-[50%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              PB End Date <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="date"
                onChange={(e) => setPBEndDate(e.target.value)}
                placeholder="Ex. 2023-07-08"
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
            {/* {dateError && <span className="text-[14px] text-red-500">Invalid Date</span>} */}
          </div>
        </div>
        <div className="flex justify-between items-center my-[20px] gap-[10px]">
          <div className="w-[50%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              AG Start Date <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="date"
                onChange={(e) => setAGStartDate(e.target.value)}
                placeholder="Ex. 2023-07-08"
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
            {/* {dateError && <span className="text-[14px] text-red-500">Invalid Date</span>} */}
          </div>
          <div className="w-[50%] my-[20px]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              AG End Date <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="date"
                onChange={(e) => setAGEndDate(e.target.value)}
                placeholder="Ex. 2023-07-08"
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
            {/* {dateError && <span className="text-[14px] text-red-500">Invalid Date</span>} */}
          </div>
        </div>

        <div className="w-[100%] mt-[30px] flex flex-col gap-[10px]">
          <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
            Performance Attachement <span className="text-red-700">*</span>
          </span>
          <label>
            <input
              type="file"
              onChange={(e) => setPBFile(e.target.files[0])}
              hidden
            />
            <div className="[w-[70%] h-[200px] flex justify-center gap-[10px] text-[#0B72B5] items-center p-4 mx-auto border border-gray-300 rounded border-dashed cursor-pointer text-center">
              <BsFillCloudUploadFill />
              <span className="text-[14px] font-bold">
                Upload Performance Attachement
              </span>
            </div>
          </label>
        </div>
        <div className="w-[100%] my-4 overflow-y-scroll">
          {pbfile && (
            <embed
              src={URL.createObjectURL(pbfile)}
              type="application/pdf"
              width="100%"
              height="600px"
            />
          )}
        </div>
        <div className="w-[100%] mt-[30px] flex flex-col gap-[10px]">
          <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
            Advanced Guarantee Attachement{" "}
            <span className="text-red-700">*</span>
          </span>
          <label>
            <input
              type="file"
              onChange={(e) => setAGFile(e.target.files[0])}
              hidden
            />
            <div className="[w-[70%] h-[200px] flex justify-center gap-[10px] text-[#0B72B5] items-center p-4 mx-auto border border-gray-300 rounded border-dashed cursor-pointer text-center">
              <BsFillCloudUploadFill />
              <span className="text-[14px] font-bold">
                Upload Advanced Guarantee Attachement
              </span>
            </div>
          </label>
        </div>
        <div className="w-[100%] my-4 overflow-y-scroll">
          {/* How to view the uploaded file inside this div */}
          {agfile && (
            <embed
              src={URL.createObjectURL(agfile)}
              type="application/pdf"
              width="100%"
              height="600px"
            />
          )}
        </div>
        <div className="w-[100%] mt-[30px] flex flex-col gap-[10px]">
          <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
            Trade License Attachement <span className="text-red-700">*</span>
          </span>
          <label>
            <input
              type="file"
              onChange={(e) => setTradeLicense(e.target.files[0])}
              hidden
            />
            <div className="[w-[70%] h-[200px] flex justify-center gap-[10px] text-[#0B72B5] items-center p-4 mx-auto border border-gray-300 rounded border-dashed cursor-pointer text-center">
              <BsFillCloudUploadFill />
              <span className="text-[14px] font-bold">
                Upload Trade License Attachement
              </span>
            </div>
          </label>
        </div>
        <div className="w-[100%] my-4 overflow-y-scroll">
          {/* How to view the uploaded file inside this div */}
          {tradeLicense && (
            <embed
              src={URL.createObjectURL(tradeLicense)}
              type="application/pdf"
              width="100%"
              height="600px"
            />
          )}
        </div>
        <div className="w-[100%] mt-[30px] flex flex-col gap-[10px]">
          <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
            Trade Registration Attachement{" "}
            <span className="text-red-700">*</span>
          </span>
          <label>
            <input
              type="file"
              onChange={(e) => setTradeRegistration(e.target.files[0])}
              hidden
            />
            <div className="[w-[70%] h-[200px] flex justify-center gap-[10px] text-[#0B72B5] items-center p-4 mx-auto border border-gray-300 rounded border-dashed cursor-pointer text-center">
              <BsFillCloudUploadFill />
              <span className="text-[14px] font-bold">
                Upload Trade Registration Attachement
              </span>
            </div>
          </label>
        </div>
        <div className="w-[100%] my-4 overflow-y-scroll">
          {/* How to view the uploaded file inside this div */}
          {tradeRegistration && (
            <embed
              src={URL.createObjectURL(tradeRegistration)}
              type="application/pdf"
              width="100%"
              height="600px"
            />
          )}
        </div>
        <div className="w-[100%] mt-[30px] flex flex-col gap-[10px]">
          <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
            TIN Registration Attachement <span className="text-red-700">*</span>
          </span>
          <label>
            <input
              type="file"
              onChange={(e) => setTinRegistration(e.target.files[0])}
              hidden
            />
            <div className="[w-[70%] h-[200px] flex justify-center gap-[10px] text-[#0B72B5] items-center p-4 mx-auto border border-gray-300 rounded border-dashed cursor-pointer text-center">
              <BsFillCloudUploadFill />
              <span className="text-[14px] font-bold">
                Upload TIN Registration Attachement
              </span>
            </div>
          </label>
        </div>
        <div className="w-[100%] my-4 overflow-y-scroll">
          {/* How to view the uploaded file inside this div */}
          {tinRegistration && (
            <embed
              src={URL.createObjectURL(tinRegistration)}
              type="application/pdf"
              width="100%"
              height="600px"
            />
          )}
        </div>
        <div className="w-[100%] mt-[30px] flex flex-col gap-[10px]">
          <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
            VAT Attachement <span className="text-red-700">*</span>
          </span>
          <label>
            <input
              type="file"
              onChange={(e) => setVatRegistration(e.target.files[0])}
              hidden
            />
            <div className="[w-[70%] h-[200px] flex justify-center gap-[10px] text-[#0B72B5] items-center p-4 mx-auto border border-gray-300 rounded border-dashed cursor-pointer text-center">
              <BsFillCloudUploadFill />
              <span className="text-[14px] font-bold">
                Upload VAT Attachement
              </span>
            </div>
          </label>
        </div>
        <div className="w-[100%] my-4 overflow-y-scroll">
          {/* How to view the uploaded file inside this div */}
          {vatRegistration && (
            <embed
              src={URL.createObjectURL(vatRegistration)}
              type="application/pdf"
              width="100%"
              height="600px"
            />
          )}
        </div>
        <div className="w-[100%] mt-[30px] flex flex-col gap-[10px]">
          <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
            Competency Attachement <span className="text-red-700">*</span>
          </span>
          <label>
            <input
              type="file"
              onChange={(e) => setCompetencyRegistration(e.target.files[0])}
              hidden
            />
            <div className="[w-[70%] h-[200px] flex justify-center gap-[10px] text-[#0B72B5] items-center p-4 mx-auto border border-gray-300 rounded border-dashed cursor-pointer text-center">
              <BsFillCloudUploadFill />
              <span className="text-[14px] font-bold">
                Upload Competency Attachement
              </span>
            </div>
          </label>
        </div>
        <div className="w-[100%] my-4 overflow-y-scroll">
          {/* How to view the uploaded file inside this div */}
          {competencyRegistration && (
            <embed
              src={URL.createObjectURL(competencyRegistration)}
              type="application/pdf"
              width="100%"
              height="600px"
            />
          )}
        </div>
        <div className="w-[100%] mt-[30px] flex flex-col gap-[10px]">
          <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
            Competency Attachement <span className="text-red-700">*</span>
          </span>
          <label>
            <input
              type="file"
              onChange={(e) => setCompetencyRegistration(e.target.files[0])}
              hidden
            />
            <div className="[w-[70%] h-[200px] flex justify-center gap-[10px] text-[#0B72B5] items-center p-4 mx-auto border border-gray-300 rounded border-dashed cursor-pointer text-center">
              <BsFillCloudUploadFill />
              <span className="text-[14px] font-bold">
                Upload Competency Attachement
              </span>
            </div>
          </label>
        </div>
        <div className="w-[100%] my-4 overflow-y-scroll">
          {/* How to view the uploaded file inside this div */}
          {competencyRegistration && (
            <embed
              src={URL.createObjectURL(competencyRegistration)}
              type="application/pdf"
              width="100%"
              height="600px"
            />
          )}
        </div>

        <div className="w-[100%] mt-[30px] flex justify-end gap-[20px] items-center">
          {error && (
            <div className="w-[100%]  text-center p-2 font-bold">
              <span className=" text-[14px] text-center text-red-700">
                Please Check the fields again there might be a missing field.
              </span>
            </div>
          )}
          <button
            onClick={handleAssign}
            className="bg-[#0B72B5] text-white text-[14px] py-2 px-4 rounded"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

export default ContractorAssignTable;
