import React, { useEffect, useState } from "react";
import BackgroundOverlay from "../BackgroundOverlay";
import axios from "axios";

function ProfileChange({ setProfileChange }) {
  const userID = localStorage.getItem("userID");
  const [profile, setProfile] = useState([]);
  const [profileImg, setProfileImg] = useState(null);
  const [error, setError] = useState(false);
  useEffect(() => {
    axios
      .get("https://pms.dalyobt.com/api/user_profile_pic/")
      .then((response) => {
        setProfile(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const filteredProfile = profile.find(
    (item) => item?.user?.toString() === userID?.toString()
  );
  // console.log(filteredProfile)
  const changeProfileImg = () => {
    const formData = new FormData();
    const pk = userID;
    formData.append("profile_picture", profileImg);
    if (filteredProfile.profile_picture) {
      axios
        .put(`https://pms.dalyobt.com/api/user_profile_pic/${pk}/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          // console.log(res.data);
          window.location.href="/myprofile"
        })
        .catch(() => {
          setError(true);
        });
    }
  };
  return (
    <div>
      <BackgroundOverlay />
      <div className="w-[50%] bg-white p-3 rounded-[10px]  absolute top-[50%] left-[25%]">
        <div className="w-[90%] mx-auto mt-[30px]">
          <span className="font-bold text-[#0B72B5]">
            {" "}
            Update Your Profile Picture
          </span>
        </div>
        {profileImg && <div className="w-[90%] mx-auto flex justify-center items-center my-5">
          <embed
              src={URL.createObjectURL(profileImg)}
              type="application/pdf"
              width={"100%"}
              height="200px"
          />
        </div>}
        <div className="w-[90%] h-[100px] mt-[20px] mx-auto border border-dashed flex justify-center items-center">
          <label>
            <input
              accept=".jpg, .jpeg ,.png, .JPG, .PNG"
              type="file"
              hidden
              onChange={(e) => setProfileImg(e.target.files[0])}
            />
            <span className="bg-[#0B72B5] py-2 px-4 text-[14px] text-white rounded-[5px] cursor-pointer">
              Upload
            </span>
          </label>
        </div>
        <div className="w-[90%] flex justify-end items-center gap-[20px] mb-[10px] mt-[30px]">
          <button
            className="bg-[#0B72B5] py-1 px-4 text-[14px] text-white rounded-[5px]"
            onClick={() => setProfileChange(false)}
          >
            Cancel
          </button>
          <button
            className="bg-[#0B72B5] py-1 px-4 text-[14px] text-white rounded-[5px]"
            onClick={changeProfileImg}
          >
            Change
          </button>
        </div>
      </div>
    </div>
  );
}

export default ProfileChange;
