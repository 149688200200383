import React, { useState } from 'react'
import Sidebar from '../components/Sidebar'
import Content from '../components/Content'


function Home() {
  const [sideBarClose,setSidebarClose] = useState(false)

  
  return (
    <div className='w-[100%] flex '>
      

    <Sidebar sideBarClose={sideBarClose} setSidebarClose={setSidebarClose}/>
    <Content sideBarClose={setSidebarClose} setSidebarClose={setSidebarClose} />

    </div>
  )
}

export default Home