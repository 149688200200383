import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import { projects } from '../../utils/data'
import { useSelector } from "react-redux";
import SiteBar from "../SiteBar";
import { FaAngleLeft } from "react-icons/fa";
import ProjectOverviewTable from "./ProjectOverviewTable";
import axios from "axios";

function ProjectOverview() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [projects, setProjects] = useState([]);
  const [sites, setSites] = useState([]);
  const selectState = useSelector((state) => state.sideBar.sidebarClosed);
  // const [selectedSite,setSelectedSite] = useState("s1")

  useEffect(() => {
    // Fetch data from Django API
    axios
      .get("https://pms.dalyobt.com/api/view_project/")
      .then((response) => {
        // Set the data received from the API to the state
        setProjects(response.data);
        // console.log(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`https://pms.dalyobt.com/api/view_site/${id}`)
      .then((response) => {
        setSites(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [id]);

  const project = projects?.find((pt) => {
    return pt.id.toString() === id.toString();
  });

  // console.log(project, "Project")
  console.log(sites[0]?.id.toString(), "first site");

  const [selectedSite, setSelectedSite] = useState(sites && sites[0]?.id);

  return (
    <div className="w-[90%] mx-auto mt-[50px] flex flex-col">
      <div className="w-[100%] mt-6 mx-auto flex items-center gap-[10px]">
        <FaAngleLeft
          className="text-[30px] cursor-pointer text-[#FDC00D] "
          onClick={() => navigate(-1)}
        />
        <span className="text-[20px] text-[#FDC00D] font-bold">
          {project?.name}
        </span>
      </div>
      <div className="w-[100%] flex gap-[20px] max-[1100px]:flex-col">
        <div
          className={
            selectState
              ? " w-[50%] h-[350px] overflow-x-auto mt-[50px] bg-white p-4 rounded-[10px] shadow-lg flex flex-col items-center  justify-center max-[1100px]:w-[90%] max-[1100px]:mx-auto"
              : "w-[50%] h-[400px] mt-[50px] bg-white p-4 rounded-[10px] flex  flex-col items-center justify-center max-[1100px]:w-[90%] max-[1100px]:mx-auto"
          }
        >
          <div className="my-4 mx-auto flex justify-center font-bold">
            <span className="text-[#FDC00D]">{project?.name} Progress</span>
          </div>

          <SiteBar project={sites} />
        </div>
        <div
          className={
            selectState
              ? " w-[50%] h-[350px] overflow-x-auto mt-[50px] bg-white p-4 rounded-[10px] shadow-lg flex flex-col items-center max-[1100px]:w-[90%] max-[1100px]:mx-auto"
              : "w-[50%] h-[400px] mt-[50px] bg-white p-4 rounded-[10px] flex  flex-col items-center justify-center max-[1100px]:w-[90%] max-[1100px]:mx-auto"
          }
        >
          <div className="my-4 mx-auto flex justify-center font-bold">
            <span className="text-[#FDC00D]">{project?.name} Review</span>
          </div>
          <div className="w-[80%] mt-[30px]  mx-auto flex  items-center justify-around  overflow-x-auto  sm:rounded-l">
            <div className="flex flex-col justify-center gap-[25px] font-bold uppercase text-[14px] text-gray-700">
              <span>Project Name</span>
              <span>Budget</span>
              <span>Progress</span>
              <span>Start Date</span>
              <span>End Date</span>
            </div>
            <div className="flex flex-col gap-[25px] justify-center text-gray-500 text-[14px]">
              <span>{project?.name}</span>
              <span>{project?.budget}</span>
              <span>{project?.progress_percentage}%</span>
              <span>{project?.start_date?.split("T")[0]}</span>
              <span>{project?.end_date.split("T")[0]}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="w-[100%] mt-[50px] rounded-[10px] shadow-lg bg-white py-4">
        <div className="w-[100%] flex justify-center items-center gap-[20px]">
          {sites?.map((site) => {
            return (
              <span
                key={site?.id}
                onClick={() => setSelectedSite(site?.id)}
                className={
                  selectedSite === site?.id
                    ? "bg-[#FDC00D] py-2 px-4 cursor-pointer rounded-[20px] text-white"
                    : "py-2 px-4 cursor-pointer rounded-[20px]"
                }
              >
                {site?.name}
              </span>
            );
          })}
        </div>
        <div className="mt-[50px]">
          {project && (
            <ProjectOverviewTable project={sites} selectedSite={selectedSite} />
          )}
        </div>
      </div>
    </div>
  );
}

export default ProjectOverview;
