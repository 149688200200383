import React, { useEffect, useState } from "react";
import { FaAngleLeft } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import FinalHandoverTable from "./FinalHandoverTable";
import axios from "axios";

function FinalHandover() {
  const navigate = useNavigate();
  const { id } = useParams();
  const userGroup = localStorage.getItem("userGroup");
  const [finalList, setFinalList] = useState([]);

  useEffect(() => {
    axios.get(`https://pms.dalyobt.com/api/temp_hand_over/${id}/`)
      .then((response) => {
        setFinalList(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [id]);

  // console.log(finalList[0]?.status===false, "----------------------------------------------------------------------")

  return (
    <div className="mt-[50px]">
      <div className="w-[90%] mt-6 mx-auto flex justify-between">
        <div className="flex justify-start items-center gap-[20px]">
          <FaAngleLeft
            className="text-[30px] cursor-pointer text-[#0B72B5]"
            onClick={() => navigate(-1)}
          />
          <span className="text-[20px] font-bold text-[#0B72B5]">
            Final handover
          </span>
        </div>
        {userGroup?.toString() === "1" && finalList[0]?.status===false &&(
          <button
            onClick={() =>
              navigate(`/postconstruction/finalhandover/addfhandover/${id}`)
            }
            className="bg-[#0B72B5] py-2 px-4 text-white text-[14px] rounded-[10px]"
          >
            Add Final Handover
          </button>
        )}
      </div>
      {/* <div className="w-[100%] mt-[50px] flex justify-center">
      <input
        type="text"
        value={search}
        placeholder="Search by project name"
        onChange={(e) => setSearch(e.target.value)}
        className="bg-white border border-gray-400 p-3 w-[40%] rounded-[5px] text-[14px] outline-none text-gray-500 max-[1000px]:w-[80%]"
      />
    </div> */}

      <div className="bg-white w-[95%] mx-auto p-4 rounded-[10px] mt-[50px] border border-gray-300 max-[1000px]:w-[95%]">
        <div className="mt-[30px]">
          <FinalHandoverTable />
        </div>
      </div>
    </div>
  );
}

export default FinalHandover;
