import React, { useState } from "react";
import FinancialPost from "./FinancialPost";
import FinancialGet from "./FinancialGet";

function FinancialReport() {

  const [fillCheck, setFillCheck] = useState(false);
  const [getCheck, setGetCheck] = useState(true);

  const handleInit = ()=>{
    setGetCheck(true)
    setFillCheck(false)
  }

  const handleCurrent = ()=>{
    setGetCheck(false)
    setFillCheck(true)
  }

  return (
     <div className="w-full relative">
      <div className="w-[50%] mx-auto flex justify-between my-5 mb-10 items-center max-[1000px]:w-[80%]">
        <button
          onClick={handleInit}
          className="bg-[#ffffff] hover:bg-[#0B72B5] focus:bg-[#0B72B5] transition duration-500 hover:text-white focus:text-white shadow-2xl p-2 text-[#0B72B5] rounded-md font-medium outline-none"
          autoFocus
        >
        List of Financial Report
        </button>
        <button
          onClick={handleCurrent}
          className="bg-[#ffffff] hover:bg-[#0B72B5] focus:bg-[#0B72B5] transition duration-500 hover:text-white focus:text-white shadow-2xl p-2 text-[#0B72B5] rounded-md font-medium outline-none"
        >
        Add New Financial Report
        </button>
      </div>

      <div className="w-full">
        {getCheck && <FinancialGet />}
        {fillCheck && <FinancialPost />}
      </div>
    </div>
  );
}

export default FinancialReport;
