import React, { useEffect, useRef, useState } from 'react'
// import { material } from '../../../utils/data'
import axios from 'axios'
function BOQ({setBOQ}) {

    // const [selectedName,setSelectedName] = useState("")
    const [boqList,setBoQList]= useState([])
    // const [selectedCatagory,setSelectedCatagory] = useState("")
    // const [quality,setQuality] = useState([])
    // const [itemList,setItemList] = useState([])
    const [itemNo,setItemNo] = useState("")
    // const [itemName,setItemName] = useState("")
    const [quantity,setQuanity] = useState("")
    const [quantityUnit,setQuantityUnit] = useState("")
    const [category,setCategory] = useState("")
    const [workType,setWorkType] = useState("")
    const [unitPrice,setUnitPrice] = useState("");
    const [description,setDescription] = useState("")
    const [remark,setRemark] = useState("")
    
const ref = useRef(null)
    const AddBOQ =async (e)=>{
        e.preventDefault();
    
        const boqFields={
            
            Item_no:itemNo,
            description:description,
            quantity_unit:quantityUnit,
            quantity:quantity,
            unit_price:unitPrice,
            category:category,
            work_type:workType,
            remark:remark};
           

            

        await axios.post('https://pms.dalyobt.com/api/BOQ_Itemmodels/',boqFields);
    //   ref.current.value="";
    setBOQ(false)
      setItemNo(null)
      setDescription(null)
      

        

    }
  
    useEffect(()=>{
        const getBOQ = async ()=>{
            await axios.get('https://pms.dalyobt.com/api/BOQ_Itemmodels/').then((res)=>{
               setBoQList(res.data)
             
              })
              console.log(boqList)
              
        }
        getBOQ()
        
    },[])

   

  return (
    <div className=' w-[80%]  my-[20px]  flex flex-col justify-center py-4 top-[10%] bg-white shadow-xl absolute left-[10%]  rounded-[10px]'>
    <div className='w-[90%] mx-auto my-[30px] flex items-center gap-[30px]'>
        {/* <FaAngleLeft className='text-[30px] cursor-pointer text-[#0B72B5]' onClick={()=>navigate('/projects/'+projectID)} /> */}
        <span className='text-[20px] text-[#0B72B5] font-bold'>Add BOQ</span>
        
    </div>
    <div className='w-[80%] mt-[50px] mx-auto flex flex-col gap-[20px]'>
    <div className='w-[40%]  flex flex-col items-start gap-[20px]'>
            <span className='text-[14px] font-bold w-[30%] text-[#0B72B5]'>Item No.</span>
            <div className='w-[100%] rounded-[5px]'>
            <input type='text' placeholder='Item No' ref={ref} required onChange={(e)=>setItemNo(e.target.value)}  className='w-[100%] border border-[#ced4da] text-gray-500 py-3 px-4 rounded-[5px] text-[14px] outline-none'/>
            </div>
         
        </div>
        <div className='flex justify-between items-center'>
        {/* <div className='w-[40%]  flex flex-col  items-start gap-[20px]'>
            <span className='text-[14px] font-bold w-[30%] text-[#0B72B5]'>Item Name</span>
            <div className='w-[100%] rounded-[5px]'>

                <div className='w-[100%] border border-[#ced4da] py-3 px-4 rounded-[5px] text-[14px] ' >
                <select onChange={(e)=>setItemName(e.target.value)}  className='w-[100%] outline-none text-gray-500'>
                
                <option value="">select an item</option>
                    {material.map((mt)=>{
                        return <option  value={mt.name} key={mt.id}>{mt.name}</option>
                    })} 
                </select>
                </div>

           
            </div>
         
        </div> */}
        <div className='w-[40%]  flex flex-col  items-start gap-[20px]'>
            <span className='text-[14px] font-bold w-[30%] text-[#0B72B5]'>Catagory</span>
            <div className='w-[100%] border border-[#ced4da] py-3 px-4 rounded-[5px] text-[14px] ' >
                <select onChange={(e)=>setCategory(e.target.value)}  className='w-[100%] outline-none text-gray-500'>
                
                <option value="">select an item</option>
             
                         <option   value={"general"}>General</option>
                         <option   value={"SUB-STRUCTURE"}>Sub-Structure</option>
                         <option   value={"SUPER-STRUCTURE"}>Super-Structure</option>
                    
                </select>
                </div>
         
        </div>
        </div>
        
       
        <div className='flex justify-between my-[20px]'>

    
        <div className='w-[40%]  flex flex-col items-start gap-[20px]'>
            <span className='text-[14px] font-bold w-[30%] text-[#0B72B5]'>Quantity</span>
            <div className='w-[100%] rounded-[5px]'>
            <input type='text' placeholder='Quantity' ref={ref} onChange={(e)=>setQuanity(e.target.value)} required  className='w-[100%] border border-[#ced4da] text-gray-500  py-3 px-4 rounded-[5px] text-[14px] outline-none'/>
            </div>
         
        </div>
       
        <div className='w-[40%]  flex flex-col items-start gap-[20px]'>
            <span className='text-[14px] font-bold w-[30%] text-[#0B72B5]'>Quantity Unit</span>
            <div  className='w-[100%] border border-[#ced4da] py-3 px-4 rounded-[5px] text-[14px]'>
           
            <select onChange={(e)=>setQuantityUnit(e.target.value)} className='w-[100%] outline-none text-gray-500'>
                
                <option value="">select an item</option>
             
                         <option   value={"m"}>Meter</option>
                         <option   value={"m2"}>Square Meter</option>
                         <option   value={"m3"}>Meter_Cube</option>
                         <option   value={"KG"}>Kilogram</option>
                         <option   value={"Piece"}>Piece</option>
                         <option   value={"Lampsum"}>Lampsum</option>
                         <option   value={"Set"}>set</option>

                    
                </select>
            {/* <input type='text' placeholder='Quantity Unit' required onChange={(e)=>setQuantityUnit(e.target.value)}  className='w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none'/>     */}
            {/* {quality.map((qu,index)=>{
                    return  <input key={index} type='text' placeholder={qu} required   className='w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none'/>
                })}
            */}
            </div>
         
        </div>
            

        </div>
        <div className='flex justify-between my-[20px]'>
        <div className='w-[40%]  flex flex-col items-start gap-[20px]'>
            <span className='text-[14px] font-bold w-[30%] text-[#0B72B5]'>Work Type</span>
            <div className='w-[100%] rounded-[5px]'>
            <input type='text' placeholder='Work Type' required ref={ref} onChange={(e)=>setWorkType(e.target.value)} className='w-[100%] border border-[#ced4da] text-gray-500 py-3 px-4 rounded-[5px] text-[14px] outline-none'/>
            </div>
         
        </div>
        <div className='w-[40%]  flex flex-col items-start gap-[20px]'>
            <span className='text-[14px] font-bold w-[30%] text-[#0B72B5]'>Remark</span>
            <div className='w-[100%] rounded-[5px]'>
            <input type='text' placeholder='Remark' required ref={ref} onChange={(e)=>setRemark(e.target.value)} className='w-[100%] border border-[#ced4da] text-gray-500  py-3 px-4 rounded-[5px] text-[14px] outline-none'/>
            </div>
         
        </div>
        </div>
         <div className='flex justify-between my-[20px]'>
        <div className='w-[40%]  flex flex-col items-start gap-[20px]'>
            <span className='text-[14px] font-bold w-[30%] text-[#0B72B5]'>Unit Price</span>
            <div className='w-[100%] rounded-[5px]'>
            <input type='text' placeholder='Work Type'  required ref={ref} onChange={(e)=>setUnitPrice(e.target.value)} className='w-[100%] border border-[#ced4da] text-gray-500  py-3 px-4 rounded-[5px] text-[14px] outline-none'/>
            </div>
         
        </div>
        
        </div>

     
<div className='w-[100%]  flex flex-col items-start gap-[20px]'>
    <span className='text-[14px] font-bold w-[30%] text-[#0B72B5]'>Description</span>
    <div className='w-[100%] rounded-[5px]'>
    
    <textarea rows={10} required ref={ref} onChange={(e)=>setDescription(e.target.value)} className='w-[100%] border border-[#ced4da] text-gray-500   resize-none py-3 px-4 rounded-[5px] text-[14px] outline-none'>

    </textarea>
    </div>
 
</div>
     
        

    </div>
   
    <div className='w-[80%] mt-[30px] flex justify-end items-center gap-[20px]'>
        <button onClick={()=>setBOQ(false)}>Cancel</button>
        <button className='py-3 px-4 bg-[#0B72B5] text-white rounded-[10px]' onClick={AddBOQ} >Add BOQ</button>
    </div>
</div>
  )
}

export default BOQ