import { BiHomeAlt2 } from "react-icons/bi";
// import { VscProject } from "react-icons/vsc";
import { AiOutlineRight } from "react-icons/ai";
import {MdOutlineConstruction} from 'react-icons/md'
// import {SiHomeadvisor} from 'react-icons/si'
import {FaClipboard, FaCodeBranch, FaHardHat}from 'react-icons/fa'
import {LuConstruction} from 'react-icons/lu'


const userGroup = localStorage.getItem('userGroup')


export const branch = [
  {
    id:"1",
    branch:"branch - 1",
    financial:"50",
    physical:"20",
    projects:4
  },
  {
    id:"2",
    branch:"branch - 2",
    financial:"60",
    physical:"40",
    projects:2
  },
  {
    id:"3",
    branch:"branch - 3",
    financial:"70",
    physical:"50",
    projects:5
  },
  {
    id:"4",
    branch:"branch - 4",
    financial:"90",
    physical:"80",
    projects:1
  },
  {
    id:"5",
    branch:"branch - 5",
    financial:"50",
    physical:"30",
    projects:8
  },
]

export const notification = [
  {
    id:"1",
    notification_content:"BOQ has been Updated",
    date:'7/27/2023',
    user:"user-1",
    type:"BOQ Update",
    block:"block-5"

  },
  {
    id:"2",
    notification_content:"BOQ Amendment has been requested",
    date:'7/27/2023',
    user:"user-2",
    type:"BOQ Amendment",
    block:"block-32"

  },
  {
    id:"3",
    notification_content:"BOQ Checklist has been updated",
    date:'7/27/2023',
    user:"user-3",
    type:"BOQ Checklist",
    block:"block-332"

  }

]

export const sidebarLinks = [
  {
    id: "1",
    name: "Dashboard",
    path:'',
    icon: <BiHomeAlt2 />,
    right: <AiOutlineRight />,
    bg: null,
  },
  {
    id: "2",
    name: "Branch",
    path:"branchlist",
    icon: <FaCodeBranch  />,
    right: <AiOutlineRight />,
    bg: null,
  },
  // {
  //   id: "3",
  //   name: "Projects",
  //   path:"Projects",
  //   icon: <VscProject />,
  //   right: <AiOutlineRight />,
  //   bg: null,
  // },

  {
    id: "3",
    name: "Pre Construction",
    path:"preconstruction",
    icon: <MdOutlineConstruction />,
    right: <AiOutlineRight />,
    bg: null,
  },
  {
    id: "4",
    name: "Post Construction",
    path:"postconstruction",
    icon: <LuConstruction />,
    right: <AiOutlineRight />,
    bg: null,
  },
 

  (userGroup?.toString()==="1" || userGroup?.toString()==="5")? {
    id: "5",
    name: "Staff",
    path:"staff",
    icon: <FaClipboard />,
    right: <AiOutlineRight />,
    bg: null,
  }: '',

  {
    id: "6",
    name: "Contractor",
    path:"contractor",
    icon: <FaHardHat />,
    right: <AiOutlineRight />,
    bg: null,
  },

];

export const stat = [
  {
    id: "1",
    project_id:"p1",
    name: "Project-1",
    buildings: "4 Buildings",
    progress: 0.5
  },
  {
    id: "2",
    project_id:"p2",
    name: "Project-2",
    buildings: "3 Buildings",
    progress: 0.4
  },
  {
    id: "3",
    project_id:"p3",
    name: "Project-3",
    buildings: "3 Buildings",
    progress: 0.3
  },
  {
    id: "4",
    name: "Project-4",
    project_id:"p4",
    buildings: "4 buildings",
    progress: 0.2,
  },
];
// line Data

export const lineData = [
  {
    name: "Project 1",
    financial: 40,
    physical: 24,
  },
  {
    name: "Project 2",
    financial: 30,
    physical: 13,
  },
  {
    name: "Project 3",
    financial: 20,
    physical: 58,
  },
];
export const projects = [
  {
    project_id: "p1",
    projectname: "Project-1",
    budget: "-",
    progress: "50",
    startDate: "6/6/2023",
    endDate: "7/6/2023",
    financial: 70,
    physical: 24,

    sites: [
      {
        site_id: "s1",
        sitename: "site-1",
        startdate: "6/6/2023",
        enddate: "6/15/2023",
        status: "-",
        siteProgress: "30%",
        area: "-",
        address: "-",
        financial: 70,
        physical: 24,
        location:"-",
        no_houses:"-",
        no_blocks:"-",
        infrastructure:[{
          id:"I1",
          name:"Road"
        },
        {
          id:"I2",
          name:"Sewage Line"
        }
      ],
        buildings: [
          {
            id: "b1",
            type: "G+3",
            useage: "20/80",
            contractors: "-",
            price: "-",
            items: [],
            infrastructure:[{
              id:"I1",
              name:"Road"
            },
            {
              id:"I2",
              name:"Sewage Line"
            }
          ]
          },
          {
            id: "b2",
            type: "G+7",
            useage: "20/80",
            contractors: "-",
            price: "-",
            items: [],
            infrastructure:[{
              id:"I1",
              name:"Road"
            },
            {
              id:"I2",
              name:"Sewage Line"
            }
          ]
          },
        ],
      },
      {
        site_id: "s2",
        sitename: "site-2",
        startdate: "6/6/2023",
        enddate: "6/15/2023",
        status: "-",
        siteProgress: "80%",
        area: "-",
        address: "-",
        financial: 50,
        physical: 20,
        location:"-",
        no_houses:"-",
        no_blocks:"-",
        infrastructure:[{
          id:"I1",
          name:"Road"
        },
        {
          id:"I2",
          name:"Sewage Line"
        }
      ],
        buildings: [
          {
            id: "b1",
            type: "G+7",
            useage: "20/80",

            contractors: "-",
            price: "-",
            items: [],
            infrastructure:[{
              id:"I1",
              name:"Road"
            },
            {
              id:"I2",
              name:"Sewage Line"
            }
          ]
          },
          {
            id: "b2",
            type: "G+5",
            useage: "20/80",
            contractors: "-",
            price: "-",
            items: [],
            infrastructure:[{
              id:"I1",
              name:"Road"
            },
            {
              id:"I2",
              name:"Sewage Line"
            }
          ]
          },
          {
            id: "b3",
            type: "G+5",
            useage: "40/80",
            contractors: "-",
            price: "-",
            items: [],
            infrastructure:[{
              id:"I1",
              name:"Road"
            },
            {
              id:"I2",
              name:"Sewage Line"
            }
          ]
          },
        ],
      },
    ],
  },
  {
    project_id: "p2",
    projectname: "Project-2",
    budget: "-",
    progress: "40",
    startDate: "6/6/2023",
    endDate: "7/6/2023",
    financial: 30,
    physical: 13,
    sites: [
      {
        site_id: "s1",
        sitename: "site-1",
        startdate: "6/6/2023",
        enddate: "6/15/2023",
        status: "-",
        siteProgress: "35%",
        area: "-",
        address: "-",
        financial: 70,
        physical: 24,
        location:"-",
        no_houses:"-",
        no_blocks:"-",
        infrastructure:[{
          id:"I1",
          name:"Road"
        },
        {
          id:"I2",
          name:"Sewage Line"
        }
      ],
        buildings: [
          {
            id: "b1",
            type: "G+3",
            useage: "20/80",
            contractors: "-",
            price: "-",
            items: [],
            infrastructure:[{
              id:"I1",
              name:"Road"
            },
            {
              id:"I2",
              name:"Sewage Line"
            }
          ]
          },
          {
            id: "b2",
            type: "G+4",
            useage: "20/80",
            contractors: "-",
            price: "-",
            items: [],
            infrastructure:[{
              id:"I1",
              name:"Road"
            },
            {
              id:"I2",
              name:"Sewage Line"
            }
          ]
          },
          {
            id: "b3",
            type: "G+5",
            useage: "40/60",
            contractors: "-",
            price: "-",
            items: [],
            infrastructure:[{
              id:"I1",
              name:"Road"
            },
            {
              id:"I2",
              name:"Sewage Line"
            }
          ]
          },
        ],
      },
      {
        site_id: "s2",
        sitename: "site-2",
        startdate: "6/6/2023",
        enddate: "6/15/2023",
        status: "-",
        siteProgress: "45%",
        area: "-",
        address: "-",
        financial: 90,
        physical: 60,
        location:"-",
        no_houses:"-",
        no_blocks:"-",
        infrastructure:[{
          id:"I1",
          name:"Road"
        },
        {
          id:"I2",
          name:"Sewage Line"
        }
      ],
        buildings: [
          {
            id: "b1",
            type: "G+3",
            useage: "20/80",
            contractors: "-",
            price: "-",
            items: [],
            infrastructure:[{
              id:"I1",
              name:"Road"
            },
            {
              id:"I2",
              name:"Sewage Line"
            }
          ]
          },
          {
            id: "b2",
            type: "G+7",
            useage: "20/80",
            contractors: "-",
            price: "-",
            items: [],
            infrastructure:[{
              id:"I1",
              name:"Road"
            },
            {
              id:"I2",
              name:"Sewage Line"
            }
          ]
          },
          {
            id: "b3",
            type: "G+5",
            useage: "40/80",

            contractors: "-",
            price: "-",
            items: [],
            infrastructure:[{
              id:"I1",
              name:"Road"
            },
            {
              id:"I2",
              name:"Sewage Line"
            }
          ]
          },
        ],
      },
      {
        site_id: "s3",
        sitename: "site-3",
        startdate: "6/6/2023",
        enddate: "6/15/2023",
        status: "-",
        siteProgress: "78%",
        area: "-",
        address: "-",
        financial: 60,
        physical: 40,
        location:"-",
        no_houses:"-",
        no_blocks:"-",
        infrastructure:[{
          id:"I1",
          name:"Road"
        },
        {
          id:"I2",
          name:"Sewage Line"
        }
      ],
        buildings: [
          {
            id: "b1",
            type: "G+3",
            useage: "20/80",

            contractors: "-",
            price: "-",
            items: [],
            infrastructure:[{
              id:"I1",
              name:"Road"
            },
            {
              id:"I2",
              name:"Sewage Line"
            }
          ]
          },
          {
            id: "b2",
            type: "G+4",
            useage: "20/80",

            contractors: "-",
            price: "-",
            items: [],
            infrastructure:[{
              id:"I1",
              name:"Road"
            },
            {
              id:"I2",
              name:"Sewage Line"
            }
          ]
          },
          {
            id: "b3",
            type: "G+5",
            useage: "40/80",

            contractors: "-",
            price: "-",
            items: [],
            infrastructure:[{
              id:"I1",
              name:"Road"
            },
            {
              id:"I2",
              name:"Sewage Line"
            }
          ]
          },
        ],
      },
    ],
  },
  {
    project_id: "p3",
    projectname: "Project-3",
    budget: "-",
    progress: "30",
    startDate: "6/6/2023",
    endDate: "7/6/2023",
    financial: 50,
    physical: 30,
    sites: [
      {
        site_id: "s1",
        sitename: "site-1",
        startdate: "6/6/2023",
        enddate: "6/15/2023",
        status: "-",
        siteProgress: "77%",
        area: "-",
        address: "-",
        financial: 70,
        physical: 30,
        location:"-",
        no_houses:"-",
        no_blocks:"-",
        infrastructure:[{
          id:"I1",
          name:"Road"
        },
        {
          id:"I2",
          name:"Sewage Line"
        }
      ],
        buildings: [
          {
            id: "b1",
            type: "G+3",
            useage: "20/80",
            contractors: "-",
            price: "-",
            items: [],
            infrastructure:[{
              id:"I1",
              name:"Road"
            },
            {
              id:"I2",
              name:"Sewage Line"
            }
          ]
          },
          {
            id: "b2",
            type: "G+4",
            useage: "20/80",
            contractors: "-",
            price: "-",
            items: [
              {
                id: "1",
                name: "window",
                item_type: "window",
                units: "",
              },
            ],
            infrastructure:[{
              id:"I1",
              name:"Road"
            },
            {
              id:"I2",
              name:"Sewage Line"
            }
          ]
          },
          {
            id: "b3",
            type: "G+5",
            useage: "40/80",
            contractors: "-",
            price: "-",
            items: [],
            infrastructure:[{
              id:"I1",
              name:"Road"
            },
            {
              id:"I2",
              name:"Sewage Line"
            }
          ]
          },
        ],
      },
      {
        site_id: "s2",
        sitename: "site-2",
        startdate: "6/6/2023",
        enddate: "6/15/2023",
        status: "-",
        siteProgress: "22%",
        area: "-",
        address: "-",
        financial: 70,
        physical: 24,
        location:"-",
        no_houses:"-",
        no_blocks:"-",
        infrastructure:[{
          id:"I1",
          name:"Road"
        },
        {
          id:"I2",
          name:"Sewage Line"
        }
      ],
        buildings: [
          {
            id: "b1",
            type: "G+3",
            useage: "20/80",
            contractors: "-",
            price: "-",
            items: [],
            infrastructure:[{
              id:"I1",
              name:"Road"
            },
            {
              id:"I2",
              name:"Sewage Line"
            }
          ]
          },
          {
            id: "b2",
            type: "G+4",
            useage: "20/80",
            contractors: "-",
            price: "-",
            items: [],
            infrastructure:[{
              id:"I1",
              name:"Road"
            },
            {
              id:"I2",
              name:"Sewage Line"
            }
          ]
          },
          {
            id: "b3",
            type: "G+5",
            useage: "40/80",
            contractors: "-",
            price: "-",
            items: [],
            infrastructure:[{
              id:"I1",
              name:"Road"
            },
            {
              id:"I2",
              name:"Sewage Line"
            }
          ]
          },
        ],
      },
    ],
  },
  {
    project_id: "p4",
    projectname: "Project-4",
    budget: "-",
    progress: "30",
    startDate: "7/7/2023",
    endDate: "8/6/2023",
    financial: 40,
    physical: 20,

    sites: [
      {
        site_id: "s1",
        sitename: "site-1",
        startdate: "6/6/2023",
        enddate: "6/15/2023",
        status: "-",
        siteProgress: "67%",
        area: "-",
        address: "-",
        financial: 50,
        physical: 30,
        location:"-",
        no_houses:"-",
        no_blocks:"-",
        infrastructure:[{
          id:"I1",
          name:"Road"
        },
        {
          id:"I2",
          name:"Sewage Line"
        }
      ],
        buildings: [
          {
            id: "b1",
            type: "G+3",
            useage: "20/80",
            contractors: "-",
            price: "-",
            items: [],
            infrastructure:[{
              id:"I1",
              name:"Road"
            },
            {
              id:"I2",
              name:"Sewage Line"
            }
          ]
          },
          {
            id: "b2",
            type: "G+4",
            useage: "20/80",
            contractors: "-",
            price: "-",
            items: [
              {
                id: "1",
                name: "window",
                item_type: "window",
                units: "",
              },
            ],
            infrastructure:[{
              id:"I1",
              name:"Road"
            },
            {
              id:"I2",
              name:"Sewage Line"
            }
          ]
          },
          {
            id: "b3",
            type: "G+5",
            useage: "40/80",

            contractors: "-",
            price: "-",
            items: [],
            infrastructure:[{
              id:"I1",
              name:"Road"
            },
            {
              id:"I2",
              name:"Sewage Line"
            }
          ]
          },
        ],
      },
      {
        site_id: "s2",
        sitename: "site-2",
        startdate: "6/6/2023",
        enddate: "6/15/2023",
        status: "-",
        siteProgress: "82%",
        area: "-",
        address: "-",
        financial: 90,
        physical: 74,
        location:"-",
        no_houses:"-",
        no_blocks:"-",
        infrastructure:[{
          id:"I1",
          name:"Road"
        },
        {
          id:"I2",
          name:"Sewage Line"
        }
      ],
        buildings: [
          {
            id: "b1",
            type: "G+3",
            useage: "20/80",
            contractors: "-",
            price: "-",
            items: [],
            infrastructure:[{
              id:"I1",
              name:"Road"
            },
            {
              id:"I2",
              name:"Sewage Line"
            }
          ]
          },
          {
            id: "b2",
            type: "G+4",
            useage: "40/60",
            contractors: "-",
            price: "-",
            items: [],
            infrastructure:[{
              id:"I1",
              name:"Road"
            },
            {
              id:"I2",
              name:"Sewage Line"
            }
          ]
          },
          {
            id: "b3",
            type: "G+5",
            useage: "40/80",
            contractors: "-",
            price: "-",
            items: [],
            infrastructure:[{
              id:"I1",
              name:"Road"
            },
            {
              id:"I2",
              name:"Sewage Line"
            }
          ]
          },
        ],
      },
    ],
  },
];

export const material = [
  {
    id: "1",
    name: "door",
    category:'category-1',
    quantity: "30",
    quality: ["height", "width", "length"],
  },
  {
    id: "2",
    name: "window",
    category:'category-2',
    quantity: "30",
    quality: ["height", "width"],
  },
];

export const checkListContent=[
  { No: 1, Activities: 'MOBILIZATION', 'ID No.': 'BT/CWICH/001' },
  { No: 2, Activities: 'SITE CLEARANCE', 'ID No.': 'BT/CWICH/002' },
  { No: 3, Activities: 'BULK EXCAVATION', 'ID No.': 'BT/CWICH/003' },
  { No: 4, Activities: 'PIT EXCAVATION', 'ID No.': 'BT/CWICH/004' },
  { No: 5, Activities: 'TRENCH EXCAVATION', 'ID No.': 'BT/CWICH/005' },
  { No: 6, Activities: 'BACKFILLING', 'ID No.': 'BT/CWICH/006' },
  { No: 7, Activities: 'HARD CORING', 'ID No.': 'BT/CWICH/007' },
  {
    No: 8,
    Activities: 'STONE MASONORY FOUNDATION',
    'ID No.': 'BT/CWICH/008'
  },
  { No: 9, Activities: 'FORMWORK FOOTING', 'ID No.': 'BT/CWICH/009' },
  {
    No: 10,
    Activities: 'PLACING OF REINFORCEMENT ',
    'ID No.': 'BT/CWICH/010'
  },
  { No: NaN, Activities: 'FOOTING, COLOMN, GRADE BEAM', 'ID No.': '' },
  {
    No: 11,
    Activities: 'PLACING OF CONCRETE FOOTING',
    'ID No.': 'BT/CWICH/011'
  },
  { No: 12, Activities: 'FORMWORK: ', 'ID No.': 'BT/CWICH/012' },
  {
    No: NaN,
    Activities: 'COLUMN/GRADE BEAM/SUSPENDED SLAB',
    'ID No.': ''
  },
  {
    No: 13,
    Activities: 'PLACING OF CONCRETE COLUMN GRADE BEAM',
    'ID No.': 'BT/CWICH/013'
  },
  {
    No: 14,
    Activities: 'PLACING OF REINFORCEMENT SUSPENDED SLAB/STAIR CASE',
    'ID No.': 'BT/CWICH/014'
  },
  {
    No: 15,
    Activities: 'POSITIONING OF CONDUIT AND JUNCTION BOX',
    'ID No.': 'BT/CWICH/015'
  },
  {
    No: 16,
    Activities: 'PLACING OF SUSPENDED SLAB OR STAIRCASE',
    'ID No.': 'BT/CWICH/016'
  },
  {
    No: 17,
    Activities: 'CARPENTARY & JOINERY INSPECTION TRUSS',
    'ID No.': 'BT/CWICH/017'
  },
  {
    No: 18,
    Activities: 'CARPENTARY & JOINERY INSPECTION PURLINE',
    'ID No.': 'BT/CWICH/018'
  },
  {
    No: 19,
    Activities: 'ROOFING AND WALL CLADDING',
    'ID No.': 'BT/CWICH/019'
  },
  {
    No: 20,
    Activities: 'STRUCTURAL STEEL WORKS',
    'ID No.': 'BT/CWICH/020'
  },
  {
    No: 21,
    Activities: 'SUPERSTRUCTURE MASONRY WORKS',
    'ID No.': 'BT/CWICH/021'
  },
  { No: 22, Activities: 'PLASTERING WORKS', 'ID No.': 'BT/CWICH/022' },
  {
    No: 23,
    Activities: 'ONE COAT PLASTERING',
    'ID No.': 'BT/CWICH/023'
  },
  { No: 24, Activities: 'RENDERING ', 'ID No.': 'BT/CWICH/024' },
  {
    No: 25,
    Activities: 'JOINERY INSPECTION, WOODEN DOORS',
    'ID No.': 'BT/CWICH/025'
  },
  {
    No: 26,
    Activities: 'METAL WORK FABRICATION & FIXING',
    'ID No.': 'BT/CWICH/026'
  },
  {
    No: 27,
    Activities: 'PULLING OF WIRES AND RELATED ACCESSORIES',
    'ID No.': 'BT/CWICH/027'
  },
  { No: 28, Activities: 'CEILING WORK', 'ID No.': 'BT/CWICH/028' },
  {
    No: 29,
    Activities: 'INDOOR USE G.I. PIPE',
    'ID No.': 'BT/CWICH/029'
  },
  {
    No: 30,
    Activities: 'INDOOR AND OUT DOOR uPVC PIPES',
    'ID No.': 'BT/CWICH/030'
  },
  {
    No: 31,
    Activities: 'CEMENT SCREED FLOOR INSPECTION',
    'ID No.': 'BT/CWICH/031'
  },
  {
    No: 32,
    Activities: 'WALL TILE COVERAGE INSPECTION WORK',
    'ID No.': 'BT/CWICH/032'
  },
  {
    No: 33,
    Activities: 'SOLID FLOOR TILE COVERAGE INSPECTION WORK',
    'ID No.': 'BT/CWICH/033'
  },
  {
    No: 34,
    Activities: 'PLASTIC/PVC TILE FLOORING ',
    'ID No.': 'BT/CWICH/034'
  },
  {
    No: 35,
    Activities: 'MARBLE FLOORING WORK INSPECTION',
    'ID No.': 'BT/CWICH/035'
  },
  {
    No: 36,
    Activities: 'PARQUET FLOORING WORK INSPECTION',
    'ID No.': 'BT/CWICH/036'
  },
  {
    No: 37,
    Activities: 'INSPECTION FOR FIXING OF FLOOR, WALL MOSAIC',
    'ID No.': 'BT/CWICH/037'
  },
  {
    No: 38,
    Activities: 'STEEL STAIRCASE COMPONENTS (METAL WORK INSPECTION)',
    'ID No.': 'BT/CWICH/038'
  },
  {
    No: 39,
    Activities: 'STAIR CASE FINISHING',
    'ID No.': 'BT/CWICH/039'
  },
  {
    No: 40,
    Activities: 'STEEL STAIRCASE COMPONENTS',
    'ID No.': 'BT/CWICH/040'
  },
  { No: 41, Activities: 'PAINTING', 'ID No.': 'BT/CWICH/041' },
  { No: 42, Activities: 'GLAZING WORK', 'ID No.': 'BT/CWICH/042' },
  {
    No: 43,
    Activities: 'DAMP/WATER PROOFING WORK',
    'ID No.': 'BT/CWICH/043'
  },
  {
    No: 44,
    Activities: 'FIXING OF BOARDS/BELL CONTROL',
    'ID No.': 'BT/CWICH/044'
  },
  {
    No: 45,
    Activities: 'FIXING OF PLASTIC SKIRTING WORK INSPECTION',
    'ID No.': 'BT/CWICH/045'
  },
  {
    No: 46,
    Activities: 'FIXING OF TERRAZZO/CERAMIC/MARBLE AND OTHER SIZE SKIRTING',
    'ID No.': 'BT/CWICH/046'
  },
  {
    No: 47,
    Activities: 'FIXING OF WOODEN MADE SKIRTING',
    'ID No.': 'BT/CWICH/047'
  },
  {
    No: 48,
    Activities: 'MARBLE CLADDING DRY STATE',
    'ID No.': 'BT/CWICH/048'
  },
  {
    No: 49,
    Activities: 'MARBLE CLADDING WET STATE',
    'ID No.': 'BT/CWICH/049'
  },
  { No: 50, Activities: 'GUTTER WORK', 'ID No.': 'BT/CWICH/050' },
  { No: 51, Activities: 'DOWN PIPE', 'ID No.': 'BT/CWICH/051' },
  {
    No: 52,
    Activities: 'FIXING OF ELECTRICAL FITTINGS/POINTS',
    'ID No.': 'BT/CWICH/052'
  },
  {
    No: 53,
    Activities: 'LIGHTENING ARRESTOR SYSTEM',
    'ID No.': 'BT/CWICH/053'
  },
  {
    No: 54,
    Activities: 'SITE WORK ELECTRICAL SYSTEM',
    'ID No.': 'BT/CWICH/054'
  },
  {
    No: 55,
    Activities: 'MANHOLES & VARIOUS CHAMBER WORKS',
    'ID No.': 'BT/CWICH/055'
  },
  {
    No: 56,
    Activities: 'PLUMBING & SANITARY WORK INSPECTION',
    'ID No.': 'BT/CWICH/056'
  },
  {
    No: 57,
    Activities: 'PLUMBING & SANITARY WORK INSTALLATION',
    'ID No.': 'BT/CWICH/057'
  },
  {
    No: 58,
    Activities: 'EXCAVATION TO SUB GRADE LEVEL',
    'ID No.': 'BT/CWICH/058'
  },
  {
    No: 59,
    Activities: 'CONSTRUCTION OF DITCHES',
    'ID No.': 'BT/CWICH/059'
  },
  {
    No: 60,
    Activities: 'CONSTRUCTION OF SUB BASE',
    'ID No.': 'BT/CWICH/060'
  },
  {
    No: 61,
    Activities: 'CONSTRUCTION OF BASE COURSE',
    'ID No.': 'BT/CWICH/061'
  },
  {
    No: 62,
    Activities: 'CONSTRUCTION OF GRAVEL WEARING COURSE',
    'ID No.': 'BT/CWICH/062'
  },
  {
    No: 63,
    Activities: 'INSPECTION FOR ROAD SURFACE DRESSING WORK',
    'ID No.': 'BT/CWICH/063'
  },
  {
    No: 64,
    Activities: 'INSPECTION FOR ASPHALT CONCRETE WORK',
    'ID No.': 'BT/CWICH/064'
  },
  { No: 65, Activities: 'CONCRETE PAVEMENT', 'ID No.': 'BT/CWICH/065' },
  {
    No: 66,
    Activities: 'WOODEN PARTITION INSPECTION',
    'ID No.': 'BT/CWICH/066'
  },
  {
    No: 67,
    Activities: 'REPAINTING OF WOOD WORK INSPECTION',
    'ID No.': 'BT/CWICH/067'
  },
  {
    No: 68,
    Activities: 'REPAINTING OF PLASTERED WALL & STEEL WORK INSPECTION',
    'ID No.': 'BT/CWICH/068'
  },
  {
    No: 69,
    Activities: 'PLUMBING AND SANITARY WORK INSPECTION (SANITARY FIXTURES)',
    'ID No.': 'BT/CWICH/069'
  },
  {
    No: 70,
    Activities: 'ELECTRO MECHANICAL & PLUMBING WORKS',
    'ID No.': 'BT/CWICH/070'
  },
  { No: 71, Activities: 'NIGHT WORK PERMIT', 'ID No.': 'BT/CWICH/071' }
]

export const  mobilizationCheckList = [
{'chkl_num': '1', 
'title':"",
'description': 'Provision of general plan of temporary works for approval',
 'activity': 'BT/CWICH/001',
 'actions':['Yes',"No","Not Applicable"]
},

  {'chkl_num': '2',
  'title':"",
   'description':'All physical obstructions are to be considered by the contractor', 
   'activity': 'BT/CWICH/001',
   'actions':['Yes',"No","Not Applicable"]
  },
    {'chkl_num': '3',
    'title':"", 
    'description': 'Location of temporary blocks and structures are to be agreed both by the contractor and the Architect', 
    'activity': 'BT/CWICH/001',
    'actions':['Yes',"No","Not Applicable"]
  },
     {'chkl_num': '4',
     'title':"",
      'description': 'The contractor is expected to facilitate the setting out tools and materials within the mobilization period', 
      'activity': 'BT/CWICH/001',
      'actions':['Yes',"No","Not Applicable"]
    }, 
      {'chkl_num': '5', 
      'title':"",
      'description': 'Position of crushers and batching plant should be with the approval/ consent of the client and others', 
      'activity': 'BT/CWICH/001',
      'actions':['Yes',"No","Not Applicable"]
    }, 
    //  -----------------------------------------------------------------

    { 'chkl_num':'6',
    
     'title':"Set up of mixers and material's dumping place shall be with the approval of the Architect",
      'contents':[
        {'chkl_num': 'a',
        // 'title':"Set up of mixers and material's dumping place shall be with the approval of the Architect",
       
        'description': "a. Equipments", 
        'activity': 'BT/CWICH/001',
        'actions':['Yes',"No","Not Applicable"]
      },
        {'chkl_num': 'b', 
        'description': "b. Materials",
         'activity': 'BT/CWICH/001',
         'actions':['Yes',"No","Not Applicable"]
        },
         {'chkl_num': 'c', 
         'description': "c. House Services",
          'activity': 'BT/CWICH/001',
          'actions':['Yes',"No","Not Applicable"]
        }, 
      ]
  },
        
        //---------------------------------------------------------------------------
        
        {'chkl_num': '7',
        'title':"",
         'description': 'The contractor shall immediately submit the Master schedule to the Architect and the work methodology of subsequent activities to the Architect for approval',
          'activity': 'BT/CWICH/001',
          'actions':['Yes',"No","Not Applicable"]
        },
         {'chkl_num': '8', 
         'title':"",
         'description': 'The contractor shall separately submit its method of site protection from floods', 'activity': 'BT/CWICH/001',
         'actions':['Yes',"No","Not Applicable"]
        }, 
        {'chkl_num': '9', 
        'title':"",
        'description': "Quality of Resident Supervisors' office and other facilities", 
        'activity': 'BT/CWICH/001',
        'actions':['Yes',"No","Not Applicable"]
      }, 
         {'chkl_num': '10',
         'title':"",
          'description': "The contractor's preparation incase of ground water encounter", 
          'activity': 'BT/CWICH/001',
          'actions':['Yes',"No","Not Applicable"]
        },
          {'chkl_num': '11', 
          'title':"",
          'description': 'Preparation of the contractor for pre construction materials testing during the mobilization period', 
          'activity': 'BT/CWICH/001',
          'actions':['Yes',"No","Not Applicable"]
        }, 
          {'chkl_num': '12',
          'title':"", 
          'description': 'Provision of general items', 
          'activity':'BT/CWICH/001',
          'actions':['Yes',"No","Not Applicable"]
        },
          //  {'chkl_num': '13', 'description': 'Additional remarks', 'activity': 'BT/CWICH/001'}
]

export const staff = [
  {
    id:"1",
    email:'Kebede_staff@gmail.com',
    username:'kebede12',
 typeOfPosition:"Executive 1",
    dateofbirth:'july 25,1988',
    role:'Senior'

  },
  {
    id:"2",
    email:'Samuel_staff@gmail.com',
    username:'samuel44',
    typeOfPosition:"Executive 2",
    dateofbirth:'july 25,1998',
    role:'Junior'

  },
  {
    id:"3",
    email:'Kebede_staff@gmail.com',
    username:'kebede12',
    typeOfPosition:"Executive 3",
    dateofbirth:'july 25,1988',
    role:'Junior'

  },
  {
    id:"4",
    email:'Tagesse_staff@gmail.com',
    username:'Tagesse12',
    typeOfPosition:"Project Manager",
    dateofbirth:'july 25,1968',
    role:'Senior'

  },
]
