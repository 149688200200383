import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SingleProjectTable from "./SingleProjectTable";
import { FaAngleLeft } from "react-icons/fa";
import axios from "axios";


function Project() {
  const navigate = useNavigate();
  const [project, setProject] = useState(null);
  const [projectSearch, setProjectSearch] = useState("");
  const { id } = useParams();
  const userGroup = localStorage.getItem("userGroup");
 

  useEffect(() => {
   
    axios
      .get(`https://pms.dalyobt.com/api/view_site/${id}`)
      .then((response) => {
        // console.log(response.data);
        setProject(response.data);
        localStorage.setItem("projectID", id);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [id]);

  // console.log(project);

  // useEffect(()=>{
  //   const findProject = projects.find((project)=>{
  //         return project.project_id === id;
  //     })
  //     setProject(findProject)

  //     localStorage.setItem('projectID',findProject?.project_id)

  //     },[id])

  return (
    <div className="mt-[50px]">
      <div className="w-[90%] mt-6 mx-auto flex justify-between items-center">
        <div className="flex justify-start items-center  gap-[20px]">
          <FaAngleLeft
            className="text-[30px] cursor-pointer text-[#0B72B5]"
            onClick={() => navigate(`/branch/projects/${id}`)}
          />
          {/* <span className='text-[20px] text-[#0B72B5] font-bold'>{project?.map((item)=>item?.project_id.toString()===id.toString()?item.name:'')}</span> */}
          <span className="text-[20px] text-[#0B72B5] font-bold">
            Project / {id}
          </span>
        </div>

        {userGroup?.toString() === "1" && (
          <button
            onClick={() => navigate("/projects/project/addsite")}
            className="bg-[#0B72B5] py-2 px-4 text-white text-[14px] rounded-[10px]"
          >
            Add Site
          </button>
        )}
      </div>
      <div className="w-[100%] mt-[50px] flex justify-center">
        <input
          type="text"
          placeholder="Search by site name"
          onChange={(e) => setProjectSearch(e.target.value)}
          className="bg-white border border-gray-400 p-3 w-[40%] rounded-[5px] text-[14px] outline-none text-gray-500 max-[1000px]:w-[80%]"
        />
      </div>
      <div className="w-[95%] mx-auto my-[20px] bg-white  p-4 rounded-[10px] mt-[50px] border border-gray-300">
        <div className="w-[100%]  mt-[50px]">
          <SingleProjectTable project={project} projectSearch={projectSearch} />
        </div>
      </div>
    </div>
  );
}

export default Project;
