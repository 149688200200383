/* eslint-disable no-unsafe-optional-chaining */
import { Box, Button, Chip, Paper, TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { Done } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import GridData from "./GridData";
import BasicSelect from "./Select";
import { useLocation, useNavigate } from "react-router-dom";
import { Container } from "@mui/material";
// import axios from "axios";

// const data = [
//   {
//     id: 1,
//     item: "door",
//     description: "metal door",
//     quantity: 100,
//     unit: "cm",
//     rate: 1000,
//     price: 1000,
//   },
//   {
//     id: 2,
//     item: "pump wire",
//     description: "wire pump",
//     quantity: 200,
//     unit: "kg",
//     rate: 1000,
//     price: 1000,
//   },
//   {
//     id: 3,
//     item: "window",
//     description: "window wood",
//     quantity: 300,
//     unit: "kg",
//     rate: 1000,
//     price: 1000,
//   },
//   {
//     id: 4,
//     item: "plastic wire",
//     description: "a wire plastic wire",
//     quantity: 300,
//     unit: "meters",
//     rate: 1000,
//     price: 1000,
//   },
// ];
const ProjectManager = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const buildingData = location.state;
  const [checkedId, setCheckedId] = useState([]);
  const [productQty, setProductQty] = useState([]);
  const [selectedContractor, setContractor] = useState("");
  const [boq, setBOQ] = useState([]);

  const contractors = ["Contractor 1", "Contractor 2", "Contractor 3"];

  useEffect(() => {
    const getBOQ = async () => {
      try {
        const response = await fetch(
          "https://pms.dalyobt.com/api/BOQ_Itemmodels/"
        );
        if (response.ok) {
          const data = await response.json();
          setBOQ(data);
        } else {
          throw new Error("Request failed.");
        }
      } catch (error) {
        console.error(error);
      }
    };

    getBOQ();
  }, []);
  //   useEffect(()=>{
  //     const getBOQ = async ()=>{
  //         await axios.get('https://pms.dalyobt.com/api/BOQ_Itemmodels/').then((res)=>{
  //           setBOQ(res.data)

  //           })
  //     }
  //     getBOQ()

  // },[])
  console.log(boq);

  const handleApprove = (item) => {
    navigate("/contractor", {
      state: {
        checkedId,
        selectedContractor,
        productQty,
        buildingData,
      },
    });
  };

  const columns = [
    {
      field: "Item_no",
      headerName: "Item No.",
      filterable: false,
      flex: 0.5,
      minWidth: 50,
      // renderCell: (index) => index.api.getRowIndex(index.row.id) + 1
    },
    // {
    //   field: "item",
    //   headerName: "Item Name",
    //   flex: 1,
    //   minWidth: 100,
    // },
    {
      field: "description",
      headerName: "Item Description",
      flex: 1,
      minWidth: 100,
    },

    {
      field: "quantity",
      headerName: "Quantity",
      type: "number",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "quantity_unit",
      headerName: "Quantity Unit",
      type: "number",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "remark",
      headerName: "Remark",
      type: "number",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "unit_price",
      headerName: "Price(Birr)",
      type: "number",
      minWidth: 120,
      flex: 1,
      renderCell: (params) => (
        <div>
          <TextField
            fullWidth
            type={"number"}
            SelectProps={{ native: true }}
            variant="filled"
            value={
              productQty?.find((item) => item?.id === params?.row?.id)?.price
            }
            onChange={(e) =>
              setProductQty((prev) => [
                ...prev?.filter((item) => item?.id !== params?.row?.id),
                {
                  id: params?.row?.id,
                  item: params?.row?.item,
                  quantity: params?.row?.quantity,
                  price: e.target.value,
                  description: params?.row?.description,
                  unit: params?.row?.unit,
                  selectedContractor,
                },
              ])
            }
          />
        </div>
      ),
    },

    {
      field: "totalPrice",
      headerName: "Total Price",
      type: "number",
      minWidth: 120,
      flex: 1,
      renderCell: (params) => {
        const product = productQty?.find(
          (item) => item?.id === params?.row?.id
        );
        return Number(product?.price || 0) * Number(product?.quantity || 0);
      },
    },
  ];

  return (
    <>
      <Container>
        <Paper sx={{ width: "100%", marginTop: "0" }}>
          <Container
            sx={{
              backgroundColor: "white",
              width: "90%",
              padding: "10px",
              marginTop: "60px",
              display: "flex",
              gap: "20px",
            }}
          >
            <Box sx={{ marginTop: "30px" }}>
              <Typography
                sx={{ color: "#1976d2", fontWeight: "bold", paddingY: "10px" }}
              >
                Building ID:{" "}
              </Typography>
              <Typography
                sx={{ color: "#1976d2", fontWeight: "bold", paddingY: "10px" }}
              >
                Building Type:{" "}
              </Typography>
              <Typography
                sx={{ color: "#1976d2", fontWeight: "bold", paddingY: "10px" }}
              >
                Building Usage:{" "}
              </Typography>
            </Box>
            <Box sx={{ marginTop: "30px" }}>
              <Typography
                sx={{ color: "gray", fontWeight: "bold", paddingY: "10px" }}
              >
                {buildingData?.buildingID}{" "}
              </Typography>
              <Typography
                sx={{ color: "gray", fontWeight: "bold", paddingY: "10px" }}
              >
                {buildingData?.buildingType}
              </Typography>
              <Typography
                sx={{ color: "gray", fontWeight: "bold", paddingY: "10px" }}
              >
                {buildingData?.buildingUsage}{" "}
              </Typography>
            </Box>
          </Container>
          {console.log(contractors, "Contractor")}
          <BasicSelect
            contractors={contractors}
            
            setState={setContractor}
            selectedContractor={selectedContractor}
          />
          <GridData
            rows={boq}
            columns={columns}
            onRowSelectionModelChange={(newSelectionModel) => {
              console.log(newSelectionModel);
              setCheckedId(newSelectionModel);
            }}
            selectionModel={checkedId}
            checkboxSelection
          />

          <Stack spacing={2} m={3}>
            <div className="flex flex-row justify-end gap-2 pb-5">
              <span>Total Amount : </span>
              <Chip
                label={productQty?.reduce(
                  (a, b) => a + Number(b?.price) * Number(b?.quantity),
                  0
                )}
              />
              <Button
                variant="contained"
                disabled={checkedId?.length === 0}
                type="button"
                onClick={() => {
                  setProductQty(
                    productQty?.filter((item) => {
                      checkedId.includes(item?.id);
                      handleApprove(item);
                    })
                  );
                }}
                color="primary"
                startIcon={<Done />}
              >
                Approve
              </Button>
            </div>
          </Stack>
        </Paper>
      </Container>
    </>
  );
};

export default ProjectManager;
