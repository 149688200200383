import axios from "axios";
import React, { useState } from "react";
import { BsFillCloudUploadFill } from "react-icons/bs";
import { useParams } from "react-router-dom";

function TemporaryHandover() {
  const [tempHandoverFile, setTempHandoverFile] = useState(null);
  const [handoverDateFill, setHandoverDateFill] = useState("");
  const [remarkFill, setRemarkFill] = useState("");
  const projectID = localStorage?.getItem("projectID");
  const siteID = localStorage?.getItem("siteID");
  const userID = localStorage?.getItem("userID");
  const { id } = useParams();
  const [error, setError] = useState(false);
  const [fieldError, setFieldError] = useState(false);

  const handleSubmit = async () => {
    if (tempHandoverFile && handoverDateFill) {
      const formData = new FormData();

      formData.append("handover_date", handoverDateFill);
      formData.append("attachment", tempHandoverFile);
      formData.append("remark", remarkFill);
      formData.append("block", id);
      formData.append("site", siteID);
      formData.append("project", projectID);
      formData.append("prepared_by", userID);

      await axios
        .post("https://pms.dalyobt.com/api/temp_hand_over/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          // console.log(response.data);
          window.location.href = `/projects/sites/buildings/${id}`;
        })
        .catch((error) => {
          console.error(error);
          setError(true);
          setFieldError(false);
        });
    } else {
      setError(true);
      setFieldError(true);
    }
  };

  return (
    <div className="py-2">
      <div className="w-[90%] mx-auto bg-white p-4  rounded shadow-md">
        <div className="w-[100%] mt-[20px] flex justify-center items-center">
          <span className="text-[#0B72B5] font-bold">Temporary Handover</span>
        </div>
        {error && (
          <div className="w-[80%] mx-auto my-4 bg-red-500 text-center p-2 rounded-md">
            {fieldError ? (
              <p className="text-white font-medium">
                There are some missing fields.
              </p>
            ) : (
              <p className="text-white font-medium">
                Please insert the correct format.
              </p>
            )}
          </div>
        )}
        <div className="flex justify-between my-[20px] gap-[10px]">
          <div className="w-[50%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              Date <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="date"
                onChange={(e) => setHandoverDateFill(e.target.value)}
                // onChange={(e) => setStartDate(e.target.value)}
                placeholder="Ex. 2023-07-08"
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
            {/* {dateError && <span className="text-[14px] text-red-500">Invalid Date</span>} */}
          </div>
        </div>
        {/* <div className="flex items-center justify-between gap-[10px]">
          <div className="w-[50%]  flex flex-col  items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              Prepared By <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="text"
                placeholder="Mr.Y"
                // onChange={(e) => setName(e.target.value)}
                className="w-[100%] border border-[#ced4da] py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>
          <div className="w-[50%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              Approved By <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="text"
                placeholder="Mr.X"
                // onChange={(e) => setBudget(e.target.value)}
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>
        </div> */}
        <div className="w-[100%] mt-[30px] flex flex-col gap-[10px]">
          <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
            Attachement <span className="text-red-700">*</span>
          </span>
          <label>
            <input
              type="file"
              onChange={(e) => setTempHandoverFile(e.target.files[0])}
              hidden
            />
            <div className="[w-[70%] h-[200px] flex justify-center gap-[10px] text-[#0B72B5] items-center p-4 mx-auto border border-gray-300 rounded border-dashed cursor-pointer text-center">
              <BsFillCloudUploadFill />
              <span className="text-[14px] font-bold">Upload Attachement</span>
            </div>
          </label>
        </div>
        <div className="w-[100%] my-4 overflow-y-scroll">
          {/* How to view the uploaded file inside this div */}
          {tempHandoverFile && (
            <embed
              src={URL.createObjectURL(tempHandoverFile)}
              type="application/pdf"
              width="100%"
              height="600px"
            />
          )}
        </div>
        <div className="flex mt-[30px] items-center justify-between gap-[10px]">
          <div className="w-[100%]  flex flex-col  items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              Remark
            </span>
            <div className="w-[100%] rounded-[5px]">
              <textarea
                rows={10}
                placeholder="Remark"
                onChange={(e) => setRemarkFill(e.target.value)}
                // onChange={(e) => setName(e.target.value)}
                className="w-[100%] resize-none border border-[#ced4da] py-3 px-4 rounded-[5px] text-[14px] outline-none"
              ></textarea>
            </div>
          </div>
        </div>
        <div className="w-[100%] mt-[30px] flex justify-end items-center">
          <button
            onClick={handleSubmit}
            className="bg-[#0B72B5] cursor-pointer text-white text-[14px] py-2 px-4 rounded"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

export default TemporaryHandover;
