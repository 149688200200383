// import { Button } from "@mui/material";
// import axios from "axios";
// import React, { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";

import React, { useState } from "react";
import InitialProgressReport from "./InitialProgressReport";
import CurrentProgressReport from "./CurrentProgressReport";

function ProgressReport() {
  // const [listOfBOQ, setListOfBOQ] = useState([]);
  // const [date,setDate] = useState("")

  const [initProg, setInitProg] = useState(true);
  const [currentProg, setCurrentProg] = useState(false);

  // const { id } = useParams();

  // useEffect(() => {
  //   axios
  //     .get(`https://pms.dalyobt.com/api/boq_item/${id}/`)
  //     .then((response) => {
  //       setListOfBOQ(response.data);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // }, [id]);
  // const filteredBOQByDate=listOfBOQ.filter((d)=>{
  //   return d.changeDate === date;
  // })

  // console.log(listOfBOQ)
  // console.log(filteredBOQByDate)
  // console.log("Date: ",date)

  // console.log("Current-ID: ", id);

  const handleInit = () => {
    setInitProg(true);
    setCurrentProg(false);
  };

  const handleCurrent = () => {
    setInitProg(false);
    setCurrentProg(true);
  };

  return (
    <div className="w-full">
      <div className="w-[50%] mx-auto flex justify-between my-5 mb-10 items-center max-[1000px]:w-[80%]">
        <button
          onClick={handleInit}
          className="bg-[#ffffff] hover:bg-[#0B72B5] focus:bg-[#0B72B5] transition duration-500 hover:text-white focus:text-white shadow-2xl p-2 text-[#0B72B5] rounded-md font-medium outline-none"
          autoFocus
        >
          Initial BOQ
        </button>
        <button
          onClick={handleCurrent}
          className="bg-[#ffffff] hover:bg-[#0B72B5] focus:bg-[#0B72B5] transition duration-500 hover:text-white focus:text-white shadow-2xl p-2 text-[#0B72B5] rounded-md font-medium outline-none"
          
        >
          Progress Reports
        </button>
      </div>

      <div className="w-full">
        {initProg && <InitialProgressReport />}
        {currentProg && <CurrentProgressReport />}
      </div>

    </div>
  );
}

export default ProgressReport;
