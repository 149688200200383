import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// import { mobilizationCheckList } from "../../utils/data";

function CheckListTable({ filteredList, dateCurrent, input, floor, setError, findActivityID }) {
  //    const [radioChecked,setRadioChecked] = useState();
  const [selectedRadio, setSelectedRadio] = useState({});
  const [remarkTxt, setRemarkTxt] = useState("");
  const { id } = useParams();
  //  const userGroup = localStorage.getItem('userGroup');
  const userID = localStorage.getItem('userID');

  // const handleRadioChange = (rowIndex, itemId, value) => {

  //     setSelectedRadio((prevState) => ({
  //       ...prevState,
  //       [rowIndex]: {
  //         ...prevState[rowIndex],
  //         "chkl_item": itemId,
  //         "value": value,
  //       },
  //     }));

  // };

  const handleRadioChange = (rowIndex, itemId, value) => {
    setSelectedRadio((prevState) => ({
      ...prevState,
      [rowIndex]: {
        ...prevState[rowIndex],
        [itemId]: value,
      },
    }));
  };

  const transformedSelectedRadio = Object.entries(selectedRadio).map(
    ([rowIndex, data]) => {
      const [chkl_item, value] = Object.entries(data)[0];
      return {
        chkl_item,
        value,
      };
    }
  );

  useEffect(() => {
    setSelectedRadio({});
  }, [input]);

  // console.log(
  //   transformedSelectedRadio,
  //   "-----------------------------------------------------------"
  // );
  // console.log(remarkTxt, "Remark")
  // console.log(dateCurrent+'T00:00:00Z', "Date Current")

  const handleSubmit = () => {
    // Check if all rows have at least one radio option selected
    const isValid = filteredList.every((mob, rowIndex) => {
      const selectedValue = selectedRadio[rowIndex]?.[mob?.id];
      return (
        selectedValue === "YES" ||
        selectedValue === "NO" ||
        selectedValue === "NA"
      );
    });

    if (isValid && dateCurrent && floor && findActivityID && remarkTxt) {
      setError(null);
      axios
        .post(`https://pms.dalyobt.com/api/check_list/${id}/`, {
          block: id,
          activity: findActivityID,
          floor: floor,
          items: transformedSelectedRadio,
          prepared_date: dateCurrent,
          // prepared_date: dateCurrent+'T00:00:00Z',
          remark: remarkTxt,
          prepared_by: userID
        })
        .then((response) => {
          // console.log(response.data);
          window.location.href=`/projects/sites/buildings/${id}`
        })
        .catch((error) => {
          // console.error(error)
          setError(
            "Please select one of the options for each row or other missing fields."
          );
        });
    } else {
      setError(
        "Please select one of the options for each row or other missing fields."
      );
    }
  };

  return (
    <div className="w-[100%] p-2 max-h-[450px] overflow-y-auto mt-[20px]  mx-auto  overflow-x-auto  sm:rounded-l">
      <table className="w-full mt-[30px] text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-center text-gray-700 uppercase border-b border-gray-300">
          <tr>
            <th scope="col" className="px-6 py-3">
              ID
            </th>
            <th scope="col" className="px-6 py-3">
              Chkl_Num
            </th>
            {/* <th scope="col" className="px-6 py-3">
           Sub-section
            </th> */}
            <th scope="col" className="px-6 py-3">
              Description
            </th>

            <th scope="col" className="px-6 py-3 flex justify-around">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredList?.map((mob, rowIndex) => (
            <tr
              key={rowIndex}
              className=" text-gray-600 border-b border-gray-300"
            >
              <td className="px-6 py-4 font-medium whitespace-nowrap">
                {mob?.id}
              </td>
              <td className="px-6 py-4 font-medium whitespace-nowrap">
                {mob?.chkl_num}
              </td>

              <td className="px-6 py-4 font-medium whitespace-nowrap">
                {mob?.description}
              </td>

              <td className="w-[400px] h-[100%] px-6  font-medium whitespace-nowrap py-[10px] flex gap-[10px] justify-between items-end">
                <input
                  type="radio"
                  name={`radio_${rowIndex}_${mob?.id}`}
                  id={`YES_${rowIndex}_${mob?.id}`}
                  checked={
                    selectedRadio[rowIndex] &&
                    selectedRadio[rowIndex][mob?.id] === "YES"
                  }
                  onChange={() => handleRadioChange(rowIndex, mob?.id, "YES")}
                />
                <label htmlFor={`YES_${rowIndex}_${mob?.id}`}>Yes</label>
                <input
                  type="radio"
                  name={`radio_${rowIndex}_${mob?.id}`}
                  id={`NO_${rowIndex}_${mob?.id}`}
                  checked={
                    selectedRadio[rowIndex] &&
                    selectedRadio[rowIndex][mob?.id] === "NO"
                  }
                  onChange={() => handleRadioChange(rowIndex, mob?.id, "NO")}
                />
                <label htmlFor={`NO_${rowIndex}_${mob?.id}`}>No</label>
                <input
                  type="radio"
                  name={`radio_${rowIndex}_${mob?.id}`}
                  id={`NA_${rowIndex}_${mob?.id}`}
                  checked={
                    selectedRadio[rowIndex] &&
                    selectedRadio[rowIndex][mob?.id] === "NA"
                  }
                  onChange={() => handleRadioChange(rowIndex, mob?.id, "NA")}
                />
                <label htmlFor={`NA_${rowIndex}_${mob?.id}`}>
                  Not Applicable
                </label>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {filteredList?.length === 0 && (
        <div className="w-[100%] my-[20px] flex justify-center items-center">
          {" "}
          <span className="text-gray-600 font-bold ">
            No CheckList available
          </span>
        </div>
      )}

      <div className="w-[90%] mx-auto mt-[40px] flex flex-col gap-[10px]">
        <div className="flex items-start gap-3"><span className="text-[#0B72B5] font-bold">Remark</span> <span className="text-red-700">*</span></div>
        <textarea
          rows={8}
          onChange={(e) => setRemarkTxt(e.target.value)}
          value={remarkTxt}
          placeholder="Additional Remarks"
          className="w-[40%] p-2 bg-white outline-none border border-gray-400 resize-none rounded-[5px]"
          aria-label="Additional Remarks"
        ></textarea>
      </div>

      <div className="w-[95%] my-[30px] p-4 flex justify-end items-center gap-[20px]">
        <button onClick={handleSubmit} className="py-3 px-4 bg-[#0B72B5] text-white rounded-[10px]">
          Save
        </button>
      </div>
    </div>
  );
}

export default CheckListTable;
