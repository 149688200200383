import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function AssignedContractorTable({ assignedContractors, contractors, error }) {
  // const [file, setFile] = useState(null);
  const [contractorID, setContractorID] = useState(null);
  const [currentContractor, setCurrentContreactor] = useState({});
  const [contractorList, setContractorList] = useState([]);
  const [blockList, setBlockList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const cContractor = contractors?.find((c) => {
      return c?.id === contractorID;
    });
    setCurrentContreactor(cContractor);
    // console.log("current contractor", currentContractor);

    // const match = contractors.filter(c => assignedContractors.map(a => a.id).includes(c.id))[0];
    // setCurrentContreactor(match)// console.log("Match found: " + JSON.stringify(match));
    // console.log(currentContractor)
  }, []);

  useEffect(() => {
    axios
      .get("https://pms.dalyobt.com/api/contractor_view/")
      .then((response) => {
        setContractorList(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    axios
      .get("https://pms.dalyobt.com/api/view_block/")
      .then((response) => {
        setBlockList(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  // console.log(assignedContractors)
  return (
    <div className="w-[100%] mx-auto relative overflow-x-auto  sm:rounded-l">
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-center text-gray-700 uppercase border-b border-gray-300">
          <tr>
            <th scope="col" className="px-8 py-3">
              Contractor
            </th>
            <th scope="col" className="px-6 py-3">
              Trade Registration
            </th>
            <th scope="col" className="px-8 py-3">
              Trade License
            </th>

            <th scope="col" className="px-6 py-3">
              TIN Registration
            </th>
            <th scope="col" className="px-6 py-3">
              Vat
            </th>

            <th scope="col" className="px-6 py-3">
              Competency
            </th>

            <th scope="col" className="px-6 py-3">
              Performance Bond
            </th>
            <th scope="col" className="px-10 py-3">
              Advance Gurantee
            </th>

            <th scope="col" className="px-8 py-3">
              Block
            </th>
            <th scope="col" className="px-12 py-3">
              Pb Start Date
            </th>
            <th scope="col" className="px-12 py-3">
              Pb End Date
            </th>
            <th scope="col" className="px-12 py-3">
              AG Start Date
            </th>
            <th scope="col" className="px-12 py-3">
              AG End Date
            </th>
            <th scope="col" className="px-6 py-3">
              Remark
            </th>

            <th scope="col" className="px-6 py-3">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {assignedContractors?.map((contractor, index) => {
            return (
              <tr
                key={index}
                className="text-center text-gray-600 border-b border-gray-300"
              >
                <td className="px-6 py-4">
                  {
                    contractorList?.find(
                      (item) => item?.id === contractor?.contractor
                    )?.name
                  }
                </td>
                <td className="p-1">
                  <a
                    href={`https://pms.dalyobt.com${contractor?.trade_registration_url}`}
                    download
                  >
                    <button className="bg-[#0B72B5] text-white p-2 cursor-pointer text-[12px] rounded-[5px]">
                      Trade Registration
                    </button>
                  </a>
                </td>
                <td className="p-1">
                  <a
                    href={`https://pms.dalyobt.com${contractor?.trade_license_url}`}
                    download
                  >
                    <button className="bg-[#0B72B5] text-white p-2 cursor-pointer text-[12px] rounded-[5px]">
                      Trade License
                    </button>
                  </a>
                </td>

                <td className="p-1">
                  <a
                    href={`https://pms.dalyobt.com${contractor?.tin_registration_url}`}
                    download
                  >
                    <button className="bg-[#0B72B5] text-white p-2 cursor-pointer text-[12px] rounded-[5px]">
                      TIN Registration
                    </button>
                  </a>
                </td>
                <td className="p-1">
                  <a
                    href={`https://pms.dalyobt.com${contractor?.vat_url}`}
                    download
                  >
                    <button className="bg-[#0B72B5] text-white p-2 cursor-pointer text-[12px] rounded-[5px]">
                      VAT
                    </button>
                  </a>
                </td>
                <td className="p-1">
                  <a
                    href={`https://pms.dalyobt.com${contractor?.competency_url}`}
                    download
                  >
                    <button className="bg-[#0B72B5] text-white p-2 cursor-pointer text-[12px] rounded-[5px]">
                      Competency
                    </button>
                  </a>
                </td>

                <td className="p-1">
                  <a
                    href={`https://pms.dalyobt.com${contractor?.performance_bond_url}`}
                    download
                  >
                    <button className="bg-[#0B72B5] text-white p-2 cursor-pointer text-[12px] rounded-[5px]">
                      Performance Bond
                    </button>
                  </a>
                </td>
                <td className="p-1">
                  <a
                    href={`https://pms.dalyobt.com${contractor?.advance_guarantee_url}`}
                    download
                  >
                    <button className="bg-[#0B72B5] text-white p-2 cursor-pointer text-[12px] rounded-[5px]">
                      Advance Gurantee
                    </button>
                  </a>
                </td>

                <td className="px-6 py-4">
                  {
                    blockList?.find((item) => item?.id === contractor?.block)
                      ?.name
                  }
                </td>
                {/* <td className="px-6 py-4">{contractor?.block}</td> */}
                {/* <td className="px-6 py-4">{contractor?.contractor}</td> */}

                <td className="px-6 py-4">{contractor?.pb_start_date}</td>
                <td className="px-6 py-4">{contractor?.pb_end_date}</td>
                <td className="px-6 py-4">{contractor?.ag_start_date}</td>
                <td className="px-6 py-4">{contractor?.ag_end_date}</td>

                <td className="px-6 py-4">
                  {contractor?.remark === "null"
                    ? "No Remark"
                    : contractor?.remark}
                </td>
                <td>
                  <button
                    className="bg-[#0B72B5] py-2 px-4 text-white text-[12px] rounded-[5px]"
                    onClick={() =>
                      navigate(
                        `/updateassignedcontractor/${contractor?.block}`,
                        {
                          state: { currentContractor: contractor },
                        }
                      )
                    }
                  >
                    Update
                  </button>
                </td>

                {/* {userGroup.toString()==="1" && <td className="flex justify-center  items-center px-6 py-4 space-x-3"> */}
                {/* <button className='bg-[#0B72B5] text-white rounded-[5px] p-2'>Edit</button> */}
                {/* <button className='bg-rose-500 text-white rounded-[5px] p-2' onClick={()=>handleDelete(project.id)}>Remove</button> */}

                {/* </td>} */}
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* {assignedContractors.length ===0 && <div className='w-[100%] my-[20px] flex justify-center items-center'> <span className='text-gray-600 font-bold '>No project available</span></div> */}
      {/* } */}
    </div>
  );
}

export default AssignedContractorTable;
