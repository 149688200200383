import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BOQ from "./BOQ/BOQ";
import Papa from "papaparse";
import ViewBOQ from "./BOQ/ViewBOQ";
import axios from "axios";


function SiteTable({ buildingSearch, projects }) {
  const { id } = useParams();
  const [file, setFile] = useState(null);
  // const[excelFile,setExcelFile] = useState(null)
  // const [buildings, setBuildings] = useState([]);
  const [boq, setBOQ] = useState(false);
  const [viewBoq, setViewBOQ] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);
  // const [boqList,setBoqList] = useState([])
  const [buildingID, setBuildingID] = useState(null);
  const navigate = useNavigate();
  const userGroup = localStorage.getItem("userGroup");
  const [blockContra, setBlockContra] = useState([]);
  const [fullContraName, setFullContraName] = useState([]);
  const [boqTry, setBOQTry] = useState([])

  // const proj =localStorage.getItem('projectID')

  //  useEffect(()=>{
  //    const findProject = projects?.find((project)=>{
  //         return project.project_id === proj;
  //     })
  //     const findSite = findProject?.sites.find((site)=>{
  //         return site.site_id === id;
  //     })
  //     setBuildings(findSite?.buildings)

  //    },[id,proj,buildings])

  useEffect(()=>{
    axios.get('https://pms.dalyobt.com/api/boq_item_get/').then((response)=>{
      setBOQTry(response.data)
    }).catch((error)=>{
      console.error(error)
    })
  },[])

  const filtered = projects?.filter((b) => {
    return b.name?.includes(buildingSearch) || b.name?.includes(buildingSearch?.toLowerCase()) || b.name?.includes(buildingSearch?.toUpperCase());
  });


  const build = filtered?.find((b) => {
    return b?.id === id;
  });

  // console.log(projects)
  //  const build = buildings?.find((b)=>{
  //   return  b.id === buildingID;
  //  })

  const fileConvert = (file) => {
    try {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          setData(results.data);
        },
      });
      setViewBOQ(true);
      // setFile(null);
    } catch (error) {
      setError(true);
    }
  };

  const handleFileUpload = async (uploadedFile) => {
    // console.log("File:", uploadedFile);
    if (uploadedFile) {
      fileConvert(uploadedFile);
      // console.log(uploadedFile, "Uploaded")
      setViewBOQ(true);
      // console.log('Upload data:');
      try {
        const formData = new FormData();
        formData.append("myfile", uploadedFile);
        const token = localStorage.getItem("token");
        // console.log(token)

        const response = await axios.post(
          `https://pms.dalyobt.com/api/import-excel/${buildingID}/`,
          formData,
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        );

        // console.log('Response data:', response.data);

        if (response.data.data) {
          setData(response.data.data);
          // window.location.href = `/projects/sites/${id}`
        } else {
          // Handle the error or invalid response
          console.error("Invalid response data");
          // Perform additional actions on error
        }
      } catch (error) {
        console.error(error); // Handle the error
        // Perform additional actions on error
      }
    }
  };

  useEffect(() => {
    axios
      .get("https://pms.dalyobt.com/api/block_contractor/")
      .then((response) => {
        // console.log(response.data)
        setBlockContra(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, []);

  useEffect(() => {
    axios
      .get("https://pms.dalyobt.com/api/contractor_view/")
      .then((response) => {
        // console.log(response.data)
        setFullContraName(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, []);


  // console.log(blockContra);

  const deadlineCalculator = (newDate) =>{
      
    const d = new Date(newDate)
    const date2 = new Date(); 
    var time_difference = d.getTime() - date2.getTime();
    var day_difference = time_difference / (1000 * 3600 * 24);
      
       return day_difference;
    }

  return (
    <div className="w-[100%]  mx-auto  overflow-x-auto  sm:rounded-l">
      <div className="w-[100%] flex justify-end items-center gap-[10px]">
        {/* <button
        className=" bg-[#0B72B5] text-white text-[14px] py-2 px-4 rounded-[5px]"
        onClick={() => setViewBOQ(true)}
      >
        View BOQ
      </button> */}
        {/* <button  className=" bg-[#0B72B5] text-white text-[14px] py-2 px-4 rounded-[5px]" onClick={()=>setBOQ(true)}>Insert BOQ</button> */}
        <a
          href={require("../../utils/try04.xlsx")}
          download
          className="bg-[#0B72B5] text-white text-[14px] py-2 px-4 rounded-[5px]"
        >
          Download BOQ Template
        </a>
      </div>
      <table className="w-full mt-[30px] text-sm text-left text-gray-500 dark:text-gray-400 overflow-x-auto">
        <thead className="text-xs text-center text-gray-700 uppercase border-b border-gray-300">
          <tr>
            <th scope="col" className="px-6 py-3">
              Block Name
            </th>
            <th scope="col" className="px-6 py-3">
              Housing Program
            </th>
           
            <th scope="col" className="px-6 py-3">
              Block Status
            </th>
            <th scope="col" className="px-6 py-3">
              Contractor
            </th>
            <th scope="col" className="px-6 py-3">
              Price
            </th>
            <th scope="col" className="px-6 py-3">
              Start Date
            </th>
            <th scope="col" className="px-6 py-3">
              End Date
            </th>

            {userGroup?.toString() === "1" && (
              <th scope="col" className="px-6 py-3">
                Upload BOQ
              </th>
            )}

            {userGroup?.toString() === "1" && (
              <th scope="col" className="px-6 py-3">
                Add Sub-Contractor
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {filtered?.map((building) => {
                const endDate = building?.end_date?.split("T")[0]  
                let result = deadlineCalculator(endDate)
                const findBlock = blockContra?.find((block)=>block?.block===building?.id);
             
                const getContractorName = fullContraName?.find((contractor)=>contractor?.id ===findBlock?.contractor)?.name;
            return (
              <tr
                key={building.id}
                onClick={() =>
                  navigate("/projects/sites/buildings/" + building.id, {
                    state: {
                      buildingID: building.id,
                      buildingName: building.name,
                      buildingType: building.type,
                      buildingUsage: building.useage,
                    },
                  })
                }
                className="text-center text-gray-600 border-b border-gray-300  cursor-pointer"
              >
                <td className="px-6 py-4 font-medium whitespace-nowrap">
                  
                    {building?.name}
            
                 
                </td>
                <td className={result < 30 ? "px-6 py-4 text-red-500" : "px-6 py-4"}>{building?.type_name}</td>
                {/* <td className="px-6 py-4 flex flex-col gap-[10px] justify-center  text-start">
                {building??.infrastructure.map((i) => {
                  return <li key={i.id}>{i.name}</li>;
                })}
              </td> */}
                <td className={result < 30 ? "px-6 py-4 text-red-500" : "px-6 py-4"}>{building?.status}</td>
                {/* fullContraName.find((items)=>bloCont.find((contra)=>(contra.block.toString()===building?.id.toString())?items.name:'')) */}
                {/* {
                  fullContraName.find((items)=>(bloCont.block.toString()===building?.id.toString())?items.name:'')
                } */}

                <td className={result < 30 ? "px-6 py-4 text-red-500" : "px-6 py-4"}>
                  {/* {blockContra.find(
                    (item) => item.block.toString() === building?.id.toString()
                  )
                    ? blockContra.find(
                        (item) =>
                          item.block.toString() === building?.id.toString()
                      ).contractor
                    : ""} */}
                    {getContractorName}
                </td>

                <td className={result < 30 ? " text-red-500 px-6 py-4" : "px-6 py-4"}>
                  {building ? building?.price : "-"}
                </td>
                <td className={result < 30 ? "text-red-500 px-6 py-4" : "px-6 py-4"}>
                  {building?.start_date}
                </td>
                <td className={result < 30 ? "px-6 py-4 text-red-500" : "px-6 py-4"}>
                  {building?.end_date}
                </td>

                {userGroup?.toString() === "1" && (
                  <td className={result < 30 ? "px-6 py-4 text-red-500" : "px-2 py-4"}>
                    {/* <input type='file'   onChange={(e)=>setFile(e.target.files[0])}  /> */}

                    {/* <button onClick={()=>setBuildingID(building.id)}>
                      <label>
                     <input type='file'  hidden onChange={(e)=>setExcelFile(e.target.files[0])} />
                      <span  className="bg-[#0B72B5] text-white p-2 cursor-pointer text-[12px] rounded-[5px]" >Upload BOQ</span>
                     </label>
                      </button>
                   <button onClick={handleUpload}>Send</button> */}

                    {!(boqTry?.find((item)=>item?.block?.toString()===building?.id?.toString())) && <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setBuildingID(building.id);
                      }}
                    >
                      <label>
                        <input
                          type="file"
                          name="myfile"
                          accept=".csv, .xlsx"
                          onChange={(e) => handleFileUpload(e.target.files[0])}
                          hidden
                        />
                        <span className="bg-[#0B72B5] text-white p-1 py-2 cursor-pointer text-[12px] rounded-[5px]">
                          Upload BOQ
                        </span>
                      </label>
                    </button>}
                    {/* <button onClick={handleSave}>Save</button> */}

                    {/* <a href="/projects" className="font-medium text-red-600 dark:text-red-500 hover:underline">Remove</a> */}
                  </td>
                )}
                {userGroup?.toString() === "1" && (
                  <td className={result < 30 ? "px-6 py-4 text-red-500" : "px-2 py-4"}>
                    <button
                      onClick={(e) =>{
                        e.stopPropagation();
                        navigate(
                          `/projects/sites/addsubcontractor/${building.id}`
                        )
                      }
                      }
                     className="bg-[#0B72B5] text-white p-2 cursor-pointer text-[12px] rounded-[5px]"
                    >
                    Sub-Contractor
                    </button>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
      {filtered?.length === 0 && (
        <div className="w-[100%] my-[20px] flex justify-center items-center">
          {" "}
          <span className="text-gray-600 font-bold ">
            No building available
          </span>
        </div>
      )}
      {boq && <BOQ setBOQ={setBOQ} />}
      {viewBoq && (
        <ViewBOQ
          data={data}
          setFile={setFile}
          building={build}
          setViewBOQ={setViewBOQ}
        />
      )}
    </div>
  );
}

export default SiteTable;
