import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { deadlineCalculator } from "../../utils/dateSubtract";
import { FaAngleLeft } from "react-icons/fa";
import axios from "axios";

function EditSite() {
  const navigate = useNavigate();
  const rlocation = useLocation();
  const projectID = localStorage.getItem("projectID");
  const { site } = rlocation.state;
  const [projectName, setProjectName] = useState(site?.project_id);
  const [name, setName] = useState(site?.name);
  const [numberOfHouses, setNumberOfHouses] = useState(site?.no_houses);
  const [numberOfBlocks, setNumberOfBlocks] = useState(site?.no_blocks);
  const [financial_progress, setFinancialProgress] = useState(
    site?.financial_progress
  );
  const [startDate, setStartDate] = useState(site?.start_date?.split("T")[0]);
  const [endDate, setEndDate] = useState(site?.end_date?.split("T")[0]);
  const [area, setArea] = useState(site?.area);
  const [status, setStatus] = useState(site?.status);
  const [address, setAddress] = useState(site?.address);
  const [location, setLocation] = useState(site?.location);
  const [error, setError] = useState(false);
  const [fieldError, setFieldError] = useState(false);
  // const [loading, setLoading] = useState(false);
  // const [error, setError] = useState('')
  const [dateError, setDateError] = useState(false);
  const [projectList, setProjectList] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    // Fetch data from Django API
    axios
      .get("https://pms.dalyobt.com/api/view_project/")
      .then((response) => {
        // Set the data received from the API to the state
        setProjectList(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const filteredproject = projectList?.filter((project) => {
    return project.id !== parseInt(projectID);
  });

  const unfilteredproject = projectList?.find((project) => {
    return project?.id === parseInt(projectID);
  });

  // console.log(unfilteredproject?.id)

  const handleSend = async () => {
    const endResult = deadlineCalculator(endDate);
    if (endResult < 0) {
      setDateError(true);
    } else {
      if (name && startDate && endDate) {
        await axios
          .put(`https://pms.dalyobt.com/api/update_site/${id}/`, {
            name: name,
            area: area,
            no_blocks: numberOfBlocks,
            no_houses: numberOfHouses,
            financial_percentage: financial_progress,
            start_date: startDate,
            end_date: endDate,
            status: status,
            location: location,
            address: address,
            project_id: projectName,
          })
          .then((response) => {
            console.log(response.data);
            window.location.href = `/projects/${projectID}`;
          })
          .catch((error) => {
            console.error(error);
            setError(true);
            setFieldError(false);
          });
      } else {
        setError(true);
        setFieldError(true);
      }
    }
  };
  return (
    <div className="w-[100%] min-h-[91.5vh] flex flex-col  py-4  bg-white">
      <div className="w-[90%] mx-auto my-[30px] flex items-center gap-[30px]">
        <FaAngleLeft
          className="text-[30px] cursor-pointer text-[#0B72B5]"
          onClick={() => navigate("/projects/" + projectID)}
        />
        <span className="text-[20px] text-[#0B72B5] font-bold">Edit site</span>
      </div>
      {error && (
        <div className="w-[80%] mx-auto my-2 bg-red-500 text-center p-2 rounded-md">
          {fieldError ? (
            <p className="text-white font-medium">
              There are some missing fields.
            </p>
          ) : (
            <p className="text-white font-medium">
              Please insert the correct format.
            </p>
          )}
        </div>
      )}

      <div className="w-[80%] mt-[50px] mx-auto flex flex-col gap-[20px]">
        <div className="w-[40%]  flex flex-col items-start gap-[20px]">
          <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
            Change Project to
          </span>
          <div className="w-[100%] rounded-[5px]">
            <select
              onChange={(e) => setProjectName(e.target.value)}
              // value={unfilteredproject?.name}
              className="w-[100%] border border-[#ced4da]  p-4 rounded-[5px] text-[14px] outline-none"
            >
              <option>{unfilteredproject?.id}</option>
              {filteredproject?.map((fp) => {
                return (
                  <option key={fp?.id} value={fp?.id}>
                    {fp?.id}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="flex justify-between my-[20px] items-center">
          <div className="w-[40%]  flex flex-col  items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[30%] text-[#0B72B5]">
              Site Name <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Site-1"
                className="w-[100%] required border border-[#ced4da] py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>

          <div className="w-[40%]  flex flex-col  items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[30%] text-[#0B72B5]">
              Location
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="text"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                placeholder="Location"
                className="w-[100%] required border border-[#ced4da] py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>
        </div>

        <div className="flex justify-between my-[20px]">
          <div className="w-[40%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[30%] text-[#0B72B5]">
              Area
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="number"
                value={area}
                onChange={(e) => setArea(e.target.value)}
                placeholder="Area"
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>
          <div className="w-[40%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[30%] text-[#0B72B5]">
              Address
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                placeholder="Address"
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>
        </div>

        <div className="flex justify-between my-[20px]">
          <div className="w-[40%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[30%] text-[#0B72B5]">
              Financial Progress
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="number"
                value={financial_progress}
                onChange={(e) => setFinancialProgress(e.target.value)}
                placeholder="Area"
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>
          <div className="w-[40%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              Status
            </span>
            <div className="w-[100%] rounded-[5px]">
              <select
                onChange={(e) => setStatus(e.target.value)}
                className="w-[100%] border border-[#ced4da]  p-4 rounded-[5px] text-[14px] outline-none"
              >
                <option value={"ongoing"}>ongoing</option>
                <option value={"Not started"}>Not Started</option>
                <option value={"Completed"}>Completed</option>
              </select>
            </div>
          </div>
        </div>
        <div className="flex justify-between my-[20px]">
          <div className="w-[40%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[30%] text-[#0B72B5]">
              Start Date <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                placeholder="Ex. 2023-07-08"
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>
          <div className="w-[40%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[30%] text-[#0B72B5]">
              End Date <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                placeholder="Ex. 2023-07-08"
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="w-[80%] mt-[50px] mx-auto flex flex-col gap-[20px]">
        <div className="flex justify-between my-[20px]">
          <div className="w-[40%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[30%] text-[#0B72B5]">
              Number Of Houses
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="number"
                value={numberOfHouses}
                onChange={(e) => setNumberOfHouses(e.target.value)}
                placeholder="Number Of Houses"
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>
          <div className="w-[40%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[30%] text-[#0B72B5]">
              Number Of Blocks
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="number"
                value={numberOfBlocks}
                onChange={(e) => setNumberOfBlocks(e.target.value)}
                placeholder="Number Of Blocks"
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="w-[90%] mt-[30px] flex justify-end items-center gap-[20px]">
        <button onClick={() => navigate("/projects/" + projectID)}>
          Cancel
        </button>
        <button
          onClick={handleSend}
          className="py-2 px-4 text-[14px] cursor-pointer bg-[#0B72B5] text-white rounded-[10px]"
        >
          Edit Site
        </button>
      </div>
    </div>
  );
}

export default EditSite;
