import axios from "axios";
import React, { useEffect, useState } from "react";
// import { BsFillCloudUploadFill } from "react-icons/bs";
import { FaAngleLeft } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";

function UpdateAssignedContractor() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [error, setError] = useState(false);
  const [blockContra, setBlockContra] = useState([]);
  // const [listOfContra, setListOfContra] = useState([]);
  const [performanceFile, setPerformanceFile] = useState(null);
  const [advanceFile, setAdvanceFile] = useState(null);
  const [tradeLicense, setTradeLicense] = useState(null);
  const [tradeRegistration, setTradeRegistration] = useState(null);
  const [tinRegistration, setTinRegistration] = useState(null);
  const [vatRegistration, setVatRegistration] = useState(null);
  const [competencyRegistration, setCompetencyRegistration] = useState(null);
  const [pbStartDate, setPbStartDate] = useState("");
  const [pbEndDate, setPbEndDate] = useState("");
  const [agStartDate, setagStartDate] = useState("");
  const [agEndDate, setAgEndDate] = useState("");

  // console.log(id,"------------------------------------ID------------------------")
  useEffect(() => {
    axios
      .get(`https://pms.dalyobt.com/api/block_contractor/${id}/`)
      .then((response) => {
        console.log(response.data);
        setBlockContra(response.data);
      })
      .catch((error) => {
        console.error(error);
        setError(true);
      });
  }, [id]);

  // useEffect(()=>{
  //   axios.get('https://pms.dalyobt.com/api/contractor_view/').then((response)=>{
  //     setListOfContra(response.data)
  //   }).catch((error)=>{
  //     console.error(error)
  //     setError(true)
  //   })
  // },[])

  const filteredBlockContra = blockContra?.find((item) => item);

  // console.log(
  //   filteredBlockContra,
  //   "--------------------Block_Contra----------------------"
  // );

  useEffect(() => {
    if (filteredBlockContra) {
      setPerformanceFile(filteredBlockContra?.performance_bond || "");
      setAdvanceFile(filteredBlockContra?.advance_guarantee || "");
      setPbStartDate(filteredBlockContra?.pb_start_date || "");
      setPbEndDate(filteredBlockContra?.pb_end_date || "");
      setagStartDate(filteredBlockContra?.ag_start_date || "");
      setAgEndDate(filteredBlockContra?.ag_end_date || "");
      setTradeLicense(filteredBlockContra?.trade_license || "");
      setTradeRegistration(filteredBlockContra?.trade_registration || "");
      setTinRegistration(filteredBlockContra?.tin_registration || "");
      setVatRegistration(filteredBlockContra?.vat || "");
      setCompetencyRegistration(filteredBlockContra?.competency || "");
    }
  }, [filteredBlockContra]);

  // console.log(tradeLicense, tradeRegistration, tinRegistration, vatRegistration, competencyRegistration)

  const handleSend = async () => {
    // e.preventDefault();
    const formData = new FormData();
    
    if(filteredBlockContra?.performance_bond!==performanceFile){
      formData.append("performance_bond", performanceFile);
    }
    if(filteredBlockContra?.advance_guarantee!==advanceFile){
      formData.append("advance_guarantee", advanceFile);
    }
    if(filteredBlockContra?.trade_license!==tradeLicense){
      formData.append("trade_license", tradeLicense);
    }
    if(filteredBlockContra?.trade_registration!==tradeRegistration){
      formData.append("trade_registration", tradeRegistration);
    }
    if(filteredBlockContra?.tin_registration!==tinRegistration){
      formData.append("tin_registration", tinRegistration);
    }
    if(filteredBlockContra?.vat!==vatRegistration){
      formData.append("vat", vatRegistration);
    }
    if(filteredBlockContra?.competency!==competencyRegistration){
      formData.append("competency", competencyRegistration);
    }
    formData.append("pb_start_date", pbStartDate);
    formData.append("pb_end_date", pbEndDate);
    formData.append("ag_start_date", agStartDate);
    formData.append("ag_end_date", agEndDate);

    // console.log(tradeLicense, tradeRegistration, tinRegistration, vatRegistration, competencyRegistration)

    await axios
      .put(`https://pms.dalyobt.com/api/block_contractor/${id}/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        // console.log(response.data);
        navigate(-1);
      })
      .catch((error) => {
        console.error(error);
        setError(true);
      });
  };
  return (

    <div className="w-[100%] min-h-[91.5vh] flex flex-col  py-4  bg-white">
      <div className="w-[90%] mx-auto my-[30px] flex justify-between items-center">
        <div className=" flex items-center gap-[30px]">
          <FaAngleLeft
            className="text-[30px] cursor-pointer text-[#0B72B5]"
            onClick={() => navigate(-1)}
          />
          <span className="text-[20px] text-[#0B72B5] font-bold">
            Update Assigned Contractor
          </span>
        </div>
      </div>
      {error && (
        <div className="w-[80%] mx-auto my-2 bg-red-500 text-center p-2 rounded-md">
          <p className="text-white font-medium">
            Please Check the fields again there might be a missing field.
          </p>
        </div>
      )}
      <div className="w-[80%] mt-[50px] mx-auto flex flex-col gap-[20px]">
        <div className="flex items-center justify-between gap-[10px]"></div>

        <div className="flex justify-between my-[20px] gap-[10px]">
          <div className="w-[50%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              PB START DATE <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="date"
                value={pbStartDate}
                onChange={(e) => setPbStartDate(e.target.value)}
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>
          <div className="w-[50%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              PB END DATE <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="date"
                value={pbEndDate}
                onChange={(e) => setPbEndDate(e.target.value)}
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between my-[20px] gap-[10px]">
          <div className="w-[50%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              AG START DATE <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="date"
                value={agStartDate}
                onChange={(e) => setagStartDate(e.target.value)}
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>
          <div className="w-[50%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              AG END DATE <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="date"
                value={agEndDate}
                onChange={(e) => setAgEndDate(e.target.value)}
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>
        </div>

        <div className="flex justify-between my-[20px] gap-[10px]">
          <div className="w-[50%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              Performance Bond <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="file"
                id="perfomanceBondfileInput"
                // value={performanceFile?.name}
                onChange={(e) => setPerformanceFile(e.target.files[0])}
                // onChange={(e) => setStartDate(e.target.value)}
                // placeholder="AG START DATE"
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
              <label htmlFor="perfomanceBondfileInput" className="custom-file-input">
                {performanceFile ? performanceFile?.name : "Choose File"}
              </label>
            </div>
          </div>
       
          <div className="w-[50%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              Advance Gurantee <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="file"
                id="AdvanceGfileInput"
                onChange={(e) => setAdvanceFile(e.target.files[0])}
                // placeholder=" AG END DATE"
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
              <label htmlFor="AdvanceGfileInput" className="custom-file-input">
                {advanceFile ? advanceFile?.name : "Choose File"}
              </label>
            </div>
          </div>
        </div>

        <div className="flex justify-between my-[20px] gap-[10px]">
          <div className="w-[50%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              Trade License <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="file"
                id="TradeLicensefileInput"
                // value={performanceFile?.name}
                onChange={(e) => setTradeLicense(e.target.files[0])}
                // onChange={(e) => setStartDate(e.target.value)}
                // placeholder="AG START DATE"
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
              <label htmlFor="TradeLicensefileInput" className="custom-file-input">
                {tradeLicense ? tradeLicense?.name : "Choose File"}
              </label>
            </div>
          </div>
          <div className="w-[50%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              Trade Registration <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="file"
                id="TradeRegistrationfileInput"
                onChange={(e) => setTradeRegistration(e.target.files[0])}
                // placeholder=" AG END DATE"
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
              <label htmlFor="TradeRegistrationfileInput" className="custom-file-input">
                {tradeRegistration ? tradeRegistration?.name : "Choose File"}
              </label>
            </div>
          </div>
        </div>

        <div className="flex justify-between my-[20px] gap-[10px]">
          <div className="w-[50%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              TIN Registration <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="file"
                id="TinRegistrationfileInput"
                // value={performanceFile?.name}
                onChange={(e) => setTinRegistration(e.target.files[0])}
                // onChange={(e) => setStartDate(e.target.value)}
                // placeholder="AG START DATE"
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
              <label htmlFor="TinRegistrationfileInput" className="custom-file-input">
                {tinRegistration ? tinRegistration?.name : "Choose File"}
              </label>
            </div>
          </div>
          <div className="w-[50%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              VAT <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="file"
                id="VatRegistrationfileInput"
                onChange={(e) => setAdvanceFile(e.target.files[0])}
                // placeholder=" AG END DATE"
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
              <label htmlFor="VatRegistrationfileInput" className="custom-file-input">
                {vatRegistration ? vatRegistration?.name : "Choose File"}
              </label>
            </div>
          </div>
        </div>

        <div className="flex justify-between my-[20px] gap-[10px]">
          <div className="w-[50%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              Competency <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="file"
                id="CompetencyfileInput"
                // value={performanceFile?.name}
                onChange={(e) => setCompetencyRegistration(e.target.files[0])}
                // onChange={(e) => setStartDate(e.target.value)}
                // placeholder="AG START DATE"
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
              <label htmlFor="CompetencyfileInput" className="custom-file-input">
                {competencyRegistration
                  ? competencyRegistration?.name
                  : "Choose File"}
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="w-[90%] mt-[30px] flex justify-end items-center gap-[20px]">
        <button onClick={() => navigate(-1)}>Cancel</button>
        <button
          className="py-2 px-4 text-[14px] bg-[#0B72B5] text-white rounded-[10px]"
          onClick={handleSend}
        >
          Update Contractor
        </button>
      </div>
    </div>
  );
}

export default UpdateAssignedContractor;
