import axios from "axios";
import React, { useState } from "react";
import { FaAngleLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

function AddContractor() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [address, setAddress] = useState("");
  const [tinNum, setTinNum] = useState("");
  const [vatReg, setVatReg] = useState("");
  const [phone, setPhone] = useState("");
  const [error, setError] = useState(false);

  const navigate = useNavigate();

  const handleSend = async () => {
    if (
      name !== "" &&
      contactPerson !== "" &&
      address !== "" &&
      tinNum !== "" &&
      vatReg !== "" &&
      phone !== ""
    ) {
      await axios
        .post("https://pms.dalyobt.com/api/contractor_view/", {
          name: name,
          email: email,
          contact_person: contactPerson,
          address: address,
          tin_no: tinNum,
          Vat_Reg_no: vatReg,
          phone: phone,
        })
        .then((response) => {
          // console.log(response)
          navigate("/preconstruction");
        })
        .catch((error) => {
          console.error(error);
          setError(true);
        });
    }
    else{
      setError(true)
    }
  };

  return (
    <div className=" w-[80%] mx-auto min-h-[600px] mt-7 my-[20px] flex flex-col justify-center py-4  bg-white shadow-xl rounded-[10px]">
      <div className="w-[90%] mx-auto my-[30px] flex items-center gap-[30px]">
        <FaAngleLeft
          className="text-[30px] cursor-pointer text-[#0B72B5]"
          onClick={() => navigate("/preconstruction")}
        />
        <span className="text-[20px] text-[#0B72B5] font-bold">
          Add Contractor
        </span>
      </div>

      {error && <div className="w-[80%] mx-auto my-2 bg-red-500 text-center p-2 rounded-md">
        <p className="text-white font-medium">Please Check the fields again there might be a missing field.</p>
      </div>}

      <div className="w-[80%] mt-[50px] mx-auto flex flex-col gap-[20px]">
        <div className="flex justify-between my-[20px] items-center">
          <div className="w-[40%]  flex flex-col  items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[100%] text-[#0B72B5]">
              Contractor Name <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="text"
                onChange={(e) => setName(e.target.value)}
                placeholder="Name"
                className="w-[100%] required border border-[#ced4da] py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>

          <div className="w-[40%]  flex flex-col  items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[100%] text-[#0B72B5]">
              Email 
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="smtg@email.com"
                className="w-[100%] required border border-[#ced4da] py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>
        </div>

        <div className="flex justify-between my-[20px]">
          <div className="w-[40%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[100%] text-[#0B72B5]">
              Contact Person <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="text"
                onChange={(e) => setContactPerson(e.target.value)}
                placeholder="Abebe, Kebede"
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>
          <div className="w-[40%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[100%] text-[#0B72B5]">
              Address <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="text"
                onChange={(e) => setAddress(e.target.value)}
                placeholder="Address"
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between my-[20px]">
          <div className="w-[40%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[100%] text-[#0B72B5]">
              Tin Number <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="text"
                onChange={(e) => setTinNum(e.target.value)}
                placeholder="Tin Number"
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>
          <div className="w-[40%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[100%] text-[#0B72B5]">
              Vat Register Number <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="text"
                onChange={(e) => setVatReg(e.target.value)}
                placeholder="Vat Register Number"
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>
        </div>

        <div className="flex justify-between my-[20px]">
          <div className="w-[40%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[100%] text-[#0B72B5]">
              Phone <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="text"
                onChange={(e) => setPhone(e.target.value)}
                placeholder="Tin Number"
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>
        </div>

      </div>

      <div className="w-[90%] mt-[30px] flex justify-end items-center gap-[20px]">
        <button onClick={() => navigate("/preconstruction")}>Cancel</button>
        <button
          onClick={handleSend}
          className="py-2 px-4 text-[14px] cursor-pointer bg-[#0B72B5] text-white rounded-[10px]"
        >
          Add Contractor
        </button>
      </div>
    </div>
  );
}

export default AddContractor;
