import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaAngleLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

function AddStaff() {
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const [position, setPosition] = useState("");
  const [error, setError] = useState(false);
  const [passError, setPassError] = useState("");
  //   const [roleError, setRoleError] = useState("");
  const [options, setOption] = useState([]);
  const [grps, setGrps] = useState([]);
  const userGroup = localStorage.getItem("userGroup");

  useEffect(() => {
    if (userGroup === "1") setOption(["Senior Engineer", "Junior Engineer"]);
    else if (userGroup === "5")
      setOption([
        "Executive 1",
        "Executive 2",
        "Executive 3",
        "project manager",
      ]);
    else {
      setOption([]);
    }
  }, [userGroup]);

  useEffect(() => {
    axios
      .get("https://pms.dalyobt.com/api/groups/")
      .then((response) => {
        setGrps(response.data);
      })
      .catch((error) => {
        console.error("Error while fetching", error);
      });
  }, [role]);

  //   const groupRole = grps.find((item)=>item?.name==="Executive 1")?.id
  //   console.log(groupRole)

  // console.log(role,"Role")

  const handleCreate = async () => {
    if (role && position && phone) {
      if (password.length >= 8) {
        // console.log(grps?.find((item)=>item?.name===role)?.id)
        try {
          const response = await axios.post(
            "https://pms.dalyobt.com/api/create-user/",
            {
              username: username,
              password: password,
              email: email,
              //   groups: grps?.find((item)=>item?.name===role)?.id,
            }
          );

          if (response.status === 201) {
            const userId = response?.data?.id;

            if (userId) {
              try {
                await axios
                  .post("https://pms.dalyobt.com/api/position_view/", {
                    name: position,
                    user: userId,
                  })
                  .then((res) => {
                    const groupId = grps?.find(
                      (item) => item?.name === role
                    )?.id;
                    if (res.status === 201) {
                      axios
                        .post(
                          `https://pms.dalyobt.com/api/add-user-to-group/${userId}/`,
                          {
                            group: groupId,
                          }
                        )
                        .then((re) => {
                          // console.log(re.data);
                          // navigate("/staff");
                          if (re.status === 200) {
                            axios
                              .post(
                                "https://pms.dalyobt.com/api/user_phone_num/",
                                {
                                  user: userId,
                                  phone: phone,
                                }
                              )
                              .then((responseLast) => {
                                console.log(responseLast.data);
                                navigate("/staff");
                              })
                              .catch((error) => {
                                setError(true);
                                console.error(error);
                              });
                          }
                        })
                        .catch((error) => {
                          setError(true);
                          //   setRoleError("");
                          setPassError("");
                          console.error(error);
                        });
                    }
                  });

                //   console.log(resp.data);
              } catch (error) {
                setError(true);
                // setRoleError("");
                setPassError("");
                console.error(error);
              }
            } else {
              setError(true);
              setPassError("");
              //   setRoleError("");
            }
          }
        } catch (error) {
          setError(true);
          setPassError("");
          //   setRoleError("");
          console.error(error);
        }
      } else {
        setError(true);
        // setRoleError("");
        setPassError(
          "Please set your password correctly. At least 8 characters are required."
        );
      }
    } else {
      setError(true);
      //   setRoleError("Please set your role and positions correctly.");
    }
  };

  //   const handleCreate = async () => {
  //     if (password.length >= 8) {
  //       await axios
  //         .post("https://pms.dalyobt.com/api/create-user/", {
  //           username: username,
  //           password: password,
  //           email: email,
  //           groups: groupRole?.id,
  //         })
  //         .then((response) => {
  //           if (response.status.toString() === "201") {
  //             axios
  //               .post("https://pms.dalyobt.com/api/position_view/", {
  //                 name: position,
  //                 user: response?.data?.id,
  //               })
  //               .then((resp) => {
  //                 console.log(resp.data);
  //               })
  //               .catch((error) => {
  //                 setError(true);
  //                 setPassError("");
  //                 console.error(error);
  //               });
  //           }
  //         })
  //         .catch((error) => {
  //           setError(true);
  //           setPassError("");
  //           console.error(error);
  //         });
  //     } else {
  //       setError(true);
  //       setPassError(
  //         "Please set your password correctly atleast 8 characters are required."
  //       );
  //     }
  //   };

  return (
    <div className="w-[100%] min-h-[91.5vh] flex flex-col  py-4  bg-white">
      <div className="w-[90%] mx-auto my-[30px] flex justify-between items-center">
        <div className=" flex items-center gap-[30px]">
          <FaAngleLeft
            className="text-[30px] cursor-pointer text-[#0B72B5]"
            onClick={() => navigate("/staff")}
          />
          <span className="text-[20px] text-[#0B72B5] font-bold">
            Add new Staff
          </span>
        </div>
        {/* <div>
            {loading && <p>Loading...</p>}
        </div> */}
      </div>

      {/* {roleError && (
        <div className="w-[80%] mx-auto my-2 bg-red-500 text-center p-2 rounded-md">
          {roleError ? (
            <p className="text-white font-medium">{roleError}</p>
          ) : ''}
        </div>
      )} */}

      {error && (
        <div className="w-[80%] mx-auto my-2 bg-red-500 text-center p-2 rounded-md">
          {passError ? (
            <p className="text-white font-medium">{passError}</p>
          ) : (
            <p className="text-white font-medium">
              Please Check the fields again there might be a missing field or
              wrongly filled fields.
            </p>
          )}
        </div>
      )}

      <div className="w-[80%] mt-[50px] mx-auto flex flex-col gap-[20px]">
        <div className="flex items-center justify-between gap-[10px]">
          <div className="w-[50%]  flex flex-col  items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[30%] text-[#0B72B5]">
              Username <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="text"
                placeholder="Mesfin231, DagmawiM12"
                onChange={(e) => setUsername(e.target.value)}
                className="w-[100%] border border-[#ced4da] py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>
          <div className="w-[50%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[30%] text-[#0B72B5]">
              Email
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="email"
                placeholder="ex. name_staff@gmail.com"
                onChange={(e) => setEmail(e.target.value)}
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>
        </div>

        <div className="flex justify-between gap-[10px] my-[20px]">
          <div className="w-[50%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[100%] text-[#0B72B5]">
              Password{" "}
              <span className="text-red-700">
                *{" "}
                <span className="text-gray-400 text-[12px]">
                  Atleast 8 characters are required
                </span>
              </span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="password"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                className={
                  passError
                    ? "w-[100%] border border-red-700 py-3 px-4 rounded-[5px] text-[14px] outline-none"
                    : "w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
                }
              />
            </div>
          </div>

          <div className="w-[50%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[30%] text-[#0B72B5]">
              Role <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <select
                onChange={(e) => setRole(e.target.value)}
                className="w-[100%] border border-[#ced4da]  p-4 rounded-[5px] text-[14px] text-gray-600 outline-none"
              >
                {options.map((item, index) => {
                  return <option key={index}>{item}</option>;
                })}
                {/* <option value={"Executive 1"}>Executive 1</option>
                <option value={"Executive 2"}>Executive 2</option>
                <option value={"Executive 3"}>Executive 3</option>
                <option value={"project manager"}>Project Manager</option>
                <option value={"Senior Engineer"}>Senior Engineer</option>
                <option value={"Junior Engineer"}>Junior Engineer</option> */}
              </select>
            </div>
          </div>
        </div>

        <div className="flex justify-between gap-[10px] my-[20px]">
          <div className="w-[50%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[30%] text-[#0B72B5]">
              Type of Position <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="text"
                placeholder="Mechanical Engineer, Hydraulic Eng"
                onChange={(e) => setPosition(e.target.value)}
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>

          <div className="w-[50%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[30%] text-[#0B72B5]">
              Phone <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="text"
                placeholder="+251911111213"
                onChange={(e) => setPhone(e.target.value)}
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="w-[90%] mt-[30px] flex justify-end items-center gap-[20px]">
        <button onClick={() => navigate("/staff")}>Cancel</button>
        <button
          onClick={handleCreate}
          className="py-2 px-4 text-[14px] bg-[#0B72B5] text-white rounded-[10px]"
        >
          Create Staff
        </button>
      </div>
    </div>
  );
}

export default AddStaff;
