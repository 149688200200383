import React from "react";
import Navbar from "./Navbar";
import Dashboard from "./Dashboard";
import { useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import Order from "./Order";
import Project from "./Projects/Project";
import Projects from "./Projects/Projects";
import Sites from "./Projects/Sites";
import Contractor from "./Contractors/Contractor";
import ProjectManager from "./ProjectManager/ProjectManager";
import ProjectOverview from "./Projects/ProjectOverview";
import AddProject from "./Projects/AddProject";
import AddSite from "./Projects/AddSite";
import Building from "./Projects/Building";
import BOQAmendment from "./Projects/BOQ/BOQAmendment";
import ProgressReport from "./Projects/ProgressReport";
import PreConstruction from "./PreConstruction/PreConstruction";
import AddContractor from "./PreConstruction/AddContractor";
import AddSubContractor from "./Projects/AddSubContractor";
import Staff from "../components/Staff/Staff";
import AddStaff from "../components/Staff/AddStaff";
import Notification from "./Notification";
import UserProfile from "./UserProfile/UserProfile";
import UpdateAssignedContractor from "./Contractors/UpdateAssignedContractor";
import ProfileStatusUpdate from "./Contractors/ProfileStatusUpdate";
import AddNewBOQ from "./Projects/BOQ/AddNewBOQ";
import EditProject from "./Projects/EditProject";
import EditSite from "./Projects/EditSite";
import PostConstruction from "./PostConstruction.js/PostConstruction";
import FinalHandover from "./PostConstruction.js/FinalHandover";
import AddFinalHandover from "./PostConstruction.js/AddFinalHandover";
import Retention from "./PostConstruction.js/Retention";
import AddRetention from "./PostConstruction.js/AddRetention";
import PerformanceBond from "./PostConstruction.js/PerformanceBond";
import AddPerformaceBond from "./PostConstruction.js/AddPerformaceBond";
import Branch from "./Branch/Branch";
import SiteOverview from "./Projects/SiteOverview";
import BranchList from "./Branch/BranchList";
import CreateBranch from "./Branch/CreateBranch";
import EditBranch from "./Branch/EditBranch";
import ProjectAnalytics from "./Projects/ProjectAnalytics";
function Content() {
  const selectState = useSelector((state) => state.sideBar.sidebarClosed);
  const userGroup = localStorage.getItem("userGroup");
  return (
    <div
      className={
        !selectState
          ? "w-[95%] max-[1110px]:w-[90%] mx-auto absolute top-0 right-0 flex-col flex-1 transition-[width] duration-700"
          : "w-[80%] absolute top-0  right-0  flex-col flex-1 transition-[width] duration-700"
      }
    >
      <Navbar />
      <Routes>
        {userGroup?.toString() === "3" ? (
          <Route path="/" element={<Projects />} />
        ) : (
          // <Route path="/" element={<Dashboard />} />
          <Route path="/" element={<Branch />} />
        )}
        {/* <Route path="/branch" element={<Branch />}/> */}
        <Route path ="/branchlist" element={<BranchList />}/>
        <Route path="/branch/create-branch" element={<CreateBranch />}/>
        <Route path="/branch/edit/:id" element={<EditBranch />}/>
        <Route path="/branch/projects/:id" element={<Projects />} />
        <Route path="/branch/project/analytics/:id" element={<ProjectAnalytics />}/>
        <Route path="/projects/:id" element={<Project />} />
        <Route path="/projects/sites/:id" element={<Sites />} />
        <Route path="/projects/sites/edit/:id" element={<EditSite />} />
        <Route path="/projectoverview/:id" element={<ProjectOverview />} />
        <Route path="/siteoverview/:id" element={<SiteOverview />}/>
        <Route path="/projects/addproject/:id" element={<AddProject />} />
        <Route path="/projects/edit/:id" element={<EditProject />} />
        <Route path="/projects/project/addsite" element={<AddSite />} />
        <Route path="/projects/sites/buildings/:id" element={<Building />} />
        <Route
          path="/projcts/sites/buildings/boqamendment"
          element={<BOQAmendment />}
        />
        {userGroup?.toString() === "1" || userGroup?.toString() === "5" ? (
          <Route path="/staff" element={<Staff />} />
        ) : (
          <Route path="/preconstruction" element={<PreConstruction />} />
        )}
        {userGroup?.toString() === "1" || userGroup?.toString() === "5" ? (
          <Route path="/staff/addStaff" element={<AddStaff />} />
        ) : (
          <Route path="/preconstruction" element={<PreConstruction />} />
        )}
        <Route path="/order" element={<Order />} />
        <Route path="/projectmanager" element={<ProjectManager />} />
        <Route path="/contractor" element={<Contractor />} />
        <Route path="/progressreport" element={<ProgressReport />} />
        <Route path="/preconstruction" element={<PreConstruction />} />
        <Route
          path="/preconstruction/addcontractor"
          element={<AddContractor />}
        />
           <Route path="/postconstruction" element={<PostConstruction />} />
       
        <Route
          path="/projects/sites/addsubcontractor/:block_id"
          element={<AddSubContractor />}
        />
        <Route path="/notification" element={<Notification />} />
        <Route path="/contractor" element={<Contractor />} />
        <Route
          path="/updateassignedcontractor/:id"
          element={<UpdateAssignedContractor />}
        />
        <Route
          path="/updatecontractorstatus/:id"
          element={<ProfileStatusUpdate />}
        />
        <Route path="/myprofile" element={<UserProfile />} />
        <Route
          path="/projects/sites/buildings/addNewBOQ/:id"
          element={<AddNewBOQ />}
        />
         <Route path="/postconstruction/finalhandover/addfhandover/:id" element={<AddFinalHandover />} />
        <Route path="/postconstruction/finalhandover/:id" element={<FinalHandover />} />
        <Route path="/postconstruction/retention/:id" element={<Retention />}/>
        <Route path="/postconstruction/retention/addretention/:id" element={<AddRetention />} />
        <Route path="/postconstruction/performancebond/:id" element={<PerformanceBond />} />
        <Route path="/postconstruction/performancebond/addperformancebond/:id" element={<AddPerformaceBond />} />
      </Routes>
    </div>
  );
}

export default Content;
