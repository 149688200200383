// import React, { useState } from 'react'
// import {AiOutlineLock,AiOutlineMail} from 'react-icons/ai'
// import {Button} from '@mui/material'
// import {useNavigate} from 'react-router-dom'
// import axios from 'axios';

// function LoginContainer({
//   toogleSignIn,
//   settoggleSignIn,
// //   username,
// //   password,
//   setUsername,
//   setPassword,
// //   handleLogin,
// //   error,
// }) {
//     // const [password,setPassword] = useState('')
//     const [error, setError] = useState('');
//     const navigate = useNavigate()

//     const handleSubmit = (e)=>{
//         e.preventDefault();
//         // navigate('/')
//         handleLogin();

//     }

//   return (
//     <div className='w-full h-[100vh] flex justify-center items-center'>
//     <div className='w-[50%] h-[70%] bg-white rounded-[45px] shadow-xl flex gap-[10px]'>
//         <div className='w-[50%] h-[100%] bg-[#0B72B5] rounded-tl-[45px] rounded-bl-[45px] '>
//         <div className='w-[90%] h-[65%] mx-auto  flex flex-col items-center justify-around'>

//        <div className='relative  bg-white rounded-full mt-[20px]'>

//       <img src={require('../Images/logo_crop.jpg')} alt="" className='w-[150px] h-[150px] rounded-full object-cover'/>

//        </div>

//                 <div className='text-center text-white w-[80%]'>
//                     <span className='text-[24px] font-bold'>Welcome</span>
//                     <p className='text-[12px] my-[10px]'>To stay connected login back to your account with your credentials.</p>

//                 </div>
//                 <div className='w-[60%]'>
//                 <Button onClick={()=>settoggleSignIn(!toogleSignIn)}  sx={{width:"100%", border:'1px solid white', color:"white",borderRadius:'10px'}}>Sign Up</Button>
//                 </div>
//         </div>

//         </div>
//         <div className='w-[70%] h-[100%] flex flex-col gap-[20px] justify-center items-center'>

//                 <div className='  flex flex-col items-center gap-[10px]'>
//                     <span className='text-[30px] font-bold'>Welcome Back!</span>
//                     <span className='text-[14px]'>Login to your account.</span>
//                     <div className='w-[50%] h-[2px] bg-[#adadad] flex justify-end items-center'>
//                         <div className='w-[30%] h-[4px] bg-[#FDC00D]'></div>
//                     </div>
//                 </div>
//                 <div className='w-[80%] mt-[50px]  flex flex-col items-center gap-[10px]  '>
//                     <div className='w-[70%]  flex items-center justify-between gap-[10px]  bg-[#f0f0f0] p-[15px]  rounded-[5px] border border-[#FDC00D]'>
//                     <input type='email' placeholder='Email' className='bg-transparent text-[14px] text-gray-400 flex-auto outline-none' />
//                     <AiOutlineMail className='text-gray-600' />

//                     </div>
//                     <div className='w-[70%]  flex items-center justify-between gap-[10px]  bg-[#f0f0f0] p-[15px]  rounded-[5px]  border border-[#FDC00D]'>
//                     <input type='password' onChange={(e)=>setPassword(e.target.value)}  placeholder='Password' className='bg-transparent text-[14px] text-gray-400 flex-auto outline-none' />
//                    {!password ? <AiOutlineLock className={'block text-gray-600'} />:null}

//                     </div>

//                 </div>
//                 <div className='w-[55%]'>
//                <button type='submit' onClick={handleLogin} className='w-[100%] bg-[#FDC00D] text-white p-[0.7rem] rounded-[10px] text-[14px]'>Sign In</button>
//                 </div>
//                 <div className='w-[55%] mx-auto flex justify-between text-[11px] text-[#777777] '>
//                     <div className='flex items-center gap-[10px]'>

//                             <input type='checkbox' />
//                             <span>Remember Me</span>

//                     </div>
//                     <div>
//                         <a href='/login' >Forgot your password?</a>
//                     </div>

//                 </div>

//         </div>

//     </div>

// </div>
//   )
// }

// export default LoginContainer

import React, { useState } from "react";
import { AiOutlineLock, AiOutlineMail } from "react-icons/ai";
// import { Button } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
import axios from "axios";
// import bcrypt from 'bcryptjs'

function LoginContainer({
  toogleSignIn,
  settoggleSignIn,
  username,
  password,
  setUsername,
  setPassword,
  // handleLogin,
  // error,
}) {
  const [error, setError] = useState("");
  // const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post("https://pms.dalyobt.com/api/login/", {
        username,
        password,
      });
      // console.log(response.data)

      if (response.status === 200) {
        // Authentication successful
        const token = response.data.token;
        const username = response.data.user_name;
        const userID = response.data.user_id;
        const userGroup = response.data.group[0];

        // const salt = bcrypt.genSalt(10)
        // const hashedPassword = bcrypt.hash(token,salt);
        localStorage.setItem("token", token);
        localStorage.setItem("username", username);
        localStorage.setItem("userID", userID);
        localStorage.setItem("userGroup", userGroup);
        // localStorage.setItem('username',response.data.)
        window.location.href = "/";
        // } else {
        //   // Authentication failed
        //   // console.log('Authentication failed');
        //   // setError('Authentication failed');
        //   setError(response.data);
      }
    } catch (error) {
      // console.log('Error occurred:', error);
      setError("Error occurred. Please try again.");
    }
  };

  return (
    <div className="w-full min-h-[100vh] flex justify-center items-center">
      <div className="w-[50%] h-[700px] my-[50px] bg-white rounded-[45px] shadow-xl flex gap-[10px] max-[1300px]:w-[80%] max-[900px]:w-[80%] max-[800px]:justify-center">
        <div className="w-[50%] h-[100%]  bg-[#0B72B5] rounded-tl-[45px] rounded-bl-[45px] max-[800px]:hidden ">
          <div className="w-[90%] h-[70%] mx-auto  flex flex-col items-center justify-center gap-[50px]">
            <div className="relative  bg-white rounded-full mt-[20px]">
              <img
                src={require("../Images/HousingAdmin.png")}
                alt=""
                className="w-[150px] h-[150px] rounded-full object-cover"
              />
            </div>

            <div className="text-center text-white w-[80%]">
              <span className="text-[24px] font-bold">Welcome</span>
              <p className="text-[12px] my-[10px]">
                To stay connected login back to your account with your
                credentials.
              </p>
            </div>
            {/* <div className='w-[60%]'>
              <Button
                onClick={() => settoggleSignIn(!toogleSignIn)}
                sx={{ width: '100%', border: '1px solid white', color: 'white', borderRadius: '10px' }}
              >
                Sign Up
              </Button>
            </div> */}
          </div>
        </div>
        <div className="w-[70%] h-[100%] flex flex-col gap-[20px] justify-center items-center max-[800px]:w-[100%]">
        <div className="relative  bg-white rounded-full mt-[20px]">
              <img
                src={require("../Images/housingLogo.png")}
                alt=""
                className="w-[150px] h-[150px] border rounded-full object-cover"
              />
            </div>
          <div className="flex flex-col items-center gap-[10px]">
            
            <span className="text-[30px] font-bold">Welcome Back!</span>
            <span className="text-[14px]">Login to your account.</span>
            <div className="w-[50%] h-[2px] bg-[#adadad] flex justify-end items-center">
              <div className="w-[30%] h-[4px] bg-[#FDC00D]"></div>
            </div>
          </div>

          {error && (
            <div className="w-[80%] mx-auto my-2 bg-red-500 text-center p-2 rounded-md">
              <p className="text-white font-medium">
                Wrong username or password. Please try again.
              </p>
            </div>
          )}

          <div className="w-[80%] mt-[50px]  flex flex-col items-center gap-[10px] max-[800px]:mt-[20px] ">
            <div className="w-[70%]  flex items-center justify-between gap-[10px]  bg-[#f0f0f0] p-[15px]  rounded-[5px] border border-[#FDC00D]">
              <input
                type="email"
                placeholder="Username"
                className="bg-transparent text-[14px] text-gray-400 flex-auto outline-none"
                onChange={(e) => setUsername(e.target.value)}
                required
              />
              <AiOutlineMail className="text-gray-600" />
            </div>
            <div className="w-[70%]  flex items-center justify-between gap-[10px]  bg-[#f0f0f0] p-[15px]  rounded-[5px]  border border-[#FDC00D]">
              <input
                type="password"
                placeholder="Password"
                className="bg-transparent text-[14px] text-gray-400 flex-auto outline-none"
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              {!password ? (
                <AiOutlineLock className={"block text-gray-600"} />
              ) : null}
            </div>
          </div>
          <div className="w-[55%]">
            <button
              type="submit"
              onClick={handleLogin}
              className="w-[100%] bg-[#FDC00D] text-white p-[0.7rem] rounded-[10px] text-[14px]"
            >
              Sign In
            </button>
          </div>
          {/* <div className="w-[55%] mx-auto flex justify-between text-[11px] text-[#777777] ">
            <div className="flex items-center gap-[10px]">
              <input type="checkbox" />
              <span>Remember Me</span>
            </div>
            {/* <div>
              <a href="/login">Forgot your password?</a>
            </div> */}
          {/* </div>  */}

          {/* {error && <p>{error}</p>} */}
        </div>
      </div>
    </div>
  );
}

export default LoginContainer;
