import axios from "axios";
import React, { useEffect, useState } from "react";
import { AiFillPrinter } from "react-icons/ai";
import { Link, useParams } from "react-router-dom";

function FinalHandoverTable() {
  const { id } = useParams();
  const [finalList, setFinalList] = useState([]);
  const [listOfBlock, setListOfBlock] = useState([]);

  useEffect(() => {
    axios
      .get(`https://pms.dalyobt.com/api/final_hand_over/${id}/`)
      .then((response) => {
        setFinalList(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [id]);

  useEffect(() => {
    axios
      .get("https://pms.dalyobt.com/api/view_block/")
      .then((response) => {
        setListOfBlock(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handlePrint = () => {
    window.print();
  };
  return (
    <div className="w-[100%] mx-auto relative overflow-x-auto  sm:rounded-l">
      <div className="w-[100%]  mx-auto flex justify-end items-center">
        <button
          className="bg-[#0B72B5] flex gap-[10px] items-center  py-2 px-4 text-white text-[12px] rounded-[5px] mb-4"
          onClick={handlePrint}
        >
          <AiFillPrinter />
          <span>Print</span>
        </button>
      </div>

      <table className="w-full overflow-x-auto text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-center text-gray-700 uppercase border-b border-gray-300">
          <tr>
            <th scope="col" className="px-6 py-3">
              Block
            </th>
            {/* <th scope="col" className="px-6 py-3">
              Project
            </th>
            <th scope="col" className="px-6 py-3">
              Site
            </th> */}
            <th scope="col" className="px-6 py-3">
              Attachment
            </th>
            <th scope="col" className="px-6 py-3">
              Prepared By
            </th>
            <th scope="col" className="px-6 py-3">
              Approved By
            </th>
            <th scope="col" className="px-6 py-3">
              Date
            </th>
            <th scope="col" className="px-6 py-3">
              Status
            </th>

            {/* {userGroup?.toString() === "1" && (
            <th scope="col" className="px-6 py-3">
              Action
            </th>
          )} */}
          </tr>
        </thead>
        <tbody>
          {finalList &&
            finalList?.map((item) => {
              return (
                <tr
                  key={item?.id}
                  className={
                    "text-center text-gray-600 border-b border-gray-300"
                  }
                >
                  <td className={"px-6 py-4"}>
                    <Link
                      to={`/postconstruction/retention/${item?.block}`}
                      className="text-[#0B72B5] font-bold"
                    >
                      {
                        listOfBlock?.find(
                          (it) => it?.id?.toString() === item?.block?.toString()
                        )?.name
                      }
                    </Link>
                  </td>
                  {/* <td className={"px-6 py-4"}>
                    <span>Project Try 1</span>
                  </td>
                  <td className={"px-6 py-4"}>
                    <span>Site-1</span>
                  </td> */}

                  <td className="p-1">
                    <a
                      href={`https://pms.dalyobt.com${item?.attachment}`}
                      download
                    >
                      <span className="text-[#0B72B5] underline p-2 cursor-pointer text-[12px] rounded-[5px]">
                        {item?.attachment}
                      </span>
                    </a>
                  </td>
                  <td className={"px-6 py-4"}>
                    <span>{item?.prepared_by}</span>
                  </td>
                  <td className={"px-6 py-4"}>
                    <span>{item?.approved_by ? item?.approved_by : "-"}</span>
                  </td>
                  <td className={"px-6 py-4"}>
                    <span>{item?.final_handover_date}</span>
                  </td>
                  <td className={"px-6 py-4"}>
                    {item?.status === false ? (
                      <span className="bg-[#FDC00D] rounded-[20px] text-[12px] text-white p-2">
                        Pending
                      </span>
                    ) : (
                      <span className="bg-green-500 rounded-[20px] text-[12px] text-white p-2">
                        Fulfilled
                      </span>
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      {finalList?.length === 0 && (
        <div className="w-[100%] my-[20px] flex justify-center items-center">
          {" "}
          <span className="text-gray-600 font-bold ">
            No Final Handover Available
          </span>
        </div>
      )}
    </div>
  );
}

export default FinalHandoverTable;
