import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
} from "recharts";

function SiteBar({ project }) {
  const width = window.innerWidth;
  const [progressFill, setProgressFill] = useState([]);

  const siteIdentification = project?.map((item)=>item?.id)

  useEffect(() => {
    // Create an array of axios requests
    const axiosRequests = siteIdentification.map((item) => {
      return axios.get(`https://pms.dalyobt.com/api/calculate_total_site_progress/${item}/`);
    });
  
    // Use Promise.all to wait for all requests to resolve
    Promise.all(axiosRequests)
      .then((responses) => {
        // responses is an array of response data from all requests
        const progressData = responses.map((response) => response.data);
        setProgressFill(progressData);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);  

  // console.log(progressFill, "-------------------------------------PROGRESS----------------------------------------")
  // console.log(siteIdentification)
  // console.log(project)

  return (
    <ResponsiveContainer
      width={width < 1000 ? "100%" : "90%" || width}
      height="80%"
    >
      <BarChart
        width={500}
        height={300}
        data={project}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <XAxis dataKey="name" />
        <YAxis type="number" domain={[0, 100]} />
        <Tooltip />
        <Legend />
        {/* {financial} */}
        <Bar dataKey="financial_progress" fill="#FDC00D" />
        <Bar dataKey="progress" fill="#ffe79e" />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default SiteBar;
