import React, { useEffect, useState } from "react";
import axios from "axios";
import {useNavigate, useParams } from "react-router-dom";
import { AiFillWarning } from "react-icons/ai";

function Table({ search }) {
  const userGroup = localStorage.getItem("userGroup");
  const navigate = useNavigate();
  const {id} = useParams();
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    // Fetch data from Django API
    axios
      .get(`https://pms.dalyobt.com/api/view_project/${id}`)
      .then((response) => {
        // Set the data received from the API to the state
        setProjects(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [id]);

  // const handleDelete = async (project_id) => {
  //   await axios
  //     .delete(`https://pms.dalyobt.com/api/delete_project/${project_id}`)
  //     .then((response) => {
  //       console.log(response.data);

  //       window.location.href = "/Projects";
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data:", error);
  //     });
  // };

  const filteredProject = projects?.filter((p) => {
    return (
      p?.name?.includes(search) ||
      p?.name?.includes(search.toUpperCase()) ||
      p?.name?.includes(search.toLowerCase())
    );
  });

 


  const deadlineCalculator = (newDate) => {
    const d = new Date(newDate);
    const date2 = new Date();
    var time_difference = d?.getTime() - date2?.getTime();
    var day_difference = time_difference / (1000 * 3600 * 24);

    return day_difference;
  };

  return (
    <div className="w-[100%] mx-auto relative overflow-x-auto  sm:rounded-l">
    

      <table className="w-full overflow-x-auto text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-center text-gray-700 uppercase border-b border-gray-300">
          <tr>
            <th scope="col" className="px-6 py-3">
              Project ID
            </th>
            <th scope="col" className="px-6 py-3">
              Project Name
            </th>
            <th scope="col" className="px-6 py-3">
              Budget
            </th>
            <th scope="col" className="px-6 py-3">
              Physical Progress
            </th>
            <th scope="col" className="px-6 py-3">
              Financial Progress
            </th>
            <th scope="col" className="px-6 py-3">
              Blocks
            </th>
            <th scope="col" className="px-6 py-3">
              Houses
            </th>
            <th scope="col" className="px-6 py-3">
              Status
            </th>
            <th scope="col" className="px-6 py-3">
              Start Date
            </th>
            <th scope="col" className="px-6 py-3">
              End Date
            </th>

            {userGroup?.toString() === "1" && (
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {filteredProject?.map((project) => {
            const endDate = project?.end_date?.split("T")[0];
            let result = deadlineCalculator(endDate);

            return (
              <tr
              onClick={()=>navigate(`/projects/${project?.id}`)}
                key={project?.id}
                className={"text-center text-gray-600 border-b border-gray-300 cursor-pointer"}
              >
                <td className="px-6 py-4  whitespace-nowrap">
                  {project?.id}
                </td>
                <td
                  className={
                    result < 30
                      ? "px-5 py-4 text-red-500 flex items-center gap-[10px]"
                      : "px-6 py-4 "
                  }
                >
                  <span> {project?.name}</span>
                  <span> {result < 30 && <AiFillWarning />}</span>
                </td>
                <td
                  className={
                    result < 30 ? "px-6 py-4 text-red-500" : "px-6 py-4"
                  }
                >
                  {project?.budget}
                </td>
                <td
                  className={
                    result < 30 ? "px-6 py-4 text-red-500" : "px-6 py-4"
                  }
                >
                  {project?.progress_percentage + "%"}
                </td>
                <td
                  className={
                    result < 30 ? "px-6 py-4 text-red-500" : "px-6 py-4"
                  }
                >
                  {project?.financial_percentage === null
                    ? "0%"
                    : project?.financial_percentage + "%"}
                </td>
                <td
                  className={
                    result < 30 ? "px-6 py-4 text-red-500" : "px-6 py-4"
                  }
                >
                  {project?.no_blocks}
                </td>
                <td
                  className={
                    result < 30 ? "px-6 py-4 text-red-500" : "px-6 py-4"
                  }
                >
                  {project?.no_houses}
                </td>
                <td
                  className={
                    result < 30 ? "px-6 py-4 text-red-500" : "px-6 py-4"
                  }
                >
                  {project?.status}
                </td>

                {/* <td className="px-6 py-4">
                           {project.sites.length}
                        </td> */}
                <td
                  className={
                    result < 30 ? "px-6 py-4 text-red-500" : "px-6 py-4"
                  }
                >
                  {project?.start_date?.split("T")[0]}
                </td>
                <td
                  className={
                    result < 30 ? "px-6 py-4 text-red-500" : "px-6 py-4"
                  }
                >
                  {project?.end_date?.split("T")[0]}
                </td>

                {userGroup?.toString() === "1" && (
                  <td className="flex justify-center  items-center px-6 py-4 space-x-3">
                    <button
                      onClick={(e) =>{
                        e.stopPropagation();
                        navigate(`/projects/edit/${project.id}`, {
                          state: {
                            project,
                          },
                        })
                      }
                      }
                      className="bg-[#0B72B5] text-white rounded-[5px] p-2"
                    >
                      Edit
                    </button>
                    {/* <button className='bg-rose-500 text-white rounded-[5px] p-2' onClick={()=>handleDelete(project.id)}>Remove</button> */}
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
      {filteredProject?.length === 0 && (
        <div className="w-[100%] my-[20px] flex justify-center items-center">
          {" "}
          <span className="text-gray-600 font-bold ">No project available</span>
        </div>
      )}
    </div>
  );
}

export default Table;
