import React from "react";
import { useNavigate } from "react-router-dom";

function ProjectOverviewTable({ project, selectedSite }) {
  const navigate = useNavigate();

//   console.log(project, "Hello");

  const filtered = project?.find((site) => {
    return site?.id === selectedSite;
  });

  return (
    <div className="w-[90%] mx-auto  overflow-x-auto  sm:rounded-l">
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-center text-gray-700 uppercase border-b border-gray-300">
          <tr>
            <th scope="col" className="px-6 py-3">
              Site Name
            </th>

            <th scope="col" className="px-6 py-3">
              Location
            </th>
            <th scope="col" className="px-6 py-3">
              Progress
            </th>
            <th scope="col" className="px-6 py-3">
              Start Date
            </th>
            <th scope="col" className="px-6 py-3">
              End Date
            </th>
          </tr>
        </thead>
        <tbody>
          <tr onClick={()=>navigate(`/siteoverview/${filtered?.id}`)} className="text-center text-gray-600 border-b border-gray-300 cursor-pointer">
            <td className="px-6 py-4">{filtered? filtered?.name:'-'}</td>

            <td className="px-6 py-4">{filtered? filtered?.location: '-'}</td>
            <td className="px-6 py-4">{filtered? filtered?.progress:'-'}%</td>
            <td className="px-6 py-4">{filtered? filtered?.start_date:'-'}</td>
            <td className="px-6 py-4">{filtered? filtered?.end_date: '-'}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default ProjectOverviewTable;
