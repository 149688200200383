import axios from 'axios';
import React, { useState } from 'react'
import { FaAngleLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom'

function CreateBranch() {
    const navigate = useNavigate();
    const [name,setName] = useState();
    const [status,setStatus] = useState("active");
    const [loading,setLoading] = useState(false);
    const [error,setError] = useState(false);
    const [errorMessage,setErrorMessage] = useState("")

    const handleCreateBranch = async ()=>{
        try {
            setLoading(true)
            await axios.post('https://pms.dalyobt.com/api/view_branch/',{name,status,manager_id:''}).then((res)=>{
                setLoading(false);
                navigate(-1)
            }).catch((error)=>{
                setLoading(false)
            
                setErrorMessage(error?.response?.data?.error);

            })
            
        } catch (error) {
            setLoading(false)
            setError(true)
            
            
        }
    }
  return (
    <div className='w-[90%] min-h-[300px] mx-auto my-[20px] p-2  bg-white rounded-[10px]'>
        <div className='w-[90%] mx-auto my-[20px] flex justify-between items-center border-b border-gray-300 p-2'>
        <div className="flex justify-start items-center gap-[20px]">
          <FaAngleLeft
            className="text-[30px] cursor-pointer text-[#0B72B5]"
            onClick={() => navigate(-1)}
          />
          <span className="text-[20px] font-bold text-[#0B72B5]">Create branch</span>
        </div>

     
        
        </div>
        {error &&      <div className='w-[100%] py-4 bg-red-600 flex justify-center items-center'>
            <span className='text-[14px] text-white font-bold text-center'>{errorMessage}</span>
        </div>}

   
        <div className='w-[90%] mx-auto my-[40px]'>
        <div className="grid grid-cols-2 gap-[10px]">
          <div className="w-[100%] col-span-1  flex flex-col  items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              Branch Name <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="text"
                placeholder="branch-1"
                onChange={(e) => setName(e.target.value)}
                className="w-[100%] border border-[#ced4da] py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>
        
          <div className="w-[100%] col-span-1  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              Status 
            </span>
            <div className="w-[100%] rounded-[5px]">
              <select  value={status}  onChange={(e) => setStatus(e.target.value)}
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none">

                    <option value={"active"}>active</option>
                    <option value={"inactive"}>inactive</option>
              </select>
             
            </div>
          </div>
        </div>
        </div>
        {loading ? <div className='w-[95%] mx-auto flex justify-end items-center'>
            <span className='text-[14px] font-bold'>Loading...</span>
        </div>:  <div className="w-[95%] mt-[30px] text-[14px] flex justify-end items-center gap-[20px]">
        <button onClick={() => navigate(-1)}>Cancel</button>
        <button
          className="py-2 px-4 bg-[#0B72B5] text-white rounded-[10px]"
          onClick={handleCreateBranch}
        >
          Create Branch
        </button>
      </div>}
      
    </div>
  )
}

export default CreateBranch