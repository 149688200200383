import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

function InitialProgressReport() {
  const [listOfBOQ, setListOfBOQ] = useState([]);
  const [date, setDate] = useState("");
  const [uploadFile, setUploadFile] = useState(null);
  const [viewPop, setViewPop] = useState(false);
  const [idNum, setiDNum] = useState(0);
  const [itNumber, setItNumber] = useState("");
  const [quanComp, setQuanComp] = useState("");
  const [dateInput, setDateInput] = useState("");
  const [remarkInput, setRemarkInput] = useState("");

  const userID = localStorage.getItem("userID");

  const { id } = useParams();

  useEffect(() => {
    axios
      .get(`https://pms.dalyobt.com/api/boq_item/${id}/`)
      .then((response) => {
        setListOfBOQ(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [id]);
  const filteredBOQByDate = listOfBOQ.filter((d) => {
    return d.changeDate === date;
  });

  const handleUpload = (num, idNum) => {
    setViewPop(!viewPop);
    setItNumber(num);
    setiDNum(idNum);
    // console.log(num, "-------------------------------NUM--------------------------------------")
    // console.log(idNum, "-------------------------------IDNUM--------------------------------------")
  };

  const formData = new FormData();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (uploadFile && dateInput && quanComp) {
      formData.append("start_boq_item", idNum);
      formData.append("latest_boq_item", idNum);
      formData.append("quantity_completed", parseFloat(quanComp));
      formData.append("prepared_by", userID);
      formData.append("prepared_date", dateInput);
      formData.append("approved_by", null);
      formData.append("approved_date", null);
      formData.append("attachement", uploadFile);
      formData.append("remark", remarkInput);
      formData.append("status", false);

      await axios
        .post(`https://pms.dalyobt.com/api/const_progress/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          // console.log(response.data);
          window.location.href = `/projects/sites/buildings/${id}`;
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  // console.log(listOfBOQ)
  // console.log(filteredBOQByDate)
  // console.log("Date: ",date)

  // console.log("Current-ID: ", id);

  // console.log(uploadFile, "----------------------")

  return (
    <div className="w-[100%] relative mx-auto overflow-y-scroll h-[600px] p-4 bg-white  flex flex-col">
    <div className="w-[100%] mt-[20px] flex justify-center items-center">
            <span className="text-[#0B72B5] font-bold">Initial BOQ Report</span>
          </div>
    
      {viewPop && (
        <div className="w-[100%] absolute z-50 bg-white top-0 p-4 left-0 my-[30px] overflow-y-scroll mx-auto max-h-[420px]">
          <div className="flex flex-col">
            <div className="w-full z-10 my-4 flex justify-end">
              <button
                onClick={() => setViewPop(!viewPop)}
                className=" bg-red-700 p-3 rounded-md text-[18px] text-white"
              >
                x
              </button>
            </div>
            <div className="w-[80%] mt-[50px] mx-auto flex flex-col gap-[20px]">
              <div className="flex items-center justify-between gap-[10px] max-[1000px]:flex-col">
                <div className="w-[30%]  flex flex-col  items-start gap-[20px] max-[1000px]:w-[90%]">
                  <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
                    Current Completed Quantity
                    <span className="text-red-700">*</span>
                  </span>
                  <div className="w-[100%] rounded-[5px]">
                    <input
                      type="number"
                      min={0}
                      placeholder="100.25"
                      onChange={(e) => setQuanComp(e.target.value)}
                      className="w-[100%] border border-[#ced4da] py-3 px-4 rounded-[5px] text-[14px] outline-none"
                    />
                  </div>
                </div>
                <div className="w-[30%]  flex flex-col items-start gap-[20px] max-[1000px]:w-[90%]">
                  <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
                    Remark
                  </span>
                  <div className="w-[100%] rounded-[5px]">
                    <input
                      type="text"
                      placeholder="Remark"
                      onChange={(e) => setRemarkInput(e.target.value)}
                      className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
                    />
                  </div>
                </div>
                <div className="w-[30%]  flex flex-col items-start gap-[20px] max-[1000px]:w-[90%]">
                  <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
                    Date <span className="text-red-700">*</span>
                  </span>
                  <div className="w-[100%] rounded-[5px]">
                    <input
                      type="date"
                      // placeholder="Remark"
                      onChange={(e) => setDateInput(e.target.value)}
                      className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
                    />
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-between gap-[10px]">
                <div className="w-[100%]  flex flex-col items-start gap-[20px]">
                  <div className="w-[80%] flex items-start gap-2">
                    <span className="text-[14px] font-bold text-[#0B72B5]">
                      Upload Takeoff Sheet
                    </span>
                    <span className="text-red-700">*</span>
                  </div>
                  <div className="w-[100%] rounded-[5px]">
                    <button>
                      <label>
                        <input
                          type="file"
                          // placeholder="contractor@gmail.com"
                          onChange={(e) => setUploadFile(e.target.files[0])}
                          name="myfile"
                          accept=".pdf"
                          className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
                          hidden
                        />
                        <span className="bg-[#0B72B5] text-white p-2 cursor-pointer text-[12px] rounded-[5px]">
                          Upload Takeoff Sheet
                        </span>
                        <span className="text-[12px]">
                          {uploadFile ? uploadFile?.name : ""}
                        </span>
                      </label>
                    </button>
                  </div>
                </div>
                {uploadFile && (
                  <div className="w-full">
                    <embed
                      src={URL.createObjectURL(uploadFile)}
                      type="application/pdf"
                      height="500px"
                      width="100%"
                    />
                  </div>
                )}
              </div>
              <div className="my-4">
                <button
                  onClick={handleSubmit}
                  className="bg-[#0B72B5] text-white p-2 text-[12px] rounded-[5px]"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="w-[90%] mt-[30px] mx-auto flex justify-end items-center">
        <input
          type="date"
          className="w-[25%] p-4 text-[14px] text-gray-600 outline-none border border-gray-400 rounded-[5px]"
          placeholder="Search"
          onChange={(e) => setDate(e.target.value)}
        />
      </div>
      <div className="overflow-y-scroll">
        <table className="min-w-full divide-y mt-[20px]  divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                ID.
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Item No.
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Work Type
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Category
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Unit Price
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Quantity Unit
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Quantity
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Description
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Uploaded Date
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Attachement
              </th>
              {/* <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Revision
            </th> */}
              {/* <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Current Progress<span className="text-red-700">*</span>
              </th> */}
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Upload Progress
              </th>
              {/* <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Remark
              </th> */}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {date === ""
              ? listOfBOQ.map((boq, index) => {
                  return (
                    <tr
                      key={index}
                      className="text-[15px] font-normal text-gray-600"
                    >
                      <td className="px-6 py-4 whitespace-nowrap">
                        {boq ? boq?.id : "-"}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {boq ? boq?.Item_no : "-"}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {boq ? boq?.work_type : "-"}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {boq ? boq?.category : "-"}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {boq ? boq?.unit_price : "-"}
                      </td>
                      {/* <td className="px-6 py-4 whitespace-nowrap">Electricity</td> */}
                      <td className="px-6 py-4 whitespace-nowrap">
                        {boq ? boq?.quantity_unit : "-"}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {boq ? boq?.quantity : "-"}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {boq ? boq?.description : "-"}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {boq ? boq?.changeDate : "-"}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {boq?.attachement && (
                          <a
                            href={`https://pms.dalyobt.com${boq?.attachement}`}
                            className="underline text-[#0B72B5]"
                            download
                          >
                            {boq ? boq?.attachement : "-"}
                          </a>
                        )}
                      </td>
                      {/* <td className="px-6 py-4 whitespace-nowrap">{boq? boq?.revision: '-'}</td> */}
                      {/* <td className="px-6 py-4 whitespace-nowrap">
                        <input
                          type="number"
                          min={0}
                          placeholder="0"
                          className="w-[80%] mx-auto p-4 text-[14px] text-gray-600 outline-none border border-gray-400 rounded-[5px]"
                        />
                      </td> */}
                      <td className="px-6 py-4 w-[50%] whitespace-nowrap">
                        <button
                          onClick={() => handleUpload(boq?.Item_no, boq?.id)}
                          className="p-2 bg-[#0B72B5] text-white text-[12px] rounded-md"
                        >
                          Upload Progress
                          {/* <label className="flex items-end gap-3">
                            <input
                              type="file"
                              onChange={(e) => setUploadFile(e.target.files[0])}
                              name={`myfile-${index}`}
                              accept=".pdf"
                              hidden
                            />
                            <span className="bg-[#0B72B5] text-white p-2 cursor-pointer text-[12px] rounded-[5px]">
                              Upload Attachement
                            </span>
                            <span className="text-[12px]">{uploadFile ? uploadFile?.name : ""}</span>
                          </label> */}
                        </button>
                      </td>
                      {/* <td className="px-6 py-4 w-[50%] leading-[30px] whitespace-nowrap">
                        <input
                          type="text"
                          placeholder="Remark"
                          className="w-[80%] mx-auto p-4 text-[14px] text-gray-600 outline-none border border-gray-400 rounded-[5px]"
                        />
                      </td> */}
                    </tr>
                  );
                })
              : filteredBOQByDate?.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      className="text-[15px] font-normal text-gray-600"
                    >
                      <td className="px-6 py-4 whitespace-nowrap">
                        {item ? item?.id : "-"}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {item ? item?.Item_no : "-"}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {item ? item?.work_type : "-"}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {item ? item?.category : "-"}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {item ? item?.unit_price : "-"}
                      </td>
                      {/* <td className="px-6 py-4 whitespace-nowrap">Electricity</td> */}
                      <td className="px-6 py-4 whitespace-nowrap">
                        {item ? item?.quantity_unit : "-"}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {item ? item?.quantity : "-"}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {item ? item?.description : "-"}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {item ? item?.changeDate : "-"}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {item?.attachement && (
                          <a
                            href={`https://pms.dalyobt.com${item?.attachement_url}`}
                            className="underline text-[#0B72B5]"
                            download
                          >
                            {item ? item?.attachement : "-"}
                          </a>
                        )}
                      </td>
                      {/* <td className="px-6 py-4 whitespace-nowrap">{item? item?.revision: '-'}</td> */}
                      {/* <td className="px-6 py-4 whitespace-nowrap">
                        <input
                          type="number"
                          placeholder="0"
                          className="w-[80%] mx-auto p-4 text-[14px] text-gray-600 outline-none border border-gray-400 rounded-[5px]"
                        />
                      </td> */}
                      <td className="px-6 py-4 w-[50%] whitespace-nowrap">
                        <button
                          onClick={() => handleUpload(item?.Item_no, item?.id)}
                          className="p-2 bg-[#0B72B5] text-white text-[12px] rounded-md"
                        >
                          Upload Progress
                        </button>
                        {/* <button>
                          <label className="flex items-end gap-3">
                            <input
                              type="file"
                              onChange={(e) => setUploadFile(e.target.files[0])}
                              name={`myfile-${index}`}
                              accept=".pdf"
                              hidden
                            />
                            <span className="bg-[#0B72B5] text-white p-2 cursor-pointer text-[12px] rounded-[5px]">
                              Upload Attachement
                            </span>
                            <span className="text-[12px]">
                              {uploadFile ? uploadFile?.name : ""}
                            </span>
                          </label>
                        </button> */}
                      </td>
                      {/* <td className="px-6 py-4 w-[50%] leading-[30px] whitespace-nowrap">
                        <input
                          type="text"
                          placeholder="Remark"
                          className="w-[80%] mx-auto p-4 text-[14px] text-gray-600 outline-none border border-gray-400 rounded-[5px]"
                        />
                      </td> */}
                    </tr>
                  );
                })}
          </tbody>
        </table>
        {filteredBOQByDate.length === 0 && date !== "" && (
          <div className="w-[100%] my-[20px] flex justify-center items-center">
            {" "}
            <span className="text-gray-600 font-bold ">No BOQ available</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default InitialProgressReport;
