import React, { useEffect, useState } from "react";
import Table from "./Table";
import { projects } from "../../utils/data";
import { useNavigate, useParams } from "react-router-dom";
import { FaAngleLeft } from "react-icons/fa";
import axios from "axios";

function Projects() {
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const {id} = useParams();
  const userGroup = localStorage.getItem("userGroup");
  const [branch, setBranch] = useState({});


  useEffect(() => {
     
      axios
        .get(`https://pms.dalyobt.com/api/view_branch/${id}`)
        .then((response) => {
       
         
         
          setBranch(response.data[0]);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }, [id]);
  return (
    <div className="mt-[50px]">
      <div className="w-[90%] mt-6 mx-auto flex justify-between">
        <div className="flex justify-start items-center gap-[20px]">
          <FaAngleLeft
            className="text-[30px] cursor-pointer text-[#0B72B5]"
            onClick={() => navigate('/branchlist')}
          />
          <span className="text-[20px] font-bold text-[#0B72B5]">Projects in  {branch?.name} branch</span>
        </div>
        {userGroup?.toString() === "1" && (
          <button
            onClick={() => navigate(`/projects/addproject/${id}`)}
            className="bg-[#0B72B5] py-2 px-4 text-white text-[14px] rounded-[10px]"
          >
            Add Project
          </button>
        )}
      </div>
      <div className="w-[100%] mt-[50px] flex justify-center">
        <input
          type="text"
          value={search}
          placeholder="Search by project name"
          onChange={(e) => setSearch(e.target.value)}
          className="bg-white border border-gray-400 p-3 w-[40%] rounded-[5px] text-[14px] outline-none text-gray-500 max-[1000px]:w-[80%]"
        />
      </div>

      <div className="bg-white  w-[95%] mx-auto p-4 rounded-[10px] my-[50px] border border-gray-300 max-[1000px]:w-[95%]">
        <div className="mt-[20px] max-h-[600px] overflow-y-auto p-2">
          <Table projects={projects} search={search} />
        </div>
      </div>
    </div>
  );
}

export default Projects;
