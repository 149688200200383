import React, { useEffect, useState } from "react";
import CheckListTable from "../CheckListTable";
// import { checkListContent } from "../../../utils/data";
import axios from "axios";

function CheckListFill() {
  const [input, setInput] = useState("MOBILIZATION");
  const [activity, setActivity] = useState([]);
  const [checkListItems, setCheckListItems] = useState([])
  const [dateCurrent, setDateCurrent] = useState('')
  const [floor, setFloor] = useState('')
  const [error, setError] = useState('')

  useEffect(() => {
    axios
      .get("https://pms.dalyobt.com/api/activities/")
      .then((response) => {
        setActivity(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(()=>{
    axios.get("https://pms.dalyobt.com/api/chklitem/").then((response)=>{
      setCheckListItems(response.data);
    }).catch((error)=>{
      console.error(error)
    })
  },[])

  const findActivityID = activity.find((item)=>item?.name===input)?.ID_number
  // console.log(findActivityID) 

  const filteredList = checkListItems.filter((item)=>item?.division===findActivityID)

  return (
    <div>
      <div className="w-[90%] h-[85%] bg-white my-[20px] py-2  mx-auto p-2 mt-[50px] rounded-[5px]">
      
      <div className="w-[100%] mt-[20px] flex justify-center items-center">
            <span className="text-[#0B72B5] font-bold">Checklist Fill</span>
          </div>
      {error && <div className="w-[80%] mt-[30px] mx-auto my-2 bg-red-500 text-center p-2 rounded-md">
        <p className="text-white font-medium">{error}</p>
      </div>}
        <div className="w-full mx-auto mt-[20px]  flex items-center gap-[20px]">
          <div className="w-[30%] flex gap-[10px] items-start">
            <select
              onChange={(e) => setInput(e.target.value)}
              id="data"
              className="bg-white w-[100%] p-4 text-[14px] text-gray-600 outline-none border border-gray-400 rounded-md"
            >
              {activity?.map((cl, index) => (
                <option className="p-2 border border-gray-400" key={index}>
                  {cl?.name}
                </option>
              ))}
            </select><span className="text-red-700">*</span>
            {/* <input
              list="data"
              className="w-[100%] p-4 text-[14px] text-gray-600 outline-none border border-gray-400 rounded-[5px]"
              onChange={(e) => setInput(e.target.value)}
              placeholder="Search"
            />
            <datalist color="white" id="data" className="bg-white">
              {checkListContent.map((cl, index) => (
                <option key={index}>{cl.Activities}</option>
              ))}
            </datalist> */}
          </div>

          <div className="w-[30%] flex gap-[10px] items-start">
            <input
              type="date"
              className="w-[100%] p-4 text-[14px] text-gray-600 outline-none border border-gray-400 rounded-[5px]"
              onChange={(e) => setDateCurrent(e.target.value)}
            /><span className="text-red-700">*</span>
          </div>

          <div className="w-[30%] flex gap-[10px] items-start">
            <input
              type="text"
              className="w-[100%] p-4 text-[14px] text-gray-600 outline-none border border-gray-400 rounded-[5px]"
              onChange={(e) => setFloor(e.target.value)}
              placeholder="Ground Floor"
            /><span className="text-red-700">*</span>
          </div>
          {/* <div className="w-[25%]">
            <input
              type="text"
              className="w-[100%] p-4 text-[14px] text-gray-600 outline-none border border-gray-400 rounded-[5px]"
              onChange={(e) => setInput(e.target.value)}
              placeholder="Consultant"
            />
          </div> */}
        </div>
        <CheckListTable filteredList={filteredList} dateCurrent={dateCurrent} input={input} floor={floor} setError={setError} findActivityID={findActivityID} />
      </div>

      {/* <div className="w-[90%] mx-auto mt-[40px] flex flex-col gap-[10px]">
        <span className="text-[#0B72B5] font-bold">Remark</span>
        <textarea
          rows={8}
          placeholder="Additional Remarks"
          className="w-[40%] p-2 bg-white outline-none border border-gray-400 resize-none rounded-[5px]"
        ></textarea>
      </div>

      <div className="w-[95%] my-[30px] p-4 flex justify-end items-center gap-[20px]">
        <button className="py-3 px-4 bg-[#0B72B5] text-white rounded-[10px]">
          Save
        </button>
      </div> */}
    </div>
  );
}

export default CheckListFill;
