import axios from "axios";
import React, { useState } from "react";
import { FaAngleLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

function AddSite() {
  const navigate = useNavigate();
  const projectID = localStorage.getItem("projectID");
  // console.log(projectID)
  const [name, setName] = useState("");
  const [numberOfHouses, setNumberOfHouses] = useState(0);
  const [numberOfBlocks, setNumberOfBlocks] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [area, setArea] = useState(0);
  const [address, setAddress] = useState("");
  const [location, setLocation] = useState("");
  const [error, setError] = useState("");

  // const token = localStorage.getItem('token')

  const handleSend = async () => {
    // const headers = {
    //     'Authorization': token,
    //     'Content-Type': 'application/json',
    // };
    await axios
      .post("https://pms.dalyobt.com/api/view_site/", {
        name: name,
        start_date: startDate,
        end_date: endDate,
        progress: 0.0,
        status: "new",
        location: location,
        address: address,
        area: area,
        no_houses: numberOfHouses,
        no_blocks: numberOfBlocks,
        project_id: projectID,
      })
      .then((response) => {
        // console.log(response.data);
        navigate(`/projects/${projectID}`);
      })
      .catch((error) => {
        setError(error.message);
        // console.error(error)
      });
  };

  return (
    <div className="w-[100%] min-h-[91.5vh] flex flex-col  py-4  bg-white">
      <div className="w-[90%] mx-auto my-[30px] flex items-center gap-[30px]">
        <FaAngleLeft
          className="text-[30px] cursor-pointer text-[#0B72B5]"
          onClick={() => navigate("/projects/" + projectID)}
        />
        <span className="text-[20px] text-[#0B72B5] font-bold">
          Add new site
        </span>
      </div>
      {error && <div className="w-[80%] mx-auto my-2 bg-red-500 text-center p-2 rounded-md">
        <p className="text-white font-medium">Please Check the fields again there might be a missing field.</p>
      </div>}
      <div className="w-[80%] mt-[50px] mx-auto flex flex-col gap-[20px]">
        <div className="flex justify-between my-[20px] items-center">
          <div className="w-[40%]  flex flex-col  items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[30%] text-[#0B72B5]">
              Site Name <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="text"
                onChange={(e) => setName(e.target.value)}
                placeholder="Site-1"
                className="w-[100%] required border border-[#ced4da] py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>

          <div className="w-[40%]  flex flex-col  items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[30%] text-[#0B72B5]">
              Location
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="text"
                onChange={(e) => setLocation(e.target.value)}
                placeholder="Location"
                className="w-[100%] required border border-[#ced4da] py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>
        </div>

        <div className="flex justify-between my-[20px]">
          <div className="w-[40%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[30%] text-[#0B72B5]">
              Area
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="number"
                onChange={(e) => setArea(e.target.value)}
                placeholder="Area"
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>
          <div className="w-[40%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[30%] text-[#0B72B5]">
              Address
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="text"
                onChange={(e) => setAddress(e.target.value)}
                placeholder="Address"
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between my-[20px]">
          <div className="w-[40%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[30%] text-[#0B72B5]">
              Start Date <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="date"
                onChange={(e) => setStartDate(e.target.value)}
                placeholder="Ex. 2023-07-08"
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>
          <div className="w-[40%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[30%] text-[#0B72B5]">
              End Date <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="date"
                onChange={(e) => setEndDate(e.target.value)}
                placeholder="Ex. 2023-07-08"
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>
        </div>
        {/* <div className='w-[100%]  flex flex-col items-start gap-[20px]'>
    <span className='text-[14px] font-bold w-[30%] text-[#0B72B5]'>Description</span>
    <div className='w-[100%] rounded-[5px]'>
    
    <textarea rows={10} className='w-[100%] border border-[#ced4da]  resize-none py-3 px-4 rounded-[5px] text-[14px] outline-none'>

    </textarea>
    </div>
 
</div> */}
      </div>

      <div className="w-[80%] mt-[50px] mx-auto flex flex-col gap-[20px]">
        {/* <div className='w-[40%]  flex flex-col  items-start gap-[20px]'>
            <span className='text-[14px] font-bold w-[30%] text-[#0B72B5]'>Location</span>
            <div className='w-[100%] rounded-[5px]'>
            <input type='text' onChange={(e)=>setLocation(e.target.value)} placeholder='Location'  className='w-[100%] required border border-[#ced4da] py-3 px-4 rounded-[5px] text-[14px] outline-none'/>
            </div>
         
        </div> */}

        <div className="flex justify-between my-[20px]">
          <div className="w-[40%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[30%] text-[#0B72B5]">
              Number Of Houses
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="number"
                onChange={(e) => setNumberOfHouses(e.target.value)}
                placeholder="Number Of Houses"
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>
          <div className="w-[40%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[30%] text-[#0B72B5]">
              Number Of Blocks
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="number"
                onChange={(e) => setNumberOfBlocks(e.target.value)}
                placeholder="Number Of Blocks"
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="w-[90%] mt-[30px] flex justify-end items-center gap-[20px]">
        <button className="text-[14px]" onClick={() => navigate("/projects/" + projectID)}>
          Cancel
        </button>
        <button
          onClick={handleSend}
          className="py-2 px-4 text-[14px] cursor-pointer bg-[#0B72B5] text-white rounded-[10px]"
        >
          Add Site
        </button>
      </div>
    </div>
  );
}

export default AddSite;
