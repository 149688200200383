import React, { useEffect, useState } from 'react'
import { FaAngleLeft } from 'react-icons/fa'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'

function ProfileStatusUpdate() {
const navigate = useNavigate()
const [error,setError] = useState(false)
const [contStatus, setContStatus] = useState([])
const {id} = useParams()

const [name, setName] = useState('')
const [email, setEmail] = useState('')
const [contactPer, setContactPer] = useState('')
const [address, setAddress] = useState('')
const [tinNo, setTinNo] = useState('')
const [vatReg, setVatReg] = useState('')
const [phone, setPhone] = useState('')

// console.log(id)

useEffect(()=>{
  axios.get(`https://pms.dalyobt.com/api/single_contractor_view/${id}/`).then((response)=>{
    setContStatus(response.data)
  }).catch((error)=>{
    console.error(error)
    setError(error)
  })
},[id])
const contStatusArray = [contStatus]
const filteredContractor = contStatusArray?.find((item)=>item)

useEffect(() => {
  if (filteredContractor) {
    setName(filteredContractor?.name || '');
    setEmail(filteredContractor?.email || '');
    setContactPer(filteredContractor?.contact_person || '');
    setAddress(filteredContractor?.address || '');
    setTinNo(filteredContractor?.tin_no || '');
    setVatReg(filteredContractor?.Vat_Reg_no || '');
    setPhone(filteredContractor?.phone || '');
  }
}, [filteredContractor]);


// console.log(filteredContractor, "___________Filtered___________________")

const handleUpdateProfiles = async()=>{
  await axios.put(`https://pms.dalyobt.com/api/contractor_view/${id}/`, {
    "name": name,
    "email": email,
    "contact_person": contactPer,
    "address": address,
    "tin_no": tinNo,
    "Vat_Reg_no": vatReg,
    "phone": phone,
  }).then((response)=>{
    console.log(response.data)
    navigate(-1)
  }).catch((error)=>{
    console.error(error)
    setError(error)
  })
}

  return (
    <div className="w-[100%] min-h-[91.5vh] flex flex-col  py-4  bg-white">
    <div className="w-[90%] mx-auto my-[30px] flex justify-between items-center">
      <div className=" flex items-center gap-[30px]">
        <FaAngleLeft
          className="text-[30px] cursor-pointer text-[#0B72B5]"
          onClick={() => navigate(-1)}
        />
        <span className="text-[20px] text-[#0B72B5] font-bold">
          Update Contractor
        </span>
      </div>
      {/* <div>{loading && <p>Loading...</p>}</div> */}
    </div>
    {/*		PB END DATE	AG START DATE	AG END DATE	REMARK */}
    {error && <div className="w-[80%] mx-auto my-2 bg-red-500 text-center p-2 rounded-md">
      <p className="text-white font-medium">Please Check the fields again there might be a missing field.</p>
    </div>}
    <div className="w-[80%] mt-[50px] mx-auto flex flex-col gap-[20px]">
      <div className="flex items-center justify-between gap-[10px]">
        <div className="w-[50%]  flex flex-col  items-start gap-[20px]">
          <span className="text-[14px] font-bold w-[100%] text-[#0B72B5]">
          Name <span className="text-red-700">*</span>
          </span>
          <div className="w-[100%] rounded-[5px]">
            <input
              type="text"
              value={name}
              placeholder="Contractor-1"
              onChange={(e) => setName(e.target.value)}
              className="w-[100%] border border-[#ced4da] py-3 px-4 rounded-[5px] text-[14px] outline-none"
            />
          </div>
        </div>
        <div className="w-[50%]  flex flex-col items-start gap-[20px]">
          <span className="text-[14px] font-bold w-[100%] text-[#0B72B5]">
          Email <span className="text-red-700">*</span>
          </span>
          <div className="w-[100%] rounded-[5px]">
            <input
              type="email"
              value={email}
              placeholder="contractor@gmail.com"
              onChange={(e) => setEmail(e.target.value)}
              className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
            />
          </div>
        </div>
      </div>

      <div className="flex justify-between gap-[10px] my-[20px]">
        
          <div className="w-[100%] flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[100%] text-[#0B72B5]">
              Contact Person
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="text"
                value={contactPer}
                placeholder="Contact Person"
                onChange={(e) => setContactPer(e.target.value)}
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>
        
        <div className="w-[100%]  flex flex-col items-start gap-[20px]">
          <span className="text-[14px] font-bold w-[100%] text-[#0B72B5]">
            Address
          </span>
          <div className="w-[100%] rounded-[5px]">
            <input
              type="text"
              value={address}
              placeholder="address-1"
              onChange={(e) => setAddress(e.target.value)}
              className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between my-[20px] gap-[10px]">
        <div className="w-[50%]  flex flex-col items-start gap-[20px]">
          <span className="text-[14px] font-bold w-[100%] text-[#0B72B5]">
          Tin Number <span className="text-red-700">*</span>
          </span>
          <div className="w-[100%] rounded-[5px]">
            <input
              type="text"
              value={tinNo}
              onChange={(e) => setTinNo(e.target.value)}
              placeholder="0"
              className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
            />
          </div>
        </div>
        <div className="w-[50%]  flex flex-col items-start gap-[20px]">
          <span className="text-[14px] font-bold w-[100%] text-[#0B72B5]">
          Vat Registration Number <span className="text-red-700">*</span>
          </span>
          <div className="w-[100%] rounded-[5px]">
            <input
              type="text"
              value={vatReg}
              onChange={(e) => setVatReg(e.target.value)}
              placeholder="0"
              className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
            />
          </div>
        </div>
      </div>


      <div className="flex justify-between my-[20px] gap-[10px]">
        <div className="w-[50%]  flex flex-col items-start gap-[20px]">
          <span className="text-[14px] font-bold w-[100%] text-[#0B72B5]">
          Phone <span className="text-red-700">*</span>
          </span>
          <div className="w-[100%] rounded-[5px]">
            <input
              type="text"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder="0"
              className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
            />
          </div>
        </div>
        
      </div>

    </div>

    <div className="w-[90%] mt-[30px] flex justify-end items-center gap-[20px]">
      <button onClick={() => navigate(-1)}>Cancel</button>
      <button
        className="py-3 px-4 bg-[#0B72B5] text-white text-[14px] rounded-[10px]"
        onClick={handleUpdateProfiles}
      >
        Update Contractor
      </button>
    </div>
  </div>
  )
}

export default ProfileStatusUpdate