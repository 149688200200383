import axios from "axios";
import React, { useState } from "react";
import { BsFillCloudUploadFill } from "react-icons/bs";
import { FaAngleLeft } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";

function AddPerformaceBond() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [fieldError, setFieldError] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [performanceBondFile, setPerformanceBondFile] = useState(null);

  const handleSubmit = async () => {
    if (startDate && endDate && performanceBondFile) {
      const formData = new FormData();

      formData.append("start_date", startDate)
      formData.append("end_date", endDate)
      formData.append("attachment", performanceBondFile)
      formData.append("block", id)

      await axios
        .post(`https://pms.dalyobt.com/api/performance_bond_handover/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          window.location.href = `/postconstruction/performancebond/${id}`;
        })
        .catch((error) => {
          console.error(error);
          setError(true);
          setFieldError(false);
        });
    } else {
      setError(true);
      setFieldError(true);
    }
  };

  return (
    <div className="bg-white p-4 h-[91vh]">
      <div className="w-[90%] mt-[30px] mx-auto">
        <div className=" flex items-center gap-[30px]">
          <FaAngleLeft
            className="text-[25px] cursor-pointer text-[#0B72B5]"
            onClick={() => navigate(-1)}
          />
          <span className="text-[20px] text-[#0B72B5] font-bold">
            Add Performance Bond
          </span>
        </div>

        {error && (
          <div className="w-[80%] mx-auto my-4 bg-red-500 text-center p-2 rounded-md">
            {fieldError ? (
              <p className="text-white font-medium">
                There are some missing fields.
              </p>
            ) : (
              <p className="text-white font-medium">
                Please insert the correct format.
              </p>
            )}
          </div>
        )}

        <div className="flex justify-between my-[30px] gap-[10px]">
          <div className="w-[50%] mt-[20px]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              Start Date <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="date"
                onChange={(e) => setStartDate(e.target.value)}
                // placeholder="Ex. 2023-07-08"
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
            {/* {dateError && <span className="text-[14px] text-red-500">Invalid Date</span>} */}
          </div>
          <div className="w-[50%] mt-[20px]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              End Date <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="date"
                onChange={(e) => setEndDate(e.target.value)}
                // placeholder="Ex. 2023-07-08"
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
            {/* {dateError && <span className="text-[14px] text-red-500">Invalid Date</span>} */}
          </div>
        </div>
        <div className="w-[100%] mt-[30px] flex flex-col gap-[10px]">
          <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
            Attachement <span className="text-red-700">*</span>
          </span>
          <label>
            <input
              type="file"
              onChange={(e) => setPerformanceBondFile(e.target.files[0])}
              hidden
            />
            <div className="[w-[70%] h-[200px] flex justify-center gap-[10px] text-[#0B72B5] items-center p-4 mx-auto border border-gray-300 rounded border-dashed cursor-pointer text-center">
              <BsFillCloudUploadFill />
              <span className="text-[14px] font-bold">Upload Attachement</span>
            </div>
          </label>
        </div>

        <div className="w-[100%] my-4 overflow-y-scroll">
          {/* How to view the uploaded file inside this div */}
          {performanceBondFile && (
            <embed
              src={URL.createObjectURL(performanceBondFile)}
              type="application/pdf"
              width="100%"
              height="600px"
            />
          )}
        </div>

        <div className="w-[100%] my-[30px] flex justify-end items-center">
          <button onClick={handleSubmit} className="bg-[#0B72B5] cursor-pointer text-white text-[14px] py-2 px-4 rounded">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddPerformaceBond;
