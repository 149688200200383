import {Routes,Route} from 'react-router-dom'
import Login from './pages/Login';
import Home from './pages/Home'
// import { useEffect } from 'react';

// import axios from 'axios';

import ProtectedRoute from './components/ProtectedRoute';
// import Error from './pages/Error';



function App() {

  const token = localStorage.getItem('token')
  // const userGroup = localStorage.getItem('userGroup')



  // const getItemModels = async()=>{

  // await axios.get('https://pms.dalyobt.com/api/BOQ_Itemmodels/').then((res)=>{
  //     console.log(res.data) 
  //   })
    

  // }
  // useEffect(()=>{
  //   getItemModels()
    

  // },[])
  return (
    <>
      <Routes>
        <Route path='/login' index element={<Login />} />
        {/* <Route path='/*'  element={<Home />} /> */}
        <Route element={<ProtectedRoute token={token} />} >
          <Route path='/*'  element={<Home />} />
          {/* <Route path='*'  element={<Error />} /> */}

        </Route>
        {/* <Route path='*' index element={<Error />} /> */}
        
      </Routes>

  
    </>
  );
}

export default App;



// export default api;
