import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
function FinancialGet() {
  const { id } = useParams();
  const [filteredList, setFilteredList] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    axios
      .get(`https://pms.dalyobt.com/api/block_finance/${id}/`)
      .then((res) => {
        setFilteredList(res.data);
      })
      .catch((e) => {
        setError(true);
        console.log(e);
      });
  }, [id]);

  return (
    <div className="w-[90%] overflow-y-auto overflow-x-hidden shadow-xl  bg-white my-[20px] py-2  mx-auto p-2 mt-[50px] rounded-[5px] flex flex-col gap-[30px] items-start relative">
      {error && (
        <div className="w-[80%] mx-auto my-2 bg-red-500 text-center p-2 rounded-md">
          <p className="text-white font-medium">
            Failed to get data from the server.
          </p>
        </div>
      )}
      <div className="w-[100%] my-[30px] mx-auto max-h-[400px] overflow-auto">
      <div className="w-[100%] flex justify-center items-center">
            <span className="text-[#0B72B5] font-bold">Financial Report</span>
          </div>
        <table className="min-w-full divide-y mt-[20px]  divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                ID
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Reciept Attachement
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Payment Certificate
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Amount
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Date
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredList?.map((boq, index) => {
              return (
                <tr
                  key={index}
                  className="text-[15px] font-normal text-gray-600"
                >
                  <td className="px-6 py-4 whitespace-nowrap">
                    {boq ? boq?.id : "-"}
                  </td>

                  <td className="px-6 py-4 whitespace-nowrap">
                    {boq?.reciept_attachement && (
                      <a
                        href={`https://pms.dalyobt.com${boq?.reciept_attachement}`}
                        download
                        className="text-[#0B72B5] underline"
                      >
                        {boq ? boq?.reciept_attachement : "-"}
                      </a>
                    )}
                  </td>

                  <td className="px-6 py-4 whitespace-nowrap">
                    {boq?.payment_certificate && (
                      <a
                        href={`https://pms.dalyobt.com${boq?.payment_certificate}`}
                        download
                        className="text-[#0B72B5] underline"
                      >
                        {boq ? boq?.payment_certificate : "-"}
                      </a>
                    )}
                  </td>

                  <td className="px-6 py-4 whitespace-nowrap">
                    {boq ? boq?.amount : "-"}
                  </td>

                  <td className="px-6 py-4 whitespace-nowrap">
                    {boq ? boq?.date_filled : "-"}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {filteredList?.length === 0 && (
          <div className="w-[100%] my-[20px] flex justify-center items-center">
            {" "}
            <span className="text-gray-600 font-bold ">
              No Financial Report Available
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

export default FinancialGet;
