import React from "react";
import {  AiFillWarning } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

function SingleProjectTable({ project, projectSearch }) {
  const navigate = useNavigate()
  const filteredSites = project?.filter((s) => {
    return s?.name?.includes(projectSearch) || s?.name?.includes(projectSearch.toLowerCase()) || s?.name?.includes(projectSearch.toUpperCase());
  });

const userGroup = localStorage?.getItem("userGroup");
  // console.log(filteredSites, "Filtered-Sites")

const deadlineCalculator = (newDate) =>{
      
  const d = new Date(newDate)
  const date2 = new Date(); 
  var time_difference = d.getTime() - date2.getTime();
  var day_difference = time_difference / (1000 * 3600 * 24);
    
     return day_difference;
  }
  return (
    <div className="w-[100%] mx-auto relative overflow-x-auto  sm:rounded-l">
     
   
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-center text-gray-700 uppercase border-b border-gray-300">
          <tr>
            <th scope="col" >
              Site Name
            </th>

          
            <th scope="col" className="px-6 py-3">
              Status
            </th>
            {/*  location:"-",
        no_houses:"-",
        no_blocks:"-", */}
            <th scope="col">
              Physical Progress
            </th>
            <th scope="col">
              Financial Progress
            </th>
            <th scope="col">
              Area
            </th>
            {/* <th scope="col" className="px-6 py-3">
                    Infrastructure
                </th> */}
            <th scope="col">
              Location
            </th>
            <th scope="col">
              Address
            </th>
            <th scope="col" >
              No. houses
            </th>
            <th scope="col">
              No. blocks
            </th>
            <th scope="col">
              Start Date
            </th>
            <th scope="col">
              End Date
            </th>
            {userGroup.toString() === "1" && (
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {filteredSites?.map((site) => {
            let result = deadlineCalculator(site?.end_date);
            return (
              <tr
                key={site?.id}
                onClick={(e)=> navigate(`/projects/sites/${site?.id}`)}
                className="text-center text-gray-600 border-b border-gray-300 cursor-pointer"
              >
                <td className={result < 30 ? "px-6 py-4 text-red-500  whitespace-nowrap flex justify-center items-center gap-[10px]" : "px-6 py-4   whitespace-nowrap"}>
                  {site?.name} <span> {result < 30 && <AiFillWarning />}</span>
                  
                </td>


                <td className={result < 30 ? "px-6 py-4 text-red-500" : "px-6 py-4"}>{site?.status}</td>
                <td className={result < 30 ? "px-6 py-4 text-red-500" : "px-6 py-4"}>{site?.progress}%</td>
                <td className={result < 30 ? "px-6 py-4 text-red-500" : "px-6 py-4"}>{site?.financial_progress}%</td>
                
                <td className={result < 30 ? "px-6 py-4 text-red-500" : "px-6 py-4"}>{site?.area}</td>
                {/* <td className="px-6 py-4 flex flex-col gap-[10px] justify-center  text-start">
                        {site?.infrastructure.map((i)=>{
                        return <li key={i.id}>{i.name}</li>
                       })}
                       
                        </td> */}
                <td className={result < 30 ? "px-6 py-4 text-red-500" : "px-6 py-4"}>{site?.location ? site?.location:"-"}</td>
                <td className={result < 30 ? "px-6 py-4 text-red-500" : "px-6 py-4"}>{site?.address ? site?.address:"-"}</td>
                <td className={result < 30 ? "px-6 py-4 text-red-500" : "px-6 py-4"}>{site?.no_houses}</td>
                <td className={result < 30 ? "px-6 py-4 text-red-500" : "px-6 py-4"}>{site?.no_blocks}</td>
                <td className={result < 30 ? " py-4 text-red-500" : "px-6 py-4"}>{site?.start_date}</td>
                <td className={result < 30 ? "py-4 text-red-500" : "px-6 py-4"}>{site?.end_date}</td>

                {userGroup?.toString() === "1" && (
                  <td className=" px-6 py-4 space-x-3">

                    <button
                    
                      onClick={(e)=>{e.stopPropagation(); navigate(`/projects/sites/edit/${site?.id}`,{
                        state:{
                          site
                        }
                      })}}
                      className="bg-[#0B72B5] p-2 text-white rounded-[5px]"
                    >
                      Edit
                    </button>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>

      {filteredSites?.length === 0 && (
        <div className="w-[100%] my-[20px] flex justify-center items-center">
          {" "}
          <span className="text-gray-600 font-bold ">No site available</span>
        </div>
      )}
    </div>
  );
}

export default SingleProjectTable;
