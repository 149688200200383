import React from 'react'

function Order() {
  return (
    <div className='w-[90%] mt-[10px] mx-auto  p-4 rounded-[10px]'>
      <div className='flex flex-col'>
            <span className='text-[30px]  font-semibold'>Order Detail</span>
            <span className='text-[12px] text-gray-600'>Hello user,great to see you again</span>
        </div>
        <div className='w-[60%] mx-auto mt-[30px] p-2 bg-white shadow-md rounded-[16px]'>
            <div className='w-[90%] mt-[20px] mx-auto flex justify-between items-center'>
                <div className='flex items-center gap-[10px]'>
                <div className='w-[20px] h-[20px] bg-[#8DA916] rounded-full'></div>
                <span className='font-bold'>Sell</span>
                </div>
                <span className='font-bold'>Order Entry</span>
              
            </div>
            {/* place  order */}
            <div className='w-[90%] mx-auto mt-[30px] p-2 border-b border-gray-200'>
                <span className='text-[25px] font-semibold'>Place your order Entry from here.</span>
                <p className='w-[60%] text-[12px] text-gray-600'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
            </div>
            <div className='w-[90%] mt-[50px] mx-auto flex justify-between'>
                {/* left */}
                    <div className='w-[45%] flex flex-col gap-[20px]'>
                        <div className='w-[100%]  flex flex-col gap-[10px]'>
                            <span className='text-[14px]'>Client ID</span>
                            <div className='bg-gray-100 p-2 rounded-[7px]'>
                            <select className=' w-[100%] mx-auto bg-transparent   text-gray-500 outline-none '>
                                <option value="select ID">Select ID</option>
                                <option value="AE334422">AE334422</option>
                            </select>
                            </div>
                         
                        </div>
                        <div className='flex flex-col gap-[10px]'>
                            <span  className='text-[14px]'>Warehouse</span>
                            <div className='bg-gray-100 p-2 rounded-[7px]'>
                            <select className=' w-[100%] mx-auto bg-transparent  text-gray-500 outline-none '>
                                <option value="Select Warehouse">Select Warehouse</option>
                                <option value="Warehouse 1">Warehouse 1</option>
                            </select>
                            </div>
                        </div>
                        <div className='flex flex-col gap-[10px]'>
                            <span  className='text-[14px]'>Available</span>
                            <div className='bg-gray-100 p-2 rounded-[7px]'>
                            <select className=' w-[100%] mx-auto bg-transparent  text-gray-500 outline-none '>
                                <option value="Available">Available</option>
                                <option value="Warehouse 1">Warehouse 1</option>
                            </select>
                            </div>
                        </div>
                        <div className='flex flex-col gap-[10px]'>
                            <span  className='text-[14px]'>Limit Price</span>
                            <div className='bg-gray-100 p-2 rounded-[7px]'>
                            <select className=' w-[100%] mx-auto bg-transparent  text-gray-500 outline-none '>
                                <option value="Limit Price">Limit Price</option>
                                <option value="Warehouse 1">200$</option>
                            </select>
                            </div>
                        </div>
                    </div>
                {/* right */}
                <div className='w-[45%]'>

                <div className='w-[100%] flex flex-col gap-[20px]'>
                        <div className='w-[100%]  flex flex-col gap-[10px]'>
                            <span className='text-[14px]'>Symbol</span>
                            <div className='bg-gray-100 p-2 rounded-[7px]'>
                            <select className=' w-[100%] mx-auto bg-transparent   text-gray-500 outline-none '>
                                <option value="select ID">Select Symbol</option>
                                <option value="AE334422">-</option>
                            </select>
                            </div>
                         
                        </div>
                        <div className='flex flex-col gap-[10px]'>
                            <span  className='text-[14px]'>Year</span>
                            <div className='bg-gray-100 p-2 rounded-[7px]'>
                            <select className=' w-[100%] mx-auto bg-transparent  text-gray-500 outline-none '>
                                <option value="Select Warehouse">Select Year</option>
                                <option value="Warehouse 1">2023</option>
                            </select>
                            </div>
                        </div>
                        <div className='flex flex-col gap-[10px]'>
                            <span  className='text-[14px]'>Quantity</span>
                            <div className='bg-gray-100 p-2 rounded-[7px]'>
                            <select className=' w-[100%] mx-auto bg-transparent  text-gray-500 outline-none '>
                                <option value="Available">Quantity</option>
                                <option value="Warehouse 1">5</option>
                            </select>
                            </div>
                        </div>
                        <div className='flex flex-col gap-[10px]'>
                            <span  className='text-[14px]'>Validity</span>
                            <div className='w-[80%] mx-auto flex justify-between'>
                            <div className='flex items-center gap-[10px]'>
                                <input type='radio' />
                                <span className='text-gray-600 text-[14px]'>Day</span>
                            </div>
                            <div className='flex items-center gap-[10px]'>
                            <input type='radio' />
                            <span className='text-gray-600 text-[14px]'>GTC</span>
                            </div>
                            <div className='flex items-center gap-[10px]'>
                            <input type='radio' />
                            <span className='text-gray-600 text-[14px]'>GTD</span>

                            </div>
                            </div>
                        </div>
                        </div>

                </div>
            </div>
            <div className='w-[90%] mx-auto my-[50px] flex justify-end items-center gap-[10px]'>
            <button  className='text-[12px] bg-[#FDC00D] p-2 px-4 rounded-[9px]'>Cancel</button>
            <button className='text-[12px] text-white bg-[#1B3E74] p-2 px-4 rounded-[9px]'>Save</button>

        </div>
        </div>
    
    </div>
  )
}
     
export default Order