import React, { useEffect, useState } from "react";
import { FaAngleLeft } from "react-icons/fa";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { deadlineCalculator } from "../../utils/dateSubtract";
import axios from "axios";

function EditProject() {
  const navigate = useNavigate();
  const location = useLocation();
  const { project } = location.state;
  const [branches, setBranches] = useState([]);
  const [name, setName] = useState(project?.name);
  const [branch,setBranch] = useState(project?.branch_id)
  const [numberOfHouses, setNumberOfHouses] = useState(project?.no_houses);
  const [numberOfBlocks, setNumberOfBlocks] = useState(project?.no_blocks);
  const [status, setStatus] = useState("Not Started");
  const [budget, setBudget] = useState(project?.budget);
  const [startDate, setStartDate] = useState(
    project?.start_date?.split("T")[0]
  );
  const [endDate, setEndDate] = useState(project?.end_date?.split("T")[0]);
  const [financial_progress, setFinancialProgress] = useState(
    project?.financial_percentage
  );
  // const [loading, setLoading] = useState(false);
  // const [error, setError] = useState('')
  const [dateError, setDateError] = useState(false);
  const {id} = useParams();
  const [error, setError] = useState(false);
  const [fieldError, setFieldError] = useState(false)

  useEffect(() => {
     
    axios
      .get("https://pms.dalyobt.com/api/view_branch/")
      .then((response) => {
     
      
       
        setBranches(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleSend = async() => {
    const endResult = deadlineCalculator(endDate);
    if (endResult < 0) {
      setDateError(true);
    }
    else{
      if(name && budget && startDate && endDate){
        await axios.put(`https://pms.dalyobt.com/api/update_project/${id}/`, {
          "name":name,"branch_id":branch, "budget": budget, "no_blocks": numberOfBlocks, "no_houses": numberOfHouses, "financial_percentage": financial_progress, "start_date": startDate, "end_date": endDate, "status": status
        }).then((response)=>{
          // console.log(response.data)
         navigate(-1)
        }).catch((error)=>{
          // console.error(error)
          setError(true);
          setFieldError(false)
        })
      }
      else{
        setError(true)
        setFieldError(true)
      }
    }
  };
  return (
    <div className="w-[100%] min-h-[91.5vh] flex flex-col  py-4  bg-white">
      <div className="w-[90%] mx-auto my-[30px] flex justify-between items-center">
        <div className=" flex items-center gap-[30px]">
          <FaAngleLeft
            className="text-[30px] cursor-pointer text-[#0B72B5]"
            onClick={() => navigate("/projects")}
          />
          <span className="text-[20px] text-[#0B72B5] font-bold">
            Edit Project
          </span>
        </div>
        {/* <div>{loading && <p>Loading...</p>}</div> */}
      </div>
      {error && (
        <div className="w-[80%] mx-auto my-2 bg-red-500 text-center p-2 rounded-md">
          {fieldError ? <p className="text-white font-medium">There are some missing fields.</p> : <p className="text-white font-medium">
            Please insert the correct format.
          </p>}
        </div>
      )}
      <div className="w-[80%] mt-[50px] mx-auto flex flex-col gap-[20px]">
        <div className="flex items-center justify-between gap-[10px]">
          <div className="w-[50%]  flex flex-col  items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              Project Name <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="text"
                placeholder="Project-1"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-[100%] border border-[#ced4da] py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>
          <div className="w-[50%]  flex flex-col  items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
             Branch<span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <select
                type="text"
                value={branch}
                placeholder="Project-1"
                onChange={(e) => setBranch(e.target.value)}
                className="w-[100%] border border-[#ced4da] py-3 px-4 rounded-[5px] text-[14px] outline-none"
              >
                <option value={""}>Select branch</option>
                {branches?.map((br,index)=>{
                  return <option key={index} value={br?.id}>{br?.name}</option>
                })}
              </select>
            </div>
          </div>
          <div className="w-[50%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              Budget <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="number"
                value={budget}
                placeholder="5,000,0000"
                onChange={(e) => setBudget(e.target.value)}
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>
        </div>

        <div className="flex justify-between gap-[10px] my-[20px]">
          <div className="w-[90%] flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              Number of Blocks
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="number"
                value={numberOfBlocks}
                placeholder="0"
                onChange={(e) => setNumberOfBlocks(e.target.value)}
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>

          <div className="w-[90%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              Number of Houses
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="number"
                value={numberOfHouses}
                placeholder="0"
                onChange={(e) => setNumberOfHouses(e.target.value)}
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between gap-[10px] my-[20px]">
          <div className="w-[50%] flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              Financial Progress
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="number"
                value={financial_progress}
                placeholder="0"
                onChange={(e) => setFinancialProgress(e.target.value)}
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>

          <div className="w-[50%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              Status
            </span>
            <div className="w-[100%] rounded-[5px]">
              <select
                onChange={(e) => setStatus(e.target.value)}
                // value={status}
                className="w-[100%] border border-[#ced4da]  p-4 rounded-[5px] text-[14px] outline-none"
              >
                <option value={"Ongoing"}>Ongoing</option>
                <option value={"Not started"}>Not Started</option>
                <option value={"Completed"}>Completed</option>
              </select>
            </div>
          </div>
        </div>
        <div className="flex justify-between my-[20px] gap-[10px]">
          <div className="w-[50%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              Start Date <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                placeholder="Ex. 2023-07-08"
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
            {/* {dateError && <span className="text-[14px] text-red-500">Invalid Date</span>} */}
          </div>
          <div className="w-[50%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              End Date <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                placeholder="Ex. 2023-07-08"
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
            {dateError && (
              <span className="text-[14px] text-red-500">Invalid Date</span>
            )}
          </div>
        </div>
      </div>

      <div className="w-[90%] mt-[30px] flex justify-end items-center gap-[20px]">
        <button onClick={() => navigate("/projects")}>Cancel</button>
        <button
          className="py-2 px-4 text-[14px] cursor-pointer bg-[#0B72B5] text-white rounded-[10px]"
          onClick={handleSend}
        >
          Edit Project
        </button>
      </div>
    </div>
  );
}

export default EditProject;
