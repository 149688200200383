import React, { useEffect, useState } from "react";
import { FaAngleLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import ContractorStatus from "./ContractorStatus";
import AssignedContractorTable from "./AssignedContractorTable";
import axios from "axios";

function Contractor() {
  const navigate = useNavigate();
  const [currentTableState, setCurrentTableState] =
    useState("contractor_profile");
  const [contractors, setContractors] = useState([]);
  const [assignedContractors, setAssignedContractors] = useState([]);
  const [error, setError] = useState(false);
  useEffect(() => {
    axios
      .get("https://pms.dalyobt.com/api/contractor_view/")
      .then((response) => {
        setContractors(response.data);
      })
      .catch((error) => {
        setError(true);
        console.log(error);
      });
  }, []);
  useEffect(() => {
    axios
      .get("https://pms.dalyobt.com/api/block_contractor/")
      .then((response) => {
        setAssignedContractors(response.data);
      })
      .catch((error) => {
        setError(true);
        console.log(error);
      });
  }, []);
  // const userGroup = localStorage.getItem('userGroup')
  return (
    <div className="mt-[50px]">
      <div className="w-[80%] mt-6 mx-auto flex justify-between">
        <div className="flex justify-start items-center gap-[20px]">
          <FaAngleLeft
            className="text-[30px] cursor-pointer text-[#0B72B5]"
            onClick={() => navigate("/")}
          />
          <span className="text-[20px] font-bold text-[#0B72B5]">
            Contractors
          </span>
        </div>
        {/* {userGroup?.toString() === "1" && <button onClick={()=>navigate('/projects/addproject')}  className='bg-[#0B72B5] p-4 text-white text-[14px] rounded-[10px]'>Add Project</button>}   */}
      </div>
      {/* Contractors Table */}
      <div className="w-[100%] mt-[20px]  flex justify-center items-center gap-[10px] mx-auto">
        <button
          onClick={() => setCurrentTableState("contractor_profile")}
          className={
            currentTableState === "contractor_profile"
              ? "bg-[#0B72B5] text-white  py-2 px-4  text-[14px] rounded-[5px]"
              : " py-2 px-4 text-black text-[14px] rounded-[5px]"
          }
        >
          Contractor Profile Status
        </button>
        <button
          onClick={() => setCurrentTableState("Assigned_Contractor")}
          className={
            currentTableState === "Assigned_Contractor"
              ? "bg-[#0B72B5] text-white  py-2 px-4  text-[14px] rounded-[5px]"
              : " py-2 px-4 text-black text-[14px] rounded-[5px]"
          }
        >
          Assigned Contractor
        </button>
      </div>

      <div className="w-[95%] max-h-[600px]  overflow-y-auto bg-white border border-gray-300 rounded-md mx-auto mt-[100px] mb-[20px]">
        {currentTableState === "contractor_profile" ? (
          <ContractorStatus contractors={contractors} error={error} />
        ) : (
          <AssignedContractorTable
            contractors={contractors}
            assignedContractors={assignedContractors}
          />
        )}
      </div>
    </div>
  );
}

export default Contractor;
