import React, { useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { BsFillCloudUploadFill } from "react-icons/bs";
function FinancialPost() {
  const [file, setFile] = useState(null);
  const [payCert, setPayCert] = useState(null);
  const [amountFill, setAmountFill] = useState("");
  const [dateFill, setDateFill] = useState("");
  const [error, setError] = useState(false);
  const [fieldError, setFieldError] = useState(false)

  const { id } = useParams();

  const handleCert = async () => {
    if (file && payCert && amountFill && dateFill) {
      const formData = new FormData();

      formData.append("block", id);
      formData.append("reciept_attachement", file);
      formData.append("payment_certificate", payCert);
      formData.append("amount", amountFill);
      formData.append("date_filled", dateFill)

      await axios
        .post(`https://pms.dalyobt.com/api/block_finance/${id}/`, formData)
        .then((response) => {
          // console.log(response.data);
          window.location.href = `/projects/sites/buildings/${id}`
        })
        .catch((error) => {
          console.error(error);
          setError(true);
          setFieldError(false)
        });
    }
    else{
      setError(true)
      setFieldError(true)
    }
  };
 

  return (
     <div className='py-4'>
      {error && (
        <div className="w-[80%] mx-auto my-2 bg-red-500 text-center p-2 rounded-md">
          {fieldError ? <p className="text-white font-medium">There are some missing fields.</p> : <p className="text-white font-medium">
            Please insert the correct format.
          </p>}
        </div>
      )}
        <div className='w-[90%] p-4 mx-auto bg-white'>
          <div className="w-[100%] mt-[20px] flex justify-center items-center">
            <span className="text-[#0B72B5] font-bold">Add New Financial Report</span>
          </div>

        <div className="flex flex-col justify-between my-[20px] gap-[10px]">
          <div className="w-[50%] mt-[30px]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
             Date <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="date"
                onChange={(e) => setDateFill(e.target.value)}
                placeholder="Ex. 2023-07-08"
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
            {/* {dateError && <span className="text-[14px] text-red-500">Invalid Date</span>} */}
          </div>
          <div className="flex mt-[30px] items-center justify-between gap-[10px]">
          <div className="w-[50%]  flex flex-col  items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              Amount <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="number"
                placeholder="250000"
                onChange={(e) => setAmountFill(e.target.value)}
                className="w-[100%] border border-[#ced4da] py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>
         
        </div>
          <div className='w-[100%] mt-[30px] flex flex-col gap-[10px]'>
        <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
             Reciept Attachement <span className="text-red-700">*</span>
            </span>
            <label>
                <input type='file' onChange={(e) => setFile(e.target.files[0])}  hidden />
                <div className='[w-[70%] h-[200px] flex justify-center gap-[10px] text-[#0B72B5] items-center p-4 mx-auto border border-gray-300 rounded border-dashed cursor-pointer text-center'>
                    <BsFillCloudUploadFill />
                    <span className='text-[14px] font-bold'>Upload Reciept Attachement</span>

                </div>
            </label>

        </div>
        <div className="w-[100%] my-4 overflow-y-scroll">
         {/* How to view the uploaded file inside this div */}
       {file && (
          <embed
            src={URL.createObjectURL(file)}
            type="application/pdf"
            width="100%"
            height="600px"
          />
        )}
      </div>
        <div className='w-[100%] mt-[30px] flex flex-col gap-[10px]'>
        <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
             Payment Certificate Attachement <span className="text-red-700">*</span>
            </span>
            <label>
                <input type='file'   onChange={(e) => setPayCert(e.target.files[0])}  hidden />
                <div className='[w-[70%] h-[200px] flex justify-center gap-[10px] text-[#0B72B5] items-center p-4 mx-auto border border-gray-300 rounded border-dashed cursor-pointer text-center'>
                    <BsFillCloudUploadFill />
                    <span className='text-[14px] font-bold'>Upload Payment Certificate Attachement </span>

                </div>
            </label>

        </div>
          <div className="w-[100%] my-4 overflow-y-scroll">
        {/* How to view the uploaded file inside this div */}
        {payCert && (
          <embed
            src={URL.createObjectURL(payCert)}
            type="application/pdf"
            width="100%"
            height="600px"
          />
        )}
      </div>
        
         
          
        </div>
        <div className='w-[100%] mt-[30px] flex justify-end items-center'>
            <button onClick={handleCert} className='bg-[#0B72B5] text-white text-[14px] py-2 px-4 rounded'>Save</button>

        </div>
        </div>
    </div>
    // <div className="w-[90%] max-h-[800px] overflow-y-auto overflow-x-hidden shadow-xl  bg-white my-[20px] py-2  mx-auto p-2 mt-[50px] rounded-[5px] flex flex-col gap-[30px] items-start relative">
    //   {error && (
    //     <div className="w-[80%] mx-auto my-2 bg-red-500 text-center p-2 rounded-md">
    //       {fieldError ? <p className="text-white font-medium">There are some missing fields.</p> : <p className="text-white font-medium">
    //         Please insert the correct format.
    //       </p>}
    //     </div>
    //   )}
    //   <div className="w-[95%]  flex justify-between gap-[30px] mx-auto p-2 mt-[40px]">
    //     <div className="w-[20%] mx-auto flex justify-between items-start gap-5">
    //       <div className="flex flex-col gap-1">
    //         <label
    //           className="block text-[#0B72B5] font-bold mb-2"
    //           htmlFor="file"
    //         >
    //           Reciept Attachement <span className="text-red-700">*</span>
    //         </label>
    //         <label>
    //           <input
    //             type="file"
    //             onChange={(e) => setFile(e.target.files[0])}
    //             placeholder="Reciept Attachement"
    //             hidden
    //           />
    //           <span className="block cursor-pointer bg-[#0B72B5] text-white p-2 text-[14px] rounded font-bold mb-2">
    //             Upload Reciept Attachement{" "}
    //           </span>
    //         </label>
    //       </div>
    //     </div>
    //     <div className="w-[20%] mx-auto flex justify-between items-start gap-5">
    //       <div className="flex flex-col gap-1">
    //         <label
    //           className="block text-[#0B72B5] font-bold mb-2"
    //           htmlFor="file"
    //         >
    //           Payment Certificate Attachement{" "}
    //           <span className="text-red-700">*</span>
    //         </label>
    //         <label>
    //           <input
    //             type="file"
    //             onChange={(e) => setPayCert(e.target.files[0])}
    //             placeholder="Upload Payment Certificate Attachement"
    //             hidden
    //           />
    //           <span className="block cursor-pointer bg-[#0B72B5] text-white p-2 text-[14px] rounded font-bold mb-2">
    //             Upload Payment Certificate Attachement
    //           </span>
    //         </label>
    //       </div>
    //     </div>
    //     <div className="w-[20%]  mx-auto flex-col justify-between items-start">
    //       <span className="text-[#0B72B5] font-bold">
    //         Amount <span className="text-red-700">*</span>
    //       </span>
    //       <input
    //         className="w-[90%] border mt-[10px] border-gray-300 bg-transparent outline-none py-2 px-4 rounded"
    //         type="number"
    //         onChange={(e) => setAmountFill(e.target.value)}
    //         placeholder="250000"
    //       />
    //     </div>
    //     <div className="w-[20%]  mx-auto flex-col justify-between items-start">
    //       <span className="text-[#0B72B5] font-bold">
    //         Date <span className="text-red-700">*</span>
    //       </span>
    //       <input
    //         className="w-[90%] border mt-[10px] border-gray-300 bg-transparent outline-none py-2 px-4 rounded"
    //         type="date"
    //         onChange={(e) => setDateFill(e.target.value)}
    //       />
    //     </div>
    //   </div>

    //   <div className="w-[100%] my-4 overflow-y-scroll">
    //     {/* How to view the uploaded file inside this div */}
    //     {file && (
    //       <embed
    //         src={URL.createObjectURL(file)}
    //         type="application/pdf"
    //         width="100%"
    //         height="600px"
    //       />
    //     )}
    //   </div>

    //   <div className="w-[100%] my-4 overflow-y-scroll">
    //     {/* How to view the uploaded file inside this div */}
    //     {payCert && (
    //       <embed
    //         src={URL.createObjectURL(payCert)}
    //         type="application/pdf"
    //         width="100%"
    //         height="600px"
    //       />
    //     )}
    //   </div>

    //   <div className="w-[90%] mt-[30px] flex justify-end items-center ">
    //     <button
    //       onClick={handleCert}
    //       className="bg-[#0B72B5] p-2 text-white font-medium rounded-md"
    //     >
    //       Save
    //     </button>
    //   </div>
    // </div>
  );
}

export default FinancialPost;
