import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Stack } from "@mui/material";

export default function BasicSelect(props) {
  const { contractors } = props;
  const handleChange = (event) => {
    props.setState(event.target.value);
  };

  return (
    <Stack direction="row" justifyContent="flex-end" mt={2} mb={2} pt={5} mr={7}>
      <FormControl sx={{ minWidth: 200 }}>
        <InputLabel id="demo-simple-select-label">Assign Contractor</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={props.selectedContractor}
          label="Assign Contractor"
          onChange={handleChange}
        >
          {contractors.map((contractor) => (
            <MenuItem value={contractor} key={contractor}>
              {contractor}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
}
