import axios from "axios";
import React, { useState } from "react";
import { BsFillCloudUploadFill } from "react-icons/bs";
import { useParams } from "react-router-dom";

function TimeExpansion() {
  const [extDate, setExtDate] = useState("");
  const [attachJustification, setAttachJustification] = useState(null);
  const [attachFile, setAttachFile] = useState(null);
  const [remarkFill, setRemarkFill] = useState("");
  const userID = localStorage.getItem("userID");
  const [error, setError] = useState(false);
  const [fieldError, setFieldError] = useState(false);
  const { id } = useParams();
  // console.log(blockID)

  const handleSubmit = async () => {
    if (extDate && attachFile && attachJustification) {
      const formData = new FormData();

      formData.append("justification_attachment", attachJustification);
      formData.append("extension_date", extDate);
      formData.append("attachment", attachFile);
      formData.append("remark", remarkFill);
      formData.append("prepared_by", userID);
      formData.append("block", id);
      // formData.append("approved_by", 20);
      // formData.append("approved_by", null)

      await axios
        .post("https://pms.dalyobt.com/api/time_extend/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          // console.log(response.data.Message);
          window.location.href = `/projects/sites/buildings/${id}`;
        })
        .catch((error) => {
          console.error(error);
          setError(true);
          setFieldError(false);
        });
    } else {
      setError(true);
      setFieldError(true);
    }
  };

  return (
    <div className="py-4">
      <div className="w-[90%] p-4 mx-auto bg-white">
        <div className="w-[100%] mt-[20px] flex justify-center items-center">
          <span className="text-[#0B72B5] font-bold">Time Extension</span>
        </div>

        {error && (
          <div className="w-[80%] mx-auto my-2 bg-red-500 text-center p-2 rounded-md">
            {fieldError ? (
              <p className="text-white font-medium">
                There are some missing fields.
              </p>
            ) : (
              <p className="text-white font-medium">
                Please insert the correct format.
              </p>
            )}
          </div>
        )}

        <div className="w-[100%] mt-[30px] flex flex-col gap-[10px]">
          <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
            Justification <span className="text-red-700">*</span>
          </span>
          <label>
            <input
              type="file"
              onChange={(e) => setAttachJustification(e.target.files[0])}
              hidden
            />
            <div className="[w-[70%] h-[200px] flex justify-center gap-[10px] text-[#0B72B5] items-center p-4 mx-auto border border-gray-300 rounded border-dashed cursor-pointer text-center">
              <BsFillCloudUploadFill />
              <span className="text-[14px] font-bold">Upload Attachement</span>
            </div>
          </label>
        </div>

        <div className="w-[100%] my-4 overflow-y-scroll">
          {/* How to view the uploaded file inside this div */}
          {attachJustification && (
            <embed
              src={URL.createObjectURL(attachJustification)}
              type="application/pdf"
              width="100%"
              height="600px"
            />
          )}
        </div>

        <div className="flex flex-col justify-between my-[20px] gap-[10px]">
          <div className="w-[50%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              Extend Date <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="date"
                onChange={(e) => setExtDate(e.target.value)}
                // onChange={(e) => setStartDate(e.target.value)}
                placeholder="Ex. 2023-07-08"
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
            {/* {dateError && <span className="text-[14px] text-red-500">Invalid Date</span>} */}
          </div>
          {/* <div className="flex mt-[30px] items-center justify-between gap-[10px]">
            <div className="w-[50%]  flex flex-col  items-start gap-[20px]">
              <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
                Prepared By <span className="text-red-700">*</span>
              </span>
              <div className="w-[100%] rounded-[5px]">
                <input
                  type="text"
                  placeholder="Mr.Y"
                  // onChange={(e) => setName(e.target.value)}
                  className="w-[100%] border border-[#ced4da] py-3 px-4 rounded-[5px] text-[14px] outline-none"
                />
              </div>
            </div>
            <div className="w-[50%]  flex flex-col items-start gap-[20px]">
              <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
                Approved By <span className="text-red-700">*</span>
              </span>
              <div className="w-[100%] rounded-[5px]">
                <input
                  type="text"
                  placeholder="Mr.X"
                  // onChange={(e) => setBudget(e.target.value)}
                  className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
                />
              </div>
            </div>
          </div> */}

          <div className="w-[100%] mt-[30px] flex flex-col gap-[10px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              Attachement <span className="text-red-700">*</span>
            </span>
            <label>
              <input
                type="file"
                onChange={(e) => setAttachFile(e.target.files[0])}
                hidden
              />
              <div className="[w-[70%] h-[200px] flex justify-center gap-[10px] text-[#0B72B5] items-center p-4 mx-auto border border-gray-300 rounded border-dashed cursor-pointer text-center">
                <BsFillCloudUploadFill />
                <span className="text-[14px] font-bold">
                  Upload Attachement
                </span>
              </div>
            </label>
          </div>
          <div className="w-[100%] my-4 overflow-y-scroll">
            {/* How to view the uploaded file inside this div */}
            {attachFile && (
              <embed
                src={URL.createObjectURL(attachFile)}
                type="application/pdf"
                width="100%"
                height="600px"
              />
            )}
          </div>
          <div className="w-[100%]  flex flex-col  items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              Remark
            </span>
            <div className="w-[100%] rounded-[5px]">
              <textarea
                rows={10}
                placeholder="Remark"
                onChange={(e) => setRemarkFill(e.target.value)}
                // onChange={(e) => setName(e.target.value)}
                className="w-[100%] resize-none border border-[#ced4da] py-3 px-4 rounded-[5px] text-[14px] outline-none"
              ></textarea>
            </div>
          </div>
        </div>
        <div className="w-[100%] mt-[30px] flex justify-end items-center">
          <button
            onClick={handleSubmit}
            className="bg-[#0B72B5] cursor-pointer text-white text-[14px] py-2 px-4 rounded"
          >
            Extend Date
          </button>
        </div>
      </div>
    </div>
  );
}

export default TimeExpansion;
