import React, { useEffect, useState } from "react";
// import StaffTable from './StaffTable'
import { useNavigate } from "react-router-dom";
import { FaAngleLeft } from "react-icons/fa";
import StaffTable from "./StaffTable";
// import { staff } from "../../utils/data";
import axios from "axios";

function Staff() {
  const navigate = useNavigate();
  const [id, setID] = useState("");
  const [username, setUsername] = useState("");
  //   const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [filteredStaffs, setFilteredStaffs] = useState([]);
  const [staff, setStaff] = useState([]);
  const [choice, setChoice] = useState([]);
  const [grp, setGrp] = useState([]);

  const userGroup = localStorage.getItem("userGroup");

  useEffect(() => {
    if (userGroup === "1") setChoice(["Senior Engineer", "Junior Engineer"]);
    else if (userGroup === "5")
      setChoice([
        "Executive 1",
        "Executive 2",
        "Executive 3",
        "project manager",
      ]);
    else {
      setChoice([]);
    }
  }, [userGroup]);

  useEffect(() => {
    axios
      .get("https://pms.dalyobt.com/api/users/")
      .then((response) => {
        setStaff(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    axios
      .get("https://pms.dalyobt.com/api/groups/")
      .then((response) => {
        setGrp(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  //   console.log(staff)

  useEffect(() => {
    const filterID = staff.filter((i) => {
      return i.id.toString() === id.toString();
    });
    const filterUsername = staff.filter((i) => {
      // console.log(username, i.username)
      // console.log(i.username === username, "Hellllo")
      return i.username === username;
    });
    // console.log("username: ", filterUsername);

    // const filterEmail = staff.filter((i) => {
    //   return i.email === email;
    // });
    // console.log("Email: ", filterEmail);

    const filterRole = staff.filter((i) => {
      // console.log(role, "Front")
      // console.log(i?.groups[0], "Groups")
      const gNames = grp.find((item) => item?.id === i?.groups[0]);
      // console.log(gNames,"Names")
      return gNames?.name === role;
    });
    // console.log("Role: ", filterRole);

    const concat = filterUsername.concat(filterID, filterRole);
    const removeDupilcate = new Set(concat);
    const uniqueStaffs = Array.from(removeDupilcate);
    // console.log(uniqueStaffs)
    setFilteredStaffs(uniqueStaffs);
  }, [role, username, id, staff]);

  return (
    <div className="mt-[50px]">
      <div className="w-[90%] mt-6 mx-auto flex justify-between">
        <div className="flex justify-start items-center gap-[20px]">
          <FaAngleLeft
            className="text-[30px] cursor-pointer text-[#0B72B5]"
            onClick={() => navigate("/")}
          />
          <span className="text-[20px] font-bold text-[#0B72B5]">Staff</span>
        </div>
        <button
          onClick={() => navigate("/staff/addstaff")}
          className="bg-[#0B72B5] py-2 px-4 text-white text-[14px] rounded-[10px]"
        >
          Add Staff
        </button>
      </div>
      <div className="w-[80%] mt-[50px] mx-auto flex items-center gap-[10px] bg-white p-2">
        <div className="w-[80%] mx-auto  flex justify-start">
          <input
            type="text"
            placeholder="Search by ID"
            onChange={(e) => setID(e.target.value)}
            className="bg-gray-100  p-3 w-[100%] rounded-[5px] text-[14px] outline-none text-gray-500"
          />
        </div>

        <div className="w-[80%] mx-auto  flex justify-start">
          <input
            type="text"
            placeholder="Search by username"
            onChange={(e) => setUsername(e.target.value)}
            className="bg-gray-100  p-3 w-[100%] rounded-[5px] text-[14px] outline-none text-gray-500"
          />
        </div>
        {/* <div className="w-[80%] mx-auto  flex justify-start">
          <input
            type="text"
            placeholder="Search by email"
            onChange={(e) => setEmail(e.target.value)}
            className="bg-gray-100  p-3 w-[100%] rounded-[5px] text-[14px] outline-none text-gray-500"
          />
        </div> */}

        <div className="w-[80%] mx-auto  flex justify-start p-2">
          <select
            onChange={(e) => setRole(e.target.value)}
            className="bg-gray-100   p-3 w-[100%] rounded-[5px] text-[14px] outline-none text-gray-500"
          >
            <option>Choose Roles</option>
            {choice?.map((item, index) => {
              return <option key={index}>{item}</option>;
            })}
            {/* <option>choose role</option>
            <option>Junior</option>
            <option>Senior</option> */}
          </select>
          {/* <input type='text'  placeholder='Search by staff role'  className='bg-gray-200  p-3 w-[100%] rounded-[5px] text-[14px] outline-none text-gray-500' /> */}
        </div>
      </div>

      <div className="bg-white w-[90%] mx-auto p-4 rounded-[10px] mb-10 mt-[50px] border border-gray-300">
        <div className="mt-[50px]">
          <StaffTable
            staffs={filteredStaffs.length === 0 ? staff : filteredStaffs}
          />
        </div>
      </div>
    </div>
  );
}

export default Staff;
