import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { FaAngleLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

function BranchList() {
    const navigate = useNavigate();
    const userGroup = localStorage.getItem("userGroup");
    const [branches, setBranches] = useState([]);
    const [search, setSearch] = useState("");

    useEffect(() => {
       
        axios
          .get("https://pms.dalyobt.com/api/view_branch/")
          .then((response) => {
         
            
           
            setBranches(response.data);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      }, []);

      const filteredBranches = branches?.filter((b) => {
        return (
          b?.name?.includes(search) ||
          b?.name?.includes(search.toUpperCase()) ||
          b?.name?.includes(search.toLowerCase())
        );
      });


    
  return (
    <div className='w-[95%] min-h-[700px]  p-2 mx-auto my-[20px] bg-white rounded-[20px]'>
        <div className='w-[90%] mx-auto my-[20px] flex justify-between items-center border-b border-gray-300 p-2'>
        <div className="flex justify-start items-center gap-[20px]">
          <FaAngleLeft
            className="text-[30px] cursor-pointer text-[#0B72B5]"
            onClick={() => navigate(-1)}
          />
          <span className="text-[20px] font-bold text-[#0B72B5]">Branches</span>
        </div>
        {userGroup?.toString() === "1" && (
          <button
            onClick={() => navigate("/branch/create-branch")}
            className="bg-[#0B72B5] py-2 px-4 text-white text-[14px] rounded-[10px]"
          >
            Add Branch
          </button>
        )}
        </div>

        <div className="w-[100%] mt-[50px] flex justify-center">
        <input
          type="text"
          value={search}
          placeholder="Search by branch name"
          onChange={(e) => setSearch(e.target.value)}
          className="bg-white border border-gray-400 p-3 w-[40%] rounded-[5px] text-[14px] outline-none text-gray-500 max-[1000px]:w-[80%]"
        />
      </div>

        {/* branch list */}

        <table className="w-full my-[30px] overflow-x-auto text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-center text-gray-700 uppercase border-b border-gray-300">
          <tr>
            
            <th scope="col" className="px-6 py-3">
              Branch Name
            </th>
            
            <th scope="col" className="px-6 py-3">
              Physical Progress
            </th>
          
           
            <th scope="col" className="px-6 py-3">
              Status
            </th>
           

            {userGroup?.toString() === "1" && (
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {filteredBranches?.map((branch) => {
      
      ;

            return (
              <tr
              onClick={()=>navigate(`/branch/projects/${branch?.id}`)}
                key={branch?.id}
                className={"text-center text-gray-600 border-b border-gray-300 cursor-pointer"}
              >
                
                <td
                  className={
                  
                      "px-6 py-4"
                  }
                >
                  <span> {branch?.name}</span>
                
                </td>
               
                <td
                  className={
                   "px-6 py-4"
                  }
                >
                  {(branch?.progress_percentage) + "%"}
                </td>
              
                
              
                <td
                  className={
                   "px-6 py-4"
                  }
                >
                    {branch?.status ==="active" && <span className='text-[14px] py-2 px-4 bg-green-600 text-white rounded-[5px]'>active</span>}
                    {branch?.status ==="inactive" && <span className='text-[14px] py-2 px-4 bg-red-600 text-white rounded-[5px]'>inactive</span>}
                </td>

            
              

                {userGroup?.toString() === "1" && (
                  <td className="flex justify-center  items-center px-6 py-4 space-x-3">
                    <button
                      onClick={(e) =>{
                        e.stopPropagation();
                        navigate(`/branch/edit/${branch?.id}`, {
                          state: {
                            branch,
                          },
                        })
                      }
                      }
                      className="bg-[#0B72B5] text-white rounded-[5px] p-2"
                    >
                      Edit
                    </button>

                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>


    </div>
  )
}

export default BranchList