import React from 'react'
import {Button} from '@mui/material'

function CreateAccount({toogleSignIn,settoggleSignIn}) {
  return (
    <div className='w-full h-[100vh] flex justify-center items-center'>
    <div className='w-[50%] h-[70%] bg-white rounded-[45px] shadow-xl flex gap-[10px]'>
        <div className='w-[50%] h-[100%] bg-[#0B72B5] rounded-tl-[45px] rounded-bl-[45px] '>
        <div className='w-[90%] h-[65%] mx-auto  flex flex-col items-center justify-around'>
       
       <div className='relative  bg-white rounded-full mt-[20px]'>
    
      <img src={require('../Images/logo_crop.jpg')} alt="" className='w-[150px] h-[150px] rounded-full object-cover'/>

    
      

       </div>
        
                <div className='text-center text-white w-[80%]'>
                    <span className='text-[24px] font-bold'>Create an Account</span>
                    <p className='text-[12px] my-[10px]'>To stay connected login back to your account with your credentials.</p>

                </div>
                <div className='w-[60%]'>
                <Button onClick={()=>settoggleSignIn(!toogleSignIn)}   sx={{width:"100%", border:'1px solid white', color:"white",borderRadius:'10px'}}>Sign In</Button>
                </div>
        </div>
             

        </div>
        <div className='w-[70%] h-[100%] flex flex-col gap-[20px] justify-center items-center'>
            
                <div className='  flex flex-col items-center gap-[10px]'>
                    <span className='text-[30px] font-bold'>Let’s Get Started.</span>
                    <span className='text-[14px]'>Create An Account.</span>
                    <div className='w-[50%] h-[2px] bg-[#adadad] flex justify-end items-center'>
                        <div className='w-[30%] h-[4px] bg-[#FDC00D]'></div>
                    </div>
                </div>
                <div className='w-[80%] mt-[50px]  flex flex-col items-center gap-[10px]  '>
                   
                <div className='w-[80%]  flex items-center justify-between gap-[10px]  bg-[#f0f0f0] p-[15px]  rounded-[5px] border border-[#FDC00D]'>
                    <input type='text' placeholder='Username' className='bg-transparent text-[14px] text-gray-400 flex-auto outline-none' />
                  
                    </div>   
                   <div className='w-[80%]  flex items-center justify-between gap-[10px]  bg-[#f0f0f0] p-[15px]  rounded-[5px] border border-[#FDC00D]'>
                    <input type='text' placeholder='Email' className='bg-transparent text-[14px] text-gray-400 flex-auto outline-none' />
                   

                    </div>
                    <div className='w-[80%]  flex items-center justify-between gap-[10px]  bg-[#f0f0f0] p-[15px]  rounded-[5px]  border border-[#FDC00D]'>
                    <input type='password' placeholder='Password' className='bg-transparent text-[14px] text-gray-400 flex-auto outline-none' />
                 

                    </div>
                    <div className='w-[80%]  flex items-center justify-between gap-[10px]  bg-[#f0f0f0] p-[15px]  rounded-[5px]  border border-[#FDC00D]'>
                    <input type='password' placeholder='Confirm password' className='bg-transparent text-[14px] text-gray-400 flex-auto outline-none' />
                 

                    </div>
                   

                </div>
                <div className='w-[65%]'>
               <button className='w-[100%] bg-[#FDC00D] text-white p-[0.7rem] rounded-[10px] text-[14px]'>Signup</button>
                </div>


          


        </div>

    </div>

</div>
  )
}

export default CreateAccount