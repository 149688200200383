import { createSlice } from "@reduxjs/toolkit";

const sideBarSlice = createSlice({
  name: "sidebar",
  initialState: {
   sidebarClosed:true
  },
  reducers: {
    toggleSidebar (state,action){
        const stateValue = action.payload;
        state.sidebarClosed = stateValue
    }
  
  },
});

export const sideBarAction = sideBarSlice.actions;

export default sideBarSlice;