import axios from "axios";
import React, { useState } from "react";
import { BsFillCloudUploadFill } from "react-icons/bs";
import { FaAngleLeft } from "react-icons/fa";
// import { useNavigate } from "react-router-dom";

function AddBuilding({ addBuilding, setAddbuilding }) {
  // const navigate = useNavigate();
  const siteID = localStorage.getItem("siteID");
  // const projectID = localStorage.getItem("projectID");

  // const [name, setName] = useState("");
  // const [numOfHouses, setNumberOfHouses] = useState(0);
  // const [packages, setPackages] = useState("");
  // const [startDate, setStartDate] = useState("");
  // const [endDate, setEndDate] = useState("");
  // const [area, setArea] = useState(0);
  // const [address, setAddress] = useState("");
  // const [location, setLocation] = useState("");
  // const [typeName, setTypeName] = useState("");
  const [error, setError] = useState(false);
  // const [price, setPrice] = useState("");
  // const [designDocuments, setDesignDocuments] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    name: '',
    location: '',
    xcoordinate:'',
    ycoordinate:'',
    area: '',
    address: '',
    numberOfFloors: '',
    package: '',
    // typeName: '',
    price: '',
    soil:null,
    sitePlan:null,
    arch:null,
    boq:null
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: files ? files[0] : value,
    });
  };

  const handleNext = () => {
    setCurrentStep((prevStep) => Math.min(prevStep + 1, 3));
  };

  const handlePrev = () => {
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 1));
  };

  const handleSend = async () => {
    if (formData.name  && formData.price && formData.typeName && formData.designDocuments) {
      const formData = new FormData();

      formData.append("name",formData.name);
      // formData.append("start_date", startDate);
      // formData.append("end_date", endDate);
      formData.append("no_floors",formData.numOfHouses);
      formData.append("location", formData.location);
      formData.append("address",formData.address);
      formData.append("area",formData.area);
      // formData.append("type_name", formData.typeName);
      formData.append("status", "new");
      formData.append("progress_percentage", 0.0);
      formData.append("package",formData.packages);
      formData.append("site_id",formData.siteID);
      formData.append("boq_id", "");
      formData.append("price", parseFloat(formData.price));
      formData.append("design_documents",formData.designDocuments);

      await axios
        .post("https://pms.dalyobt.com/api/view_block/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          // console.log(response.data);
          window.location.href = `/projects/sites/${siteID}`;
        })
        .catch((error) => {
          setError(true);
          // console.error(error);
        });
    } else {
      setError(true);
    }
  };

  return (
    <div className=" w-[90%]  min-h-[700px] my-[70px] flex flex-col  py-4 top-[10%]  bg-white shadow-xl absolute left-[5%] rounded-[10px] max-[1000px]:w-[95%] max-[1000px]:left-[2.5%]">
      <div className="w-[90%] my-[30px] mx-auto flex items-center gap-[30px]">
        <FaAngleLeft
          className="text-[30px] cursor-pointer text-[#0B72B5]"
          onClick={() => setAddbuilding(false)}
        />
        <span className="text-[20px] text-[#0B72B5] font-bold">
          Add new block
        </span>
      </div>
      {error && (
        <div className="w-[80%] mx-auto my-2 bg-red-500 text-center p-2 rounded-md">
          <p className="text-white font-medium">
            Please Check the fields again there might be a missing field.
          </p>
        </div>
      )}
      <div className="w-[80%] my-[50px] mx-auto flex flex-col gap-[20px]">
      {currentStep === 1 && (
        <div>
          <div className="flex justify-between my-[20px] items-center">
            <div className="w-[40%] flex flex-col items-start gap-[20px]">
              <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
                Block Name <span className="text-red-700">*</span>
              </span>
              <div className="w-[100%] rounded-[5px]">
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Block-1"
                  className="w-[100%] required border border-[#ced4da] py-3 px-4 rounded-[5px] text-[14px] outline-none"
                />
              </div>
            </div>

            <div className="w-[40%] flex flex-col items-start gap-[20px]">
              <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
                Location
              </span>
              <div className="w-[100%] rounded-[5px]">
                <input
                  type="text"
                  name="location"
                  value={formData.location}
                  onChange={handleChange}
                  placeholder="Location"
                  className="w-[100%] required border border-[#ced4da] py-3 px-4 rounded-[5px] text-[14px] outline-none"
                />
              </div>
            </div>
          </div>
          <div className="flex justify-between my-[20px] items-center">
            <div className="w-[40%] flex flex-col items-start gap-[20px]">
              <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
            Coordinate (x)<span className="text-red-700">*</span>
              </span>
              <div className="w-[100%] rounded-[5px]">
                <input
                  type="text"
                  name="xcoordinate"
                  value={formData.xcoordinate}
                  onChange={handleChange}
                  placeholder="Y coordinate"
                  className="w-[100%] required border border-[#ced4da] py-3 px-4 rounded-[5px] text-[14px] outline-none"
                />
              </div>
            </div>

            <div className="w-[40%] flex flex-col items-start gap-[20px]">
              <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
                Coordinate (y) <span className="text-red-700">*</span>
              </span>
              <div className="w-[100%] rounded-[5px]">
                <input
                  type="text"
                  name="ycoordinate"
                  value={formData.ycoordinate}
                  onChange={handleChange}
                  placeholder="X coordinate"
                  className="w-[100%] required border border-[#ced4da] py-3 px-4 rounded-[5px] text-[14px] outline-none"
                />
              </div>
            </div>
          </div>
          
          

          <div className="flex justify-between my-[20px]">
            <div className="w-[40%] flex flex-col items-start gap-[20px]">
              <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
                Area
              </span>
              <div className="w-[100%] rounded-[5px]">
                <input
                  type="number"
                  name="area"
                  min={0}
                  value={formData.area}
                  onChange={handleChange}
                  placeholder="Area"
                  className="w-[100%] border border-[#ced4da] py-3 px-4 rounded-[5px] text-[14px] outline-none"
                />
              </div>
            </div>

            <div className="w-[40%] flex flex-col items-start gap-[20px]">
              <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
                Address
              </span>
              <div className="w-[100%] rounded-[5px]">
                <input
                  type="text"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  placeholder="Address"
                  className="w-[100%] border border-[#ced4da] py-3 px-4 rounded-[5px] text-[14px] outline-none"
                />
              </div>
            </div>
          </div>
          <div className="flex justify-between my-[20px]">
          <div className="w-[40%] flex flex-col items-start gap-[20px]">
              <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
                Number Of Floors
              </span>
              <div className="w-[100%] rounded-[5px]">
                <input
                  type="number"
                  name="numberOfFloors"
                  value={formData.numberOfFloors}
                  onChange={handleChange}
                  placeholder="Number Of Floors"
                  className="w-[100%] border border-[#ced4da] py-3 px-4 rounded-[5px] text-[14px] outline-none"
                />
              </div>
            </div>
            <div className="w-[40%] flex flex-col items-start gap-[20px]">
              <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
                Package
              </span>
              <div className="w-[100%] rounded-[5px]">
                <input
                  type="text"
                  name="package"
                  value={formData.package}
                  onChange={handleChange}
                  placeholder="Package"
                  className="w-[100%] border border-[#ced4da] py-3 px-4 rounded-[5px] text-[14px] outline-none"
                />
              </div>
            </div>
          </div>
          {/* <div className="w-[100%] flex flex-col items-start gap-[20px]">
              <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
                Type Name <span className="text-red-700">*</span>
              </span>
              <div className="w-[100%] rounded-[5px]">
                <input
                  type="text"
                  name="typeName"
                  value={formData.typeName}
                  onChange={handleChange}
                  placeholder="E.g 20/80"
                  className="w-[100%] border border-[#ced4da] py-3 px-4 rounded-[5px] text-[14px] outline-none"
                />
              </div>
            </div> */}
        </div>
      )}

      {/* {currentStep === 2 && (
        <div>
         
          <div className="flex justify-between my-[20px]">
            <div className="w-[100%] flex flex-col items-start gap-[20px]">
              <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
                Type Name <span className="text-red-700">*</span>
              </span>
              <div className="w-[100%] rounded-[5px]">
                <input
                  type="text"
                  name="typeName"
                  value={formData.typeName}
                  onChange={handleChange}
                  placeholder="E.g 20/80"
                  className="w-[100%] border border-[#ced4da] py-3 px-4 rounded-[5px] text-[14px] outline-none"
                />
              </div>
            </div>

            <div className="w-[40%] flex flex-col items-start gap-[20px]">
              <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
                Price <span className="text-red-700">*</span>
              </span>
              <div className="w-[100%] rounded-[5px]">
                <input
                  type="number"
                  name="price"
                  min={0}
                  value={formData.price}
                  onChange={handleChange}
                  placeholder="Price"
                  className="w-[100%] border border-[#ced4da] py-3 px-4 rounded-[5px] text-[14px] outline-none"
                />
              </div>
            </div>
          </div>
        </div>
      )} */}

      {currentStep === 2 && (
        <div className="w-[100%]">
          <div>
          <span className="flex justify-center text-center font-bold w-[80%] text-[#0B72B5]">
              Design Documents 
            </span>
          </div>
          <div className="w-[100%] mt-[30px] flex flex-col gap-[10px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
            Soil <span className="text-red-700">*</span>
            </span>
            <label>
              <input
                type="file"
                name="soil"
                onChange={handleChange}
                hidden
              />
              <div className="w-[100%] h-[200px] flex justify-center gap-[10px] text-[#0B72B5] items-center p-4 mx-auto border border-gray-300 rounded border-dashed cursor-pointer text-center">
                <BsFillCloudUploadFill />
                <span className="text-[14px] font-bold">Upload Attachment</span>
              </div>
            </label>
          </div>

          <div className="w-[100%] my-4 overflow-y-scroll">
            {formData.soil && (
              <embed
                src={URL.createObjectURL(formData.soil)}
                type="application/pdf"
                width="100%"
                height="600px"
              />
            )}
          </div>
          <div className="w-[100%] mt-[30px] flex flex-col gap-[10px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
            Site plan <span className="text-red-700">*</span>
            </span>
            <label>
              <input
                type="file"
                name="sitePlan"
                onChange={handleChange}
                hidden
              />
              <div className="w-[100%] h-[200px] flex justify-center gap-[10px] text-[#0B72B5] items-center p-4 mx-auto border border-gray-300 rounded border-dashed cursor-pointer text-center">
                <BsFillCloudUploadFill />
                <span className="text-[14px] font-bold">Upload Attachment</span>
              </div>
            </label>
          </div>
          
          <div className="w-[100%] my-4 overflow-y-scroll">
            {formData.sitePlan && (
              <embed
                src={URL.createObjectURL(formData.sitePlan)}
                type="application/pdf"
                width="100%"
                height="600px"
              />
            )}
          </div>
          <div className="w-[100%] mt-[30px] flex flex-col gap-[10px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
         Architectural <span className="text-red-700">*</span>
            </span>
            <label>
              <input
                type="file"
                name="arch"
                onChange={handleChange}
                hidden
              />
              <div className="w-[100%] h-[200px] flex justify-center gap-[10px] text-[#0B72B5] items-center p-4 mx-auto border border-gray-300 rounded border-dashed cursor-pointer text-center">
                <BsFillCloudUploadFill />
                <span className="text-[14px] font-bold">Upload Attachment</span>
              </div>
            </label>
          </div>
            
          <div className="w-[100%] my-4 overflow-y-scroll">
            {formData.arch && (
              <embed
                src={URL.createObjectURL(formData.arch)}
                type="application/pdf"
                width="100%"
                height="600px"
              />
            )}
          </div>
        </div>
      )}

      {currentStep ===3 && <div className="flex flex-col gap-[10px]">
        <div className="w-[100%] mt-[30px] flex flex-col gap-[10px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
         Upload BOQ <span className="text-red-700">*</span>
            </span>
            <label>
              <input
                type="file"
                name="boq"
                onChange={handleChange}
                hidden
              />
              <div className="w-[100%] h-[200px] flex justify-center gap-[10px] text-[#0B72B5] items-center p-4 mx-auto border border-gray-300 rounded border-dashed cursor-pointer text-center">
                <BsFillCloudUploadFill />
                <span className="text-[14px] font-bold">Upload Attachment</span>
              </div>
            </label>
          </div>
          <div className="w-[100%] my-4 overflow-y-scroll">
            {formData.boq && (
              <embed
                src={URL.createObjectURL(formData.boq)}
                type="application/pdf"
                width="100%"
                height="600px"
              />
            )}
          </div>
        </div>}

      {formData.name !=="" && formData.xcoordinate && formData.ycoordinate &&    <div className="flex justify-end gap-[10px] mt-[20px]">
        {currentStep > 1 && (
          <button
            onClick={handlePrev}
            className="px-4 py-2 bg-gray-300 text-gray-700 rounded"
          >
            Previous
          </button>
        )}
        {currentStep < 3 && (
          <button
            onClick={handleNext}
            className="px-4 py-2 bg-[#0B72B5] text-white rounded"
          >
            Next
          </button>
        )}
      </div>
}
    
        {/* <div className="flex justify-between my-[20px] items-center">
          <div className="w-[40%]  flex flex-col  items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              Block Name <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="text"
                onChange={(e) => setName(e.target.value)}
                placeholder="Block-1"
                className="w-[100%] required border border-[#ced4da] py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>

          <div className="w-[40%]  flex flex-col  items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              Location
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="text"
                onChange={(e) => setLocation(e.target.value)}
                placeholder="Location"
                className="w-[100%] required border border-[#ced4da] py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>
        </div> */}

        {/* <div className="flex justify-between my-[20px]">
          <div className="w-[40%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              Area
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="number"
                min={0}
                onChange={(e) => setArea(e.target.value)}
                placeholder="Area"
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>
          <div className="w-[40%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              Address
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="text"
                onChange={(e) => setAddress(e.target.value)}
                placeholder="Address"
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>
        </div> */}
        {/* <div className="flex justify-between my-[20px]">
          <div className="w-[40%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              Start Date <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="date"
                onChange={(e) => setStartDate(e.target.value)}
                placeholder="Ex. 2023-07-08"
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>
          <div className="w-[40%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              End Date <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="date"
                onChange={(e) => setEndDate(e.target.value)}
                placeholder="Ex. 2023-07-08"
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>
        </div> */}
      </div>

      {/* <div className="w-[80%] mt-[50px] mx-auto flex flex-col gap-[20px]">
        <div className="flex justify-between my-[20px]">
          <div className="w-[40%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              Number Of Floors
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="number"
                onChange={(e) => setNumberOfHouses(e.target.value)}
                placeholder="Number Of Floors"
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>
          <div className="w-[40%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              Package
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="text"
                onChange={(e) => setPackages(e.target.value)}
                placeholder="Packages"
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>
        </div>

        <div className="flex justify-between my-[20px]">
          <div className="w-[40%] flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              Type Name <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="text"
                onChange={(e) => setTypeName(e.target.value)}
                placeholder="E.g 20/80"
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>
          <div className="w-[40%]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              Price <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="number"
                min={0}
                onChange={(e) => setPrice(e.target.value)}
                placeholder="Price"
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>
        </div>

        <div className="w-[100%] mt-[30px] flex flex-col gap-[10px]">
          <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
            Design Documents <span className="text-red-700">*</span>
          </span>
          <label>
            <input
              type="file"
              onChange={(e) => setDesignDocuments(e.target.files[0])}
              hidden
            />
            <div className="[w-[70%] h-[200px] flex justify-center gap-[10px] text-[#0B72B5] items-center p-4 mx-auto border border-gray-300 rounded border-dashed cursor-pointer text-center">
              <BsFillCloudUploadFill />
              <span className="text-[14px] font-bold">Upload Attachement</span>
            </div>
          </label>
        </div>

        <div className="w-[100%] my-4 overflow-y-scroll">
          
          {designDocuments && (
            <embed
              src={URL.createObjectURL(designDocuments)}
              type="application/pdf"
              width="100%"
              height="600px"
            />
          )}
        </div>
      </div> */}
      {formData.name !=="" && formData.xcoordinate !=="" && formData.ycoordinate !==""  && formData.typeName !=="" && formData.soil && formData.sitePlan && formData.arch && formData.boq &&   <div className="w-[90%] mt-[30px] flex justify-end items-center gap-[20px]">
        <button onClick={() => setAddbuilding(false)}>Cancel</button>
        <button
          onClick={handleSend}
          className="py-2 px-4 cursor-pointer text-[14px] bg-[#0B72B5] text-white rounded-[10px]"
        >
          Add Block
        </button>
      </div>}
     
    </div>
  );
}

export default AddBuilding;
