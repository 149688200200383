import React, { useEffect, useState } from 'react'
import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { branch } from '../utils/data'
import axios from 'axios';

function BranchLineBar() {
  const [branches, setBranches] = useState([]);


  useEffect(() => {
     
      axios
        .get("https://pms.dalyobt.com/api/view_branch/")
        .then((response) => {
       

         
          setBranches(response.data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }, []);

  return (
    <ResponsiveContainer width={"90%"} height="80%">
    <BarChart

      data={branches}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      
      <XAxis dataKey="name" />
      <YAxis type="number" domain={[0, 100]} />
      <Tooltip />
      <Legend />
      <Bar dataKey="financial_percentage" fill="#0B72B5" barSize={50} minPointSize={30} />
      <Bar dataKey="progress_percentage" fill="#FDC00D" barSize={50} minPointSize={30} />
      
    </BarChart>
  </ResponsiveContainer>
  )
}

export default BranchLineBar