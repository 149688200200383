import { configureStore } from "@reduxjs/toolkit";
import sideBarSlice from "./sidebarSlice";



const store = configureStore({
  reducer: {
    sideBar: sideBarSlice.reducer,

   
 
  },
});

export default store;