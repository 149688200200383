import axios from "axios";
import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { staff } from '../../utils/data'

function StaffTable({ staffs }) {
  const [position, setPosition] = useState([]);
  const [grp, setGRP] = useState([]);
  const [viewPos, setViewPos] = useState([])
  const [newStaff, setNewStaff] = useState([])
//   const [changeRole, setChangeRole] = useState(0)
  const [phoneNum, setPhoneNumber] = useState([])
  const [choosenField, setChoosenField] = useState('')
  //   console.log(staffs)
  const userGroup = localStorage.getItem("userGroup");
  const userID = localStorage.getItem('userID')
  const [error, setError] = useState(false)
//   const navigate = useNavigate()
  useEffect(() => {
    if (userGroup === "1") setPosition(["Senior Engineer", "Junior Engineer"]);
    else if (userGroup === "5")
      setPosition([
        "Executive 1",
        "Executive 2",
        "Executive 3",
        "project manager",
      ]);
    else{
        setPosition([])
    }
  }, [userGroup]);


  useEffect(()=>{
    axios.get("https://pms.dalyobt.com/api/groups/").then((response)=>{
        setGRP(response.data)
    }).catch((error)=>{
        console.error(error)
    })
  },[])

  useEffect(()=>{
    axios.get("https://pms.dalyobt.com/api/position_view/").then((response)=>{
        setViewPos(response.data)
    }).catch((error)=>{
        console.error(error)
    })
  },[])

  useEffect(()=>{
    axios.get('https://pms.dalyobt.com/api/user_phone_num/').then((response)=>{
      setPhoneNumber(response.data)
    }).catch((error)=>{
      console.error(error)
    })
  },[])

  // console.log(phoneNum, "----------------------------------------------------")

//   console.log(staffs, "All Staffs")

  useEffect(()=>{
    if (userGroup==="1"){
        // console.log(staffs.filter((items)=>items.groups[0]===1), "Heeeey")
        setNewStaff(staffs?.filter((items)=>(items.groups[0]?.toString()==="2" || items.groups[0]?.toString()==="3")))
    }
    else if(userGroup==="5"){
        setNewStaff(staffs?.filter((items)=>(items.groups[0]?.toString()==="1" || items.groups[0]?.toString()==="5" || items.groups[0]?.toString()==="6" || items.groups[0]?.toString()==="7")))
    }
  },[staffs, userGroup])

//   console.log(choosenField, "Field")
  const groupIdentification = grp.find((item)=>item.name===choosenField)

//   console.log(groupIdentification, "Group")

  const handleUpdate = async(userId)=>{
    if(groupIdentification){
      await axios.put(`https://pms.dalyobt.com/api/add-user-to-group/${userId}/`,{group: groupIdentification?.id,}).then((response)=>{
        // console.log(response.data)
        window.location.href='/staff'
        // navigate('/staff')
        }).catch((error)=>{
            // console.error(error)
            setError(true)
        })
    }
    else{
      setError(true)
    }
  }

//   console.log(newStaff,"New")

  return (
    <div className="w-[100%] max-h-[400px] overflow-y-scroll mx-auto relative overflow-x-auto  sm:rounded-l">
      {error && <div className="w-full mx-auto my-8 bg-red-500 text-center p-2 rounded-md">
        <p className="text-white font-medium">Please choose the roles correctly.</p>
      </div>}
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-center text-gray-700 uppercase border-b border-gray-300">
          <tr>
            <th scope="col" className="px-6 py-3">
              Staff ID
            </th>
            <th scope="col" className="px-6 py-3">
              Email
            </th>
            <th scope="col" className="px-6 py-3">
              Phone
            </th>
            <th scope="col" className="px-6 py-3">
              Username
            </th>
            <th scope="col" className="px-6 py-3">
              Type Of Position
            </th>

            <th scope="col" className="px-6 py-3">
              Current Role
            </th>

            <th scope="col" className="px-6 py-3">
              Role
            </th>

            {(userGroup==="1" || userGroup==="5") &&  <th scope="col" className="px-6 py-3">
              Action
            </th>}
          </tr>
        </thead>
        <tbody>
          {newStaff?.map((s) => {
            return (
              <tr
                key={s?.id}
                className="text-center text-gray-400 border-b border-gray-300"
              >
                <td className="px-6 py-4">{s?.id}</td>
                <td className="px-6 py-4">{s ? s?.email : "-"}</td>
                <td className="px-6 py-4">{phoneNum?.find((items)=>items?.user===s?.id)?.phone}</td>
                <td className="px-6 py-4 ">{s?.username}</td>
                {/* {console.log(viewPos?.find((item)=>item?.id===21)?.id)} */}
                <td className="px-6 py-4">{viewPos?.find((item)=>item?.user===s?.id)?.name}</td>
                {/* <td className="px-6 py-4" >{s?.typeOfPosition}</td> */}

                <td className="px-6 py-4 capitalize">{grp?.find((item)=>item?.id===s?.groups[0])?.name}</td>

                <td className="px-6 py-4">
                  <div className="px-2 bg-gray-100  ">
                    <select onChange={(e)=>setChoosenField(e.target.value)} className="bg-transparent p-3 w-[100%] rounded-[5px] text-[14px] outline-none text-gray-500">
                      <option>Choose Role</option>
                      {position.map((g, index) => {
                        return <option key={index}>{g}</option>;
                      })}
                      {/* <option>Executive 1</option>
                <option>Executive 2</option>
                <option>Executive 3</option>
                <option>Senior Engineer</option>
                <option>Junior Engineer</option> */}
                    </select>
                  </div>
                </td>
              
                <td>
                  {userID?.toString()===s?.id?.toString() ? '' : <button onClick={()=>handleUpdate(s?.id)} className="bg-[#0B72B5] text-white rounded-[5px] p-2">
                    Re-assign Role
                  </button>}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* {filteredProject.length ===0 && <div className='w-[100%] my-[20px] flex justify-center items-center'> <span className='text-gray-600 font-bold '>No project available</span></div> */}
      {/* } */}
    </div>
  );
}

export default StaffTable;
