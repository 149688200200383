import React, { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
// import { notification } from '../../utils/data'
// import { notification } from "../utils/data";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function Notification() {
  const navigate = useNavigate();
  const [notification, setNotification] = useState([]);
  const [selectedNotifcation, setSelectedNotification] = useState(
    "PROJECT-NOTIFICATION"
  );

  useEffect(() => {
    axios
      .get("https://pms.dalyobt.com/api/notification/")
      .then((response) => {
        setNotification(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const filteredNotifcation = notification?.filter((noti) => {
    return noti?.name === selectedNotifcation;
  });

  return (
    <div className="w-[50%] p-3 mx-auto mt-[50px] bg-white shadow-md rounded-[10px] max-[1300px]:w-[70%] max-[900px]:w-[80%] max-[1000px]:w-[90%]">
      <div className="w-[90%] mx-auto p-4 flex justify-between items-center border-b border-gray-300">
        <span className="text-[18px] font-bold">Notifications</span>
        <FaTimes
          className="text-gray-500 cursor-pointer"
          onClick={() => navigate(-1)}
        />
      </div>
      <div className="w-[90%] overflow-x-auto mx-auto  mt-[20px] flex justify-between gap-[20px] items-center p-2 border-b border-gray-300">
        <div
          className="flex items-center px-4 gap-[10px] cursor-pointer"
          onClick={() => setSelectedNotification("PROJECT-NOTIFICATION")}
        >
          <span className="font-bold text-[14px]">Project</span>
          {/* <div className='w-[20px] h-[20px] bg-[#FDC00D] rounded-[50%] flex justify-center items-center text-white text-[10px]'>{filteredNotifcation?.length}</div> */}
        </div>
        <div
          className="flex items-center px-4 gap-[10px] cursor-pointer"
          onClick={() => setSelectedNotification("SITE-NOTIFICATION")}
        >
          <span className="font-bold text-[14px]">Site</span>
          {/* <div className='w-[20px] h-[20px] bg-[#FDC00D] rounded-[50%] flex justify-center items-center text-white text-[10px]'>{filteredNotifcation?.length}</div> */}
        </div>
        <div
          className="flex items-center px-4 gap-[10px] cursor-pointer "
          onClick={() => setSelectedNotification("BLOCK-NOTIFICATION")}
        >
          <span className="font-bold text-[14px] text-gray-500">Block</span>
          {/* <div className='w-[20px] h-[20px] bg-[#FDC00D] rounded-[50%] flex justify-center items-center text-white text-[10px]'>3</div> */}
        </div>
        <div
          className="flex items-center px-4 gap-[10px] cursor-pointer "
          onClick={() => setSelectedNotification("CONTRUCTOR-NOTIFICATION")}
        >
          <span className="font-bold text-[14px] text-gray-500">
            Contractor
          </span>
          {/* <div className='w-[20px] h-[20px] bg-[#FDC00D] rounded-[50%] flex justify-center items-center text-white text-[10px]'>3</div> */}
        </div>
        <div
          className="flex items-center px-4 gap-[10px] cursor-pointer"
          onClick={() => setSelectedNotification("BOQ-NOTIFICATION")}
        >
          <span className="font-bold text-[14px] text-gray-500">BOQ</span>
          {/* <div className='w-[20px] h-[20px] bg-[#FDC00D] rounded-[50%] flex justify-center items-center text-white text-[10px]'>3</div> */}
        </div>

        {/* <div
          className="flex items-center px-4 gap-[10px] cursor-pointer "
          onClick={() => setSelectedNotification("CHECKLIST-NOTIFICATION")}
        >
          <span className="font-bold text-[14px] text-gray-500">Checklist</span>
        </div> */}
      </div>
      <div className="w-[90%] mx-auto max-h-[600px] overflow-y-auto">
        {filteredNotifcation.length>0 ? filteredNotifcation?.map((not) => {
          return (
            <div
              key={not?.id}
              className="border-b border-gray-300 p-4 mt-[20px] flex flex-col gap-[10px]"
            >
              <span>
                {" "}
                {not?.content}
              </span>
              <div className="text-[14px] text-gray-500 flex gap-[10px]">
                {/* <span className="font-bold">block-1</span> */}
                <span>{not?.date}</span>
              </div>
            </div>
          );
        }): <div className="border-b border-gray-300 p-4 mt-[20px] text-gray-500 text-center flex flex-col gap-[10px]">No Notification Available.</div>}
      </div>
    </div>
  );
}

export default Notification;
