import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'


function ProtectedRoute({token}) {
//   if(!token){
//     return <Navigate to={'/login'} replace />
//   }
return token ? <Outlet /> : <Navigate to={'/login'} />
}

export default ProtectedRoute