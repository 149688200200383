import React, { useState } from "react";
import CheckListGet from "./CheckListGet";
import CheckListFill from "./CheckListFill";

function CheckList() {
  const [fillCheck, setFillCheck] = useState(false);
  const [getCheck, setGetCheck] = useState(true);

  const handleInit = ()=>{
    setGetCheck(true)
    setFillCheck(false)
  }

  const handleCurrent = ()=>{
    setGetCheck(false)
    setFillCheck(true)
  }

  return (
    <div className="w-full relative">
      <div className="w-[50%] mx-auto flex justify-between my-5 mb-10 items-center max-[1000px]:w-[80%]">
        <button
          onClick={handleInit}
          className="bg-[#ffffff] hover:bg-[#0B72B5] focus:bg-[#0B72B5] transition duration-500 hover:text-white focus:text-white shadow-2xl p-2 text-[#0B72B5] rounded-md font-medium outline-none"
          autoFocus
        >
          Checklist Progress
        </button>
        <button
          onClick={handleCurrent}
          className="bg-[#ffffff] hover:bg-[#0B72B5] focus:bg-[#0B72B5] transition duration-500 hover:text-white focus:text-white shadow-2xl p-2 text-[#0B72B5] rounded-md font-medium outline-none"
        >
          Checklist Fill
        </button>
      </div>

      <div className="w-full">
        {getCheck && <CheckListGet />}
        {fillCheck && <CheckListFill />}
      </div>
    </div>
  );
}

export default CheckList;
