import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

function AmmendTable({ singleFilteredItem, boqDate, uploadFile }) {
  const [quantityFill, setQuantityFill] = useState("");
  const [unitPrice, setUnitPrice] = useState("");
  const [itemNo, setItemNo] = useState("");
  const [idSet, setIdSet] = useState("");
  const [error, setError] = useState(false);
  const { id } = useParams();

  const singleItem = singleFilteredItem?.find((item) => item);

  useEffect(() => {
    if (singleItem) {
      setIdSet(singleItem?.id || "");
      setItemNo(singleItem?.Item_no || "");
      setQuantityFill(singleItem?.quantity || "");
      setUnitPrice(singleItem?.unit_price || "");
    }
  }, [singleItem]);

  const handleSubmit = async () => {
    const formData = new FormData();

    formData.append("attachement", uploadFile);
    formData.append("quantity", quantityFill);
    formData.append("unit_price", unitPrice);
    formData.append("changeDate", boqDate);

    await axios
      .put(
        `https://pms.dalyobt.com/api/boq_item/${id}/${itemNo}/${parseInt(
          idSet
        )}/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        // console.log(response.data);
        window.location.href = `/projects/sites/buildings/${id}`
      })
      .catch((error) => {
        console.error(error);
        setError(true);
      });
  };

  return (
    <div className="w-[100%] mt-[20px]  mx-auto  overflow-x-auto  sm:rounded-l">
      <table className="w-full my-[30px] text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-center text-gray-700 uppercase border-b border-gray-300">
          <tr className=" text-[#0B72B5]">
            <th scope="col" className="px-6 py-3">
              ID.
            </th>
            <th scope="col" className="px-6 py-3">
              Description
            </th>
            <th scope="col" className="px-6 py-3">
              Item_no
            </th>
            <th scope="col" className="px-4 py-3">
              Quantity
            </th>
            <th scope="col" className="px-4 py-3">
              Quantity Unit
            </th>
            <th scope="col" className="px-6 py-3">
              Unit Price
            </th>
            <th scope="col" className="px-6 py-3">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {singleFilteredItem &&
            singleFilteredItem?.map((item, index) => {
              return (
                <tr
                  key={index}
                  className="text-center text-gray-600 border-b border-gray-300"
                >
                  <td>{item?.id}</td>
                  <td className="text-start p-4 w-[50%] leading-[35px]">
                    {item?.description}
                  </td>
                  <td>{item?.Item_no}</td>
                  <td>
                    <input
                      type="number"
                      value={quantityFill}
                      onChange={(e) => setQuantityFill(e.target.value)}
                      className="w-[80%] mx-auto border border-gray-200 rounded p-2"
                    />
                  </td>
                  <td>{item?.quantity_unit}</td>
                  <td>
                    <input
                      type="number"
                      value={unitPrice}
                      onChange={(e) => setUnitPrice(e.target.value)}
                      className="w-[80%] mx-auto border border-gray-200 rounded p-2"
                    />
                  </td>
                  <td className="text-start p-4">
                    <button
                      onClick={handleSubmit}
                      className="py-3 px-4 bg-[#0B72B5] text-white rounded-[10px]"
                    >
                      Save
                    </button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>

      {error && (
        <div className="w-[80%] mx-auto my-8 bg-red-500 text-center p-2 rounded-md">
          <p className="text-white font-medium">
            Please Check the fields again there might be a missing field.
          </p>
        </div>
      )}

      {/* <div className="my-8">
        <button className="py-3 px-4 bg-[#0B72B5] text-white rounded-[10px]">
          Save
        </button>
      </div> */}
    </div>
  );
}

export default AmmendTable;
