import React from 'react'
import {ResponsiveContainer,BarChart,XAxis,YAxis,Tooltip,Legend,Bar} from 'recharts'

function LineBar({projects}) {

  




  const width = window.innerWidth
  return (
    <ResponsiveContainer width={ width <1000?"100%":'90%'||width} height="80%">
    <BarChart
      width={500}
      height={300}
      data={projects?.slice(0,6)}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      
      <XAxis dataKey="name" />
      <YAxis type="number" domain={[0, 100]} />
      <Tooltip />
      <Legend />
      <Bar dataKey="financial_percentage" fill="#0B72B5" barSize={50} minPointSize={30} />
      <Bar dataKey="progress_percentage" fill="#FDC00D" barSize={50} minPointSize={30} />
      
    </BarChart>
  </ResponsiveContainer>
  )
}

export default LineBar