import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

function CurrentProgressReport() {
  const [listOfBOQ, setListOfBOQ] = useState([]);
  const [date, setDate] = useState("");
  const [listUsers, setListUsers] = useState([])

  const { id } = useParams();

  useEffect(() => {
    axios
      .get(`https://pms.dalyobt.com/api/const_progress/${id}/`)
      .then((response) => {
        setListOfBOQ(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [id]);

  useEffect(()=>{
    axios.get('https://pms.dalyobt.com/api/users/').then((response)=>{
      setListUsers(response.data)
    }).catch((error)=>{
      console.error(error)
    })
  },[])

  const filteredBOQByDate = listOfBOQ.filter((d) => {
    return d?.prepared_date === date;
  });

  // console.log(listOfBOQ)
  // console.log(filteredBOQByDate)
  // console.log("Date: ",date)

  // console.log("Current-ID: ", id);

  return (
    <div className="w-[100%] mx-auto h-[500px] p-2  bg-white  flex flex-col">
      <div className="w-[100%] mt-[20px] flex justify-center items-center">
            <span className="text-[#0B72B5] font-bold">Current Progress Report</span>
          </div>
      
      <div className="w-[90%] mt-[30px] mx-auto flex justify-end items-center">
        <input
          type="date"
          className="w-[25%] p-4 text-[14px] text-gray-600 outline-none border border-gray-400 rounded-[5px]"
          placeholder="Search"
          onChange={(e) => setDate(e.target.value)}
        />
      </div>
      <div className="overflow-y-auto">
        <table className="min-w-full divide-y mt-[20px]  divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Item No.
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Quantity Completed
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Quantity Remaining
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Start BOQ_Item
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Latest BOQ_Item
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Status
              </th>

              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Remark
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Prepared Date
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Approved Date
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Attachement
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Prepared By
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Approved By
              </th>
              {/* <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Current Progress
              </th> */}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {date === ""
              ? listOfBOQ?.map((boq, index) => {
                  return (
                    <tr
                      key={index}
                      className="text-[15px] font-normal text-gray-600"
                    >
                      <td className="px-6 py-4 whitespace-nowrap">
                        {index + 1}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {boq ? boq?.quantity_completed : "-"}
                      </td>

                      <td className="px-6 py-4 whitespace-nowrap">
                        {boq ? boq?.quantity_remaining : "-"}
                      </td>

                      <td className="px-6 py-4 whitespace-nowrap">
                        {boq ? boq?.start_boq_item : "-"}
                      </td>
                      {/* <td className="px-6 py-4 whitespace-nowrap">Electricity</td> */}
                      <td className="px-6 py-4 whitespace-nowrap">
                        {boq ? boq?.latest_boq_item : "-"}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {boq?.status?.toString() === "true"
                          ? "Approved"
                          : "Rejected"}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {boq ? boq?.remark : "-"}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {boq ? boq?.prepared_date : "-"}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {boq ? boq?.approved_date : "-"}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {boq?.attachement_url && (
                          <a
                            href={`https://pms.dalyobt.com${boq?.attachement_url}`}
                            className="underline text-[#0B72B5]"
                            download
                          >
                            {boq? boq?.attachement: '-'}
                          </a>
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {/* {boq ? boq?.prepared_by : "-"} */}
                        { listUsers?.find((item)=>item?.id===boq?.prepared_by)?.username }
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {/* {boq ? boq?.approved_by : "-"} */}
                        { listUsers?.find((item)=>item?.id===boq?.approved_by)?.username }
                      </td>
                      {/* <td className="px-6 py-4 whitespace-nowrap">
                        <input
                          type="number"
                          placeholder="0"
                          className="w-[80%] mx-auto p-4 text-[14px] text-gray-600 outline-none border border-gray-400 rounded-[5px]"
                        />
                      </td> */}
                    </tr>
                  );
                })
              : filteredBOQByDate?.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      className="text-[15px] font-normal text-gray-600"
                    >
                      <td className="px-6 py-4 whitespace-nowrap">
                        {index + 1}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {item ? item?.quantity_completed : "-"}
                      </td>

                      <td className="px-6 py-4 whitespace-nowrap">
                        {item ? item?.quantity_remaining : "-"}
                      </td>

                      <td className="px-6 py-4 whitespace-nowrap">
                        {item ? item?.start_boq_item : "-"}
                      </td>
                      {/* <td className="px-6 py-4 whitespace-nowrap">Electricity</td> */}
                      <td className="px-6 py-4 whitespace-nowrap">
                        {item ? item?.latest_boq_item : "-"}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {item?.status?.toString() === "true"
                          ? "Approved"
                          : "Rejected"}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {item ? item?.remark : "-"}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {item ? item?.prepared_date : "-"}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {item ? item?.approved_date : "-"}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {item?.attachement_url && (
                          <a
                            href={`https://pms.dalyobt.com${item?.attachement_url}`}
                            className="underline text-[#0B72B5]"
                            download
                          >
                            {item? item?.attachement: '-'}
                          </a>
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {/* {item ? item?.prepared_by : "-"} */}
                        { listUsers?.find((item)=>item?.id===item?.prepared_by)?.username }
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {/* {item ? item?.approved_by : "-"} */}
                        { listUsers?.find((item)=>item?.id===item?.approved_by)?.username }
                      </td>
                      {/* <td className="px-6 py-4 whitespace-nowrap">
                        <input
                          type="number"
                          placeholder="0"
                          className="w-[80%] mx-auto p-4 text-[14px] text-gray-600 outline-none border border-gray-400 rounded-[5px]"
                        />
                      </td> */}
                    </tr>
                  );
                })}
          </tbody>
        </table>
        {filteredBOQByDate.length === 0 && date !== "" && (
          <div className="w-[100%] my-[20px] flex justify-center items-center">
            {" "}
            <span className="text-gray-600 font-bold ">No BOQ available</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default CurrentProgressReport;
