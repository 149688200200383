import React, { useEffect, useState } from "react";
import {  useNavigate, useParams } from "react-router-dom";

import AmmendTable from "./AmmendTable";
import axios from "axios";
// import { FaAngleLeft } from "react-icons/fa";

function BOQAmendment() {
  const [boqInput, setBoqInput] = useState("");
  const [boqID, setBoqID] = useState("");
  const [boqDate, setBoqDate] = useState("");
  const [uploadFile, setUploadFile] = useState(null);
  const navigate = useNavigate();
  // const location = useLocation();
  // const data = location.state;
  const { id } = useParams();
  const [boqItem, setBoqItem] = useState([]);

  useEffect(() => {
    axios
      .get(`https://pms.dalyobt.com/api/boq_item/${id}/`)
      .then((response) => {
        setBoqItem(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [id]);

  const filteredListItemNo = boqItem?.filter(
    (items) => items?.block?.toString() === id.toString()
  );

  const singleFilteredItem = filteredListItemNo?.filter(
    (item) => item?.id?.toString() === boqID?.toString()
  );

  const filterItemNo = filteredListItemNo?.map((items) => items?.Item_no);

  const singleFilterItem = filterItemNo?.find((item) => item === boqInput);

  const listOfID = filteredListItemNo?.map((item) =>
    item?.Item_no?.toString() === singleFilterItem?.toString() ? item?.id : ""
  );

  const filteredListOfID = listOfID?.filter((items) => items !== "");

  // console.log(filteredListOfID, "-----------")

  // console.log(singleFilterItem, "SingleItemNo--------")

  // console.log(filterItemNo, "Filtered Item No--------")

  // console.log(filteredListItemNo, "Filtered List Item----------------------")

  // console.log(singleFilteredItem, "---------------------------------");

  // console.log(uploadFile, "------------------------------")

  return (
    <div className="py-4">
    <div className=" w-[90%] py-4 mx-auto overflow-y-auto bg-white flex flex-col rounded shadow-md">
      
      
      <div className="w-[90%] mx-auto my-5 flex justify-between items-center">
      <div>
            <span className="text-[#0B72B5] font-bold">BOQ Amendment</span>
          </div>
        <button onClick={()=>navigate(`/projects/sites/buildings/addNewBOQ/${id}`)} className="bg-[#0B72B5] text-[14px] text-white p-2 rounded-md outline-none border-none">
          Add New BOQ Item
        </button>
      </div>
      <div className="w-[90%] mx-auto flex items-center gap-[20px]">
        <div className="w-[50%] my-[50px] flex flex-col gap-[20px]">
          <span className="text-[#0B72B5] font-bold">BOQ</span>

          <select
            list="data"
            className="bg-white w-[100%] p-4 text-[14px] text-gray-600 outline-none border border-gray-400 rounded-md"
            onChange={(e) => setBoqInput(e.target.value)}
            placeholder="Search BOQ"
          >
            <option>Choose Item_no</option>
            {filteredListItemNo?.map((items, index) => {
              return (
                <option key={index} className="p-2 border border-gray-400">
                  {items?.Item_no}
                </option>
              );
            })}
          </select>
        </div>
        {boqInput && (
          <div className="w-[50%] my-[50px] flex flex-col gap-[20px]">
            <span className="text-[#0B72B5] font-bold">Select Item ID</span>

            <select
              list="data"
              className="bg-white w-[100%] p-4 text-[14px] text-gray-600 outline-none border border-gray-400 rounded-md"
              onChange={(e) => setBoqID(e.target.value)}
              placeholder="Search BOQ"
            >
              <option>Select Item ID</option>
              {filteredListOfID?.map((items, index) => {
                return (
                  <option key={index} className="p-2 border border-gray-400">
                    {items}
                  </option>
                );
              })}
            </select>
          </div>
        )}
        {boqInput && boqID && (
          <div className="w-[50%] my-[50px] flex flex-col gap-[20px]">
            <span className="text-[#0B72B5] font-bold">Date</span>

            <input
              type="date"
              className="p-4 text-[14px] text-gray-600 outline-none border border-gray-400 rounded-[5px]"
              onChange={(e) => setBoqDate(e.target.value)}
            />
          </div>
        )}
      </div>

      {boqInput && boqID && boqDate && (
        <div className="w-[95%] mx-auto">
          <div className=" mx-auto flex justify-end p-2 items-center gap-[10px]">
            <div className="flex flex-col items-start gap-[20px]">
              <button>
                <label>
                  <input
                    type="file"
                    onChange={(e) => setUploadFile(e.target.files[0])}
                    name="myfile"
                    accept=".pdf"
                    hidden
                  />
                  <span className="bg-[#0B72B5] text-white p-4 cursor-pointer text-[12px] rounded-[5px]">
                    Upload Reason
                  </span>
                </label>
              </button>
              {/* <span className='text-[14px] text-gray-500'>Allowed Format .PDF</span> */}
            </div>
          </div>

          {uploadFile && boqInput && boqID && boqDate && (
            <div className="my-4 border border-gray-400 rounded-md overflow-y-scroll w-[90%] p-2 mx-auto">
              <embed
                src={URL.createObjectURL(uploadFile)}
                type="application/pdf"
                width="100%"
                height="500px"
              />
            </div>
          )}

          {uploadFile && boqInput && boqID && boqDate && (
            <AmmendTable singleFilteredItem={singleFilteredItem} boqDate={boqDate} uploadFile={uploadFile} />
          )}
        </div>
      )}
    </div>
    </div>
  );
}

export default BOQAmendment;
