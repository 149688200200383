import React from 'react'
import {AiFillWarning} from 'react-icons/ai'
import { deadlineCalculator } from "../utils/dateSubtract";
function DashboardBondSummary({contractor,contractorName}) {


  return (
    <div className="w-[100%] max-h-[700px] overflow-y-auto mt-[80px] mb-4 max-[1120px]:w-[100%] bg-white p-3 rounded-[10px] shadow-md">
        {/* <div className='flex mt-[20px] justify-center items-center'>
        <span className="text-[12px]  text-gray-500 uppercase">
      Bonds Summary 
    </span>
        </div> */}
 
    <div className="mt-[30px]">
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-center  text-gray-700 uppercase border-b border-gray-300">
          <tr>
            <th scope="col" className="px-6 py-3">
              Contractor
            </th>
            <th scope="col" className="px-6 py-3">
              Block
            </th>
            <th scope="col" className="px-6 py-3">
              PB_End_Date
            </th>
            <th scope="col" className="px-6 py-3">
              AG_End_Date
            </th>
          </tr>
        </thead>
        <tbody>
          {contractor?.map((cont,index) => {
           
        

           let pbResult = deadlineCalculator(cont?.pb_end_date)
           let agResult = deadlineCalculator(cont?.ag_end_date)
            
            return (
              <tr
                key={index}
                className="text-center text-gray-600 border-b border-gray-300"
              >
                {/* <td  className="px-6 py-4 underline font-medium whitespace-nowrap">
                      <Link to={'/projects/'+project.id}>{project.id} </Link>
                      
                  </td> */}
                <td className={(pbResult || agResult) < 30?"flex items-center justify-center gap-[10px] px-6 py-4 text-red-700" :"px-6 py-4"}>
                <span>  {contractorName.find(
                    (item) => item?.id === cont?.contractor
                  )?.name || ""}</span>
                  <span> {(pbResult || agResult) < 30 && <AiFillWarning />}</span>
                 
                </td>
                <td className={(pbResult || agResult) < 30?"px-6 py-4 text-red-700" :"px-6 py-4"}>{cont?.block}</td>
              
                <td className={(pbResult || agResult) < 30?"px-6 py-4 text-red-700" :"px-6 py-4"}>{cont?.pb_end_date}</td>
                <td className={(pbResult || agResult) < 30?"px-6 py-4 text-red-700" :"px-6 py-4"}>{cont.ag_end_date}</td>

                {/* <td className="px-6 py-4">
                     {project.sites.length}
                  </td> */}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  </div>
  )
}

export default DashboardBondSummary