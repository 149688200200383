import axios from "axios";
import React, { useState } from "react";
import { FaAngleLeft } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";

function AddRetention() {
  const { id } = useParams();
  const navigate = useNavigate();
  // const [retDate, setRetDate] = useState('')
  const [amountFill, setAmountFill] = useState("");
  const userID = localStorage?.getItem("userID");
  const [error, setError] = useState(false);
  const [fieldError, setFieldError] = useState(false);

  const handleSubmit = async () => {
    if (amountFill) {
      await axios
        .post(`https://pms.dalyobt.com/api/retention/`, {
          retention_amount: amountFill,
          prepared_by: userID,
          block: id,
        })
        .then((response) => {
          console.log(response.data);
          window.location.href = `/postconstruction/retention/${id}`;
        })
        .catch((error) => {
          console.error(error);
          setError(true);
          setFieldError(false);
        });
    } else {
      setError(true);
      setFieldError(true);
    }
  };

  return (
    <div className="bg-white p-4 h-[91vh]">
      <div className="w-[90%] mt-[30px] mx-auto">
        <div className=" flex items-center gap-[30px]">
          <FaAngleLeft
            className="text-[25px] cursor-pointer text-[#0B72B5]"
            onClick={() => navigate(-1)}
          />
          <span className="text-[20px] text-[#0B72B5] font-bold">
            Add Retention
          </span>
        </div>

        {error && (
          <div className="w-[80%] mx-auto my-4 bg-red-500 text-center p-2 rounded-md">
            {fieldError ? (
              <p className="text-white font-medium">
                There are some missing fields.
              </p>
            ) : (
              <p className="text-white font-medium">
                Please insert the correct format.
              </p>
            )}
          </div>
        )}

        {/* <div className="flex justify-between my-[30px] gap-[10px]">
          <div className="w-[50%] mt-[20px]  flex flex-col items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              Date <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="date"
                // onChange={(e) => setStartDate(e.target.value)}
                placeholder="Ex. 2023-07-08"
                className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>
        </div> */}
        <div className="w-[50%] mt-[30px]  flex flex-col items-start gap-[20px]">
          <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
            Amount <span className="text-red-700">*</span>
          </span>
          <div className="w-[100%] rounded-[5px]">
            <input
              type="number"
              onChange={(e) => setAmountFill(e.target.value)}
              // onChange={(e) => setStartDate(e.target.value)}
              placeholder="0"
              className="w-[100%] border border-[#ced4da]  py-3 px-4 rounded-[5px] text-[14px] outline-none"
            />
          </div>
          {/* {dateError && <span className="text-[14px] text-red-500">Invalid Date</span>} */}
        </div>
        {/* <div className="flex mt-[30px] items-center justify-between gap-[10px]">
          <div className="w-[50%]  flex flex-col  items-start gap-[20px]">
            <span className="text-[14px] font-bold w-[80%] text-[#0B72B5]">
              Prepared By <span className="text-red-700">*</span>
            </span>
            <div className="w-[100%] rounded-[5px]">
              <input
                type="text"
                placeholder="Mr.Y"
                // onChange={(e) => setName(e.target.value)}
                className="w-[100%] border border-[#ced4da] py-3 px-4 rounded-[5px] text-[14px] outline-none"
              />
            </div>
          </div>
        </div> */}
        <div className="w-[100%] mt-[30px] flex justify-end items-center">
          <button
            onClick={handleSubmit}
            className="bg-[#0B72B5] cursor-pointer text-white text-[14px] py-2 px-4 rounded"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddRetention;
