import React, { useEffect, useState } from "react";
import { FaAngleLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ProfileChange from "./ProfileChange";
function UserProfile() {
  const navigate = useNavigate();
  const [userProfile, setUserProfile] = useState({});
  const [groups, setGroups] = useState([]);
  const [position, setPosition] = useState([]);
  const [profile, setProfile] = useState([]);
  const [profilechange, setProfileChange] = useState(false);

  const username = localStorage.getItem("username");
  const userID = localStorage.getItem("userID");
  const userGroup = localStorage.getItem("userGroup");

  useEffect(() => {
    axios.get(`https://pms.dalyobt.com/api/users/${userID}`).then((response) => {
      // console.log("USER: ",response.data)
      setUserProfile(response.data);
    });
    axios.get("https://pms.dalyobt.com/api/position_view").then((response) => {
      // console.log("POSITION: ", response.data)
      setPosition(response.data);
    });
    axios.get("https://pms.dalyobt.com/api/groups/").then((response) => {
      // console.log("GROUPS",response.data)
      setGroups(response.data);
    });

    // console.log("profile",userProfile)
  }, [userID]);

  useEffect(() => {
    axios
      .get("https://pms.dalyobt.com/api/user_profile_pic/")
      .then((response) => {
        setProfile(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const filteredProfile = profile?.find(
    (item) => item?.user?.toString() === userID?.toString()
  );
  const filtered = groups?.find((i) => {
    return i.id.toString() === userGroup;
  });
  console.log(filteredProfile);

  const filteredPosition = position?.find((i) => {
    return i?.user.toString() === userID;
  });
  // console.log(filteredPosition)

  return (
    <div className="w-[90%]  p-2 mt-[50px] mx-auto">
      <div className="flex justify-between items-center gap-[20px]">
        <div className="flex items-center gap-[20px]">
          <FaAngleLeft
            className="text-[30px] cursor-pointer text-[#0B72B5]"
            onClick={() => navigate(-1)}
          />
          <span className="text-[20px] font-bold text-[#0B72B5]">
            My Profile
          </span>
        </div>

        <div>
          {filteredProfile?.profile_picture ? (
            <button
              className="bg-[#0B72B5] py-2 px-4 text-[14px] text-white rounded-[5px]"
              onClick={() => setProfileChange(true)}
            >
              Change Profile Picture
            </button>
          ) : (
            <button className="bg-[#0B72B5] py-2 px-4 text-[14px] text-white rounded-[5px]">
              Set Profile Picture
            </button>
          )}
        </div>
      </div>
      <div className="w-[100%] mx-auto mt-[40px] bg-white p-4 max-[900px]:w-[90%]">
        <div className="w-[90%] flex items-center gap-[20px] p-2 mt-[30px] border mx-auto  rounded-[10px]">
          <div className={"w-[60px] h-[60px] rounded-full"}>
            <img
              src={`https://pms.dalyobt.com${filteredProfile?.profile_picture}`}
              className="w-[100%] h-[100%] object-cover rounded-full"
              alt=""
            />
          </div>
          <div className="flex flex-col">
            <span className="font-bold text-[#0B72B5]">{username}</span>
            <span className="text-[14px] text-gray-500">{filtered?.name}</span>
          </div>
        </div>
        <div className="w-[90%]  p-2 mt-[30px] border mx-auto bg-white rounded-[10px]">
          <div className={"w-[95%] mx-auto mt-[20px]"}>
            <span className="text-[#0B72B5] font-bold">
              Personal Information
            </span>
          </div>
          
          <div className="w-[70%] mx-auto  flex flex-col mt-[30px]">
            <div className="w-[100%] flex justify-between gap-[30px]">
              <div className="w-[50%] flex flex-col">
                <span className="text-[14px] text-gray-500">Email</span>
                <span>{userProfile.email}</span>
              </div>
              <div className="w-[50%] flex flex-col">
                <span className="text-[14px] text-gray-500">position</span>
                <span>{filteredPosition?.name}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {profilechange && <ProfileChange setProfileChange={setProfileChange} />}
    </div>
  );
}

export default UserProfile;
