import React, { useEffect, useState } from "react";
import { sidebarLinks } from "../utils/data";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import axios from "axios";

function Sidebar() {
  const selectState = useSelector((state) => state.sideBar.sidebarClosed);
  // const [userGroups, setUserGroups] = useState([]);
  const [userProfiles, setUserProfiles] = useState([]);
  const [position, setPosition] = useState([])
  const location = useLocation();
  const pathName = location.pathname.replace("/", "");
  const username = localStorage.getItem("username");
  const userGroup = localStorage.getItem("userGroup");
  const userID = localStorage.getItem("userID")

  // console.log(sidebarLinks, "SIde")

  useEffect(()=>{
    axios.get('https://pms.dalyobt.com/api/user_profile_pic/').then((response)=>{
      setUserProfiles(response.data)
    }).catch((error)=>{
      console.error(error)
    })
  },[]) 

  useEffect(()=>{
    axios.get('https://pms.dalyobt.com/api/position_view/').then((response)=>{
      setPosition(response.data)
    }).catch((error)=>{
      console.error(error)
    })
  },[])

  // console.log(userProfiles, "User Profiles")

  const filteredProfile = userProfiles.find((item)=>item?.user?.toString()===userID?.toString())
  // console.log(filteredProfile.profile_picture, "User Profiles")

  const filteredPosition = position.find((item)=>item?.user?.toString()===userID?.toString())
  // console.log(filteredPosition?.name, "Position")

  const filteredLink = sidebarLinks.filter((link) => {
    return (link?.name !== "Dashboard");
  });

  const filteredLinkFinal = filteredLink.filter((link)=>link.length!==0)
  // console.log(filteredLinkFinal, "Final")
  const sidebarLinksFinal = sidebarLinks.filter((link)=>link.length!==0)

  // const getAllGroups = async () => {
  //   const response = await axios.get("https://pms.dalyobt.com/api/groups");
  //   if (response.status === 200) {
  //     setUserGroups(response.data.groups);
  //   }
  // };

  // useEffect(() => {
  //   getAllGroups();
  // }, []);

  // const filteredGroups = userGroups?.find((group) => {
  //   return group?.id?.toString() === userGroup?.toString();
  // });

  // console.log(sidebarLinks, "Leba")
  // filteredLink = filteredLink.filter((item)=>item!=='')

  return (
    <div
      className={
        selectState
          ? "w-[20%] border  h-[100vh] fixed top-0  left-0  bg-[#0B72B5] flex flex-col transition-[width] duration-700 justify-between z-40"
          : "w-[5%] max-[1110px]:w-[10%] h-[100vh] bg-[#0B72B5] flex flex-col justify-between fixed top-0 bottom-0 left-0 transition-[width]  duration-700 z-40"
      }
    >
      <div>
        <div className="w-[100%] mt-[30px] flex flex-col gap-[10px] items-center justify-center">
          <div
            className={
              !selectState
                ? "w-[100%]  flex justify-center"
                : "w-[30%] max-[1300px]:w-[50%] flex justify-center"
            }
          >
            <img
              src={require("../Images/housingLogo.png")}
              alt=""
              className={
                !selectState
                  ? "w-[50px] h-[50px] object-cover rounded-full"
                  : "w-[100px] h-[100px] object-cover rounded-full max-[900px]:w-[50px] max-[900px]:h-[50px]"
              }
            />
          </div>
          <div className="text-center">
            <span
              className={
                !selectState
                  ? "hidden"
                  : "text-white max-[1300px]:text-[12px]  max-[800px]:hidden"
              }
            >
              አ.አ. ቤቶች ልማት ኮርፖሬሽን
            </span>
          </div>
        </div>
        <div className="w-[100%] my-8 flex flex-col overflow-x-hidden max-[1100px]:items-center max-[1100px]:justify-center">
          {userGroup.toString() === "3"
            ? filteredLinkFinal?.map((link) => {
                return (
                  <a
                    href={"/" + link?.path}
                    key={link?.id}
                    className={
                      pathName === link?.name
                        ? "bg-white p-4 cursor-pointer text-[#0B72B5] flex flex-col items-center:"
                        : `bg-transparent p-4 cursor-pointer text-white flex flex-col items-center`
                    }
                  >
                    <div
                      className={
                        selectState
                          ? "w-[80%] mx-auto flex gap-[10px] justify-between max-[1100px]:justify-center"
                          : "w-[80%] mx-auto flex justify-center"
                      }
                    >
                      <div className="flex items-center gap-[10px]">
                        <span className="text-[30px]">{link?.icon}</span>
                        {selectState && (
                          <span className="text-[14px] font-bold max-[1100px]:hidden">
                            {link?.name}
                          </span>
                        )}
                      </div>
                    </div>
                  </a>
                );
              })
            : sidebarLinksFinal?.map((link) => {
                return (
                  <a
                    href={"/" + link?.path}
                    key={link?.id}
                    className={
                      pathName === link?.name
                        ? "bg-white p-4 cursor-pointer text-[#0B72B5] flex flex-col items-center:"
                        : `bg-transparent p-4 cursor-pointer text-white flex flex-col items-center`
                    }
                  >
                    <div
                      className={
                        selectState
                          ? "w-[80%] mx-auto flex gap-[10px] justify-between max-[900px]:justify-center"
                          : "w-[80%] mx-auto flex justify-center"
                      }
                    >
                      <div className="flex items-center   gap-[10px]">
                        <span className="text-[25px]">{link?.icon}</span>
                        {selectState && (
                          <span className="text-[14px] font-bold max-[1100px]:hidden">
                            {link?.name}
                          </span>
                        )}
                      </div>

                     
                    </div>
                  </a>
                );
              })}
        </div>
      </div>

      <div>
        <div
          className={
            !selectState
              ? "my-[30px] flex justify-center items-center "
              : "w-[90%] py-4 my-4 mx-auto px-2 relative flex gap-[20px]  justify-start items-center"
          }
        >
          <div className={"w-[50px] h-[50px] rounded-full"}>
            {filteredProfile?.profile_picture ? <img
              // src={require("../Images/person.jpg")}
              src={`https://pms.dalyobt.com${filteredProfile?.profile_picture}`}
              className="w-[100%] h-[100%] object-cover rounded-full"
              alt=""
            />:<img
            // src={require("../Images/person.jpg")}
            src={require("../Images/userLogo.jpg")}
            className="w-[100%] h-[100%] object-cover rounded-full"
            alt=""
          />}
          </div>
          <div
            className={
              !selectState ? "hidden" : "flex flex-col gap-[5px] text-white max-[1100px]:hidden"
            }
          >
            <span className="font-bold capitalize">{username}</span>
            <span className="text-[14px] text-gray-200">
              {filteredPosition?.name && filteredPosition?.name}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
